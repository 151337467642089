import React from 'react';
import Breadcrumb from 'components/PageHeader/Breadcrumb';
import Title from 'components/PageHeader/Title';
import './styles.scss';

interface Props {
  children: React.ReactNode;
  direction?: 'rows' | 'columns';
}

class PageHeader extends React.Component<Props> {
  static Breadcrumb = Breadcrumb;
  static Title = Title;

  render() {
    const { children, direction = 'rows' } = this.props;
    return <div className={`page-header ${direction}`}>{children}</div>;
  }
}

export default PageHeader;
