import React, { useEffect, useState } from 'react';
import { Alert, Col, Modal, Spinner, Table } from 'react-bootstrap';
import { useQuery } from '@apollo/react-hooks';
import { Button } from '@spins/react-ui-library';

import client from 'apollo/apollo-client-products';
import PaginatorWrapper from 'components/PaginatorWrapper';

import {
  GetProductsByIdQuery as getProductsByIdQuery,
  GetProductsByIdQueryVariables as getProductsByIdQueryVariables,
  GetProductsByIdDocument as getProductsByIdDocument
} from '../../../generated/graphql';

import './styles.scss';

interface Props {
  clientId: string;
  isAdmin: boolean;
  show: boolean;
  productsIds: Array<string>;
  handleEdit: Function;
  handleClose: Function;
}

const IO_SUMMARY_PRODUCTS_PER_PAGE = 15;

const ProductsWidgetModal: React.FC<Props> = ({
  clientId,
  show,
  productsIds,
  isAdmin,
  handleEdit,
  handleClose
}) => {
  const [products, setProducts] = useState([]);

  const { data, error, loading } = useQuery<getProductsByIdQuery, getProductsByIdQueryVariables>(
    getProductsByIdDocument,
    {
      client,
      fetchPolicy: 'cache-and-network',
      variables: {
        clientId: clientId,
        ids: productsIds
      }
    }
  );

  useEffect(() => {
    if (!loading && data && data?.batchListProductsHelios) {
      setProducts(data.batchListProductsHelios.items);
    }
  }, [data, loading]);

  const renderTableBody = (start: number, end: number) => {
    return products.slice(start, end).map(product => {
      let upcName = 'N/A';
      const upcs = product.upc.filter(upc => upc.type === 'GTIN');

      if (upcs.length > 0) {
        upcName = upcs[0].value;
      }

      return (
        <tr key={product.id}>
          <td>{upcName}</td>
          <td>{product.name}</td>
        </tr>
      );
    });
  };

  return (
    <Modal
      className="products-widget-modal"
      show={show}
      onHide={handleClose}
      backdrop="static"
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title className="products-widget-modal__title">
          <span>Products ({products.length})</span>
          {isAdmin && (
            <Button
              id="products-widget-modal__edit"
              className="push-right products-widget-modal__edit"
              onClick={() => handleEdit()}
            >
              EDIT
            </Button>
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading && (
          <Col lg={{ span: 4, offset: 4 }} className="text-center">
            <Spinner animation="border" className="loading-icon" />
          </Col>
        )}
        {error && (
          <Col lg={{ span: 4, offset: 4 }} className="mt-4">
            <Alert variant="danger">{`An error occurred, try again later. ${error.message}`}</Alert>
          </Col>
        )}
        {!loading && data && data?.batchListProductsHelios && (
          <PaginatorWrapper perPage={IO_SUMMARY_PRODUCTS_PER_PAGE} total={products.length}>
            {(indexStart, indexEnd) => (
              <Table className="products-table">
                <colgroup>
                  <col />
                  <col />
                </colgroup>
                <thead>
                  <tr>
                    <th>UPC</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>{renderTableBody(indexStart, indexEnd)}</tbody>
              </Table>
            )}
          </PaginatorWrapper>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ProductsWidgetModal;
