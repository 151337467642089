import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import './styles.scss';

interface Props {
  show: boolean;
  title: string;
}

const NewCouponConfirmationModal: React.FC<Props> = ({ show, title }) => (
  <Modal
    backdrop="static"
    centered
    dialogClassName="new-coupon-confirmation-modal"
    keyboard={false}
    show={show}
  >
    <Modal.Body>
      <div className="nccm-content">
        <FontAwesomeIcon color="#95CE38" icon={faCheckCircle} size="9x" />
        <div className="title">
          <div>
            <strong>{title}</strong>
          </div>
          <div>has been submitted</div>
        </div>
        <div className="message">
          Your order will be processed in the next 48 business hours. If there are questions about
          your submission a coupon expert will contact you.
        </div>
      </div>
    </Modal.Body>
  </Modal>
);

export default NewCouponConfirmationModal;
