import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Alert, Col, Spinner } from 'react-bootstrap';
import { RetailerType } from 'utils/types';
import RetailersUtils from 'utils/retailers';
import {
  GetRetailersQuery as getRetailersQuery,
  GetRetailersQueryVariables as getRetailersQueryVariables,
  GetRetailersDocument as getRetailersDocument
} from '../../generated/graphql';

interface Props {
  children: (retailersMap: Map<string, RetailerType>) => React.ReactNode;
}

const RetailersProvider: React.FC<Props> = ({ children }) => {
  const { data, error, loading } = useQuery<getRetailersQuery, getRetailersQueryVariables>(
    getRetailersDocument,
    {
      fetchPolicy: 'cache-and-network'
    }
  );

  return (
    <div className="retailers-provider">
      {loading && (
        <Col lg={{ span: 4, offset: 4 }} className="text-center">
          <Spinner animation="border" className="loading-icon" />
        </Col>
      )}
      {error && (
        <Col lg={{ span: 4, offset: 4 }} className="mt-3">
          <Alert variant="danger">An error occurred. Try again later</Alert>
        </Col>
      )}
      {!loading &&
        data &&
        data.listRetailers &&
        children(RetailersUtils.mapRetailersFromBackend(data.listRetailers))}
    </div>
  );
};

export default RetailersProvider;
