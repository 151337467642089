import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from 'store/storeInstance';
import { ROUTES } from 'utils/routes';
import * as actions from 'containers/Profile/actions';
import BeginNewProfileModal from 'components/Profile/BeginModal';
import NewProfileConfirmationModal from 'components/Profile/ConfirmationModal';
import NewProfileFormModal from 'containers/Profile/FormModal';

const Profile: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const showModal = useSelector((state: RootState) => state.profile.showModal);
  const step = useSelector((state: RootState) => state.profile.step);

  const onStepSet = (value: number) => {
    dispatch(actions.setStep(value));
  };

  const handleCancel = () => {
    dispatch(actions.setShowModal(false));
  };

  const handleClose = () => {
    history.push(ROUTES.COUPON_CREATION);
    dispatch(actions.setShowModal(false));
  };

  if (showModal) {
    switch (step) {
      case 1:
        return <BeginNewProfileModal onContinue={() => onStepSet(step + 1)} />;
      case 2:
        return <NewProfileFormModal onClose={handleCancel} onComplete={() => onStepSet(3)} />;
      case 3:
        return <NewProfileConfirmationModal onClose={handleClose} />;
      default:
        return null;
    }
  }
  return null;
};

export default Profile;
