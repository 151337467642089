import React from 'react';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import moment from 'moment';
import './styles.scss';

interface Props {
  minDate?: string;
  value?: string;
  onChange: any;
}

const DatePicker: React.FC<Props> = ({ minDate = null, value = '', onChange }) => {
  let minMoment = moment().subtract(1, 'day');
  if (minDate !== null) {
    minMoment = moment(minDate).subtract(1, 'day');
  }
  return (
    <Datetime
      className="date-picker"
      closeOnSelect
      dateFormat="MM/DD/YY"
      inputProps={{ className: 'dp-input', readOnly: true }}
      isValidDate={current => current.isAfter(minMoment)}
      timeFormat={false}
      value={moment(value)}
      onChange={value => onChange(moment(value).format('YYYY-MM-DD'))}
    />
  );
};

export default DatePicker;
