import { delay, takeEvery, select, put, call } from 'redux-saga/effects';
import {
  NEW_COUPON_START,
  NEW_COUPON_CONFIRMATION_SHOW,
  CAMPAIGN_DETAILS_VALUE_CHANGE
} from 'containers/CouponCreation/action-types';
import { selectedRetailersSelector } from 'containers/CouponCreation/selectors';
import {
  changeCampaignDetailsValue,
  setShowConfirmationModal
} from 'containers/CouponCreation/actions';
import { ROUTES } from 'utils/routes';
import { RetailerCampaigns } from 'utils/types';
import CouponsUtils from 'utils/coupons';

function* showConfirmationModalSaga({ payload: history }) {
  yield put(setShowConfirmationModal(true));
  yield delay(3000);
  yield put(setShowConfirmationModal(false));
  history.push(ROUTES.COUPONS);
  yield put({ type: NEW_COUPON_START });
}

function* campaignDetailsValueChangeSaga({ payload }) {
  const selectedRetailers: Array<RetailerCampaigns> = yield select(state =>
    selectedRetailersSelector(state)
  );
  const campaign = selectedRetailers.find(campaign => campaign.retailer.id === payload.retailerId);
  if (campaign) {
    const details = campaign.campaigns.find((details, index) => index === payload.index);
    let expiration;
    switch (payload.attribute) {
      case 'duration':
        expiration = CouponsUtils.calculateExpiration(
          details.startDate,
          payload.value,
          details.extension
        );
        if (expiration !== null) {
          yield put(
            changeCampaignDetailsValue(payload.retailerId, payload.index, 'expiration', expiration)
          );
        }
        break;
      case 'startDate':
        expiration = CouponsUtils.calculateExpiration(
          payload.value,
          details.duration,
          details.extension
        );
        if (expiration !== null) {
          yield put(
            changeCampaignDetailsValue(payload.retailerId, payload.index, 'expiration', expiration)
          );
        }
        break;
      case 'maxClips':
        yield put(
          changeCampaignDetailsValue(
            payload.retailerId,
            payload.index,
            'maxClipsBudget',
            campaign.retailer.updatedClipFee
              ? payload.value * campaign.retailer.updatedClipFee
              : payload.value * campaign.retailer.clipFee
          )
        );
        break;
      case 'extension':
        expiration = CouponsUtils.calculateExpiration(
          details.startDate,
          details.duration,
          payload.value
        );
        if (expiration !== null) {
          yield put(
            changeCampaignDetailsValue(payload.retailerId, payload.index, 'expiration', expiration)
          );
        }
        break;
    }
  }
}

export default function* couponCreationSagas() {
  yield takeEvery(CAMPAIGN_DETAILS_VALUE_CHANGE as any, campaignDetailsValueChangeSaga);
  yield takeEvery(NEW_COUPON_CONFIRMATION_SHOW as any, showConfirmationModalSaga);
}
