import React from 'react';
import './styles.scss';

interface Props {
  children: React.ReactNode;
  subTitle?: string;
  title: string;
}

const OfferDetailsWidget: React.FC<Props> = ({ children, subTitle = '', title }) => (
  <div className="offer-details-widget">
    <div className="title">{title}</div>
    {subTitle !== '' && <div className="sub-title">{subTitle}</div>}
    <div className="odw-content">{children}</div>
  </div>
);

export default OfferDetailsWidget;
