import React, { useEffect, useState } from 'react';
import { CouponRow } from 'utils/types';
import { orderBy, sortBy } from 'lodash';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { ROUTES } from 'utils/routes';

import CouponsTable from 'components/Coupons/Table';
import PaginatorWrapper from 'components/PaginatorWrapper';

const COUPONS_PER_PAGE = 10;

interface Props {
  data: Array<CouponRow>;
  searchText: string;
}

const CouponList: React.FC<Props> = ({ data, searchText }) => {
  const [currentSortHeader, setCurrentSortHeader] = useState<string | null>('createdDate');
  const [selectedOrdersIds, setSelectedOrdersIds] = useState([]);
  const [sortingAsc, setSortingAsc] = useState<boolean | null>(false);
  const [couponList, setCouponList] = useState<Array<CouponRow>>(data);
  const history = useHistory();

  const filterCoupons = coupons => {
    let response = coupons;

    // Filter by selected order ids
    if (selectedOrdersIds.length > 0) {
      response = response.filter(coupon =>
        selectedOrdersIds.some(orderId => coupon.orderId === orderId)
      );
    }

    // Filter coupons by search
    if (searchText !== '') {
      response = response.filter(
        coupon =>
          coupon.orderId.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ||
          coupon.title.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ||
          coupon.description.toLowerCase().indexOf(searchText.toLowerCase()) > -1
      );
    }

    return response;
  };

  const sortCoupons = (coupons: Array<CouponRow>) => {
    let result = coupons;
    if (currentSortHeader !== null) {
      switch (currentSortHeader) {
        case 'orderId':
        case 'title':
        case 'description':
          result = orderBy(result, currentSortHeader, sortingAsc ? 'asc' : 'desc');
          break;
        case 'createdDate':
          result = result.slice().sort((left: CouponRow, right: CouponRow) => {
            const leftMoment = moment(left[currentSortHeader]);
            const rightMoment = moment(right[currentSortHeader]);
            if (leftMoment.isBefore(rightMoment)) {
              return sortingAsc ? -1 : 1;
            }
            if (leftMoment.isAfter(rightMoment)) {
              return sortingAsc ? 1 : -1;
            }
            return 0;
          });
          break;
        default:
          result = orderBy(result, currentSortHeader, sortingAsc ? 'asc' : 'desc');
          break;
      }
    } else {
      // Sort by created date
      result = result.slice().sort((left: CouponRow, right: CouponRow) => {
        const leftMoment = moment(left[currentSortHeader]);
        const rightMoment = moment(right[currentSortHeader]);
        if (leftMoment.isBefore(rightMoment)) {
          return sortingAsc ? -1 : 1;
        }
        if (leftMoment.isAfter(rightMoment)) {
          return sortingAsc ? 1 : -1;
        }
        return 0;
      });
    }
    return result;
  };

  const handleOrderIdSelect = orderId => {
    let newSelectedOrdersIds;

    if (selectedOrdersIds.some(id => orderId === id)) {
      newSelectedOrdersIds = selectedOrdersIds.filter(id => orderId !== id);
    } else {
      newSelectedOrdersIds = selectedOrdersIds.concat([orderId]);
    }

    setSelectedOrdersIds(newSelectedOrdersIds);
  };

  const handleClearAllSelectedOptions = () => {
    setSelectedOrdersIds([]);
  };

  const handleSort = (attribute: string, ascending: boolean) => {
    setCurrentSortHeader(attribute);
    setSortingAsc(ascending);
  };

  useEffect(() => {
    if (data) {
      const filtered = sortCoupons(filterCoupons(data));
      setCouponList(filtered);
    }
  }, [data, searchText, currentSortHeader, selectedOrdersIds, sortingAsc]);

  const orderIds = sortBy(Array.from(new Set(data.map(coupon => coupon.orderId))));

  return (
    <div className="coupon-list">
      <PaginatorWrapper perPage={COUPONS_PER_PAGE} total={couponList.length}>
        {(indexStart, indexEnd) => (
          <CouponsTable
            currentSortHeader={currentSortHeader}
            orderIds={orderIds}
            rows={couponList.slice(indexStart, indexEnd)}
            selectedOrdersIds={selectedOrdersIds}
            sortingAsc={sortingAsc}
            onOrderIdSelect={handleOrderIdSelect}
            onClearAllSelectedOptions={handleClearAllSelectedOptions}
            onRowClick={id => history.push(ROUTES.COUPON_DETAILS(id))}
            onSort={handleSort}
          />
        )}
      </PaginatorWrapper>
    </div>
  );
};

export default CouponList;
