import React, { useState } from 'react';
import { Image } from 'react-bootstrap';
import { CouponRow, CouponStatusType } from 'utils/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import Formats from 'utils/formats';

import TableDetails from '../TableDetails';

import './styles.scss';

interface Props {
  row: CouponRow;
  onRowClick?: (string) => void;
}

const TableRow: React.FC<Props> = ({ row, onRowClick }) => {
  const [openDetails, setOpenDetails] = useState<boolean>(false);

  const handleOpenDetails = () => {
    setOpenDetails(!openDetails);
  };

  return (
    <>
      <tr className="table-row" key={row.orderId}>
        <td>{row.orderId}</td>
        <td className="image">
          <Image className="image" src={row.image} />
        </td>
        <td>
          <div className="table-row__title">{row.title}</div>
          <div className="table-row__description">{row.description}</div>
        </td>
        <td>{row.createdDate !== null ? Formats.date(row.createdDate) : '-'}</td>
        <td>{row.numberOfIos}</td>
        <td>{row.maxClips !== null ? Formats.formatNumber(row.maxClips) : '-'}</td>
        <td>{row.clips !== null ? Formats.formatNumber(row.clips) : '-'}</td>
        <td>{row.redemptions !== null ? Formats.formatNumber(row.redemptions) : '-'}</td>
        <td>{row.redemptionRate !== null ? `${row.redemptionRate}%` : '-'}</td>
        <td>
          <div className="table-row__plus-icon">
            {openDetails ? (
              <FontAwesomeIcon className="show-icon" icon={faMinus} onClick={handleOpenDetails} />
            ) : (
              <FontAwesomeIcon className="hide-icon" icon={faPlus} onClick={handleOpenDetails} />
            )}
          </div>
        </td>
      </tr>
      {openDetails && (
        <tr>
          <td className="table-row" colSpan={10} style={{ padding: 0 }}>
            <TableDetails row={row} onRowClick={onRowClick} />
          </td>
        </tr>
      )}
    </>
  );
};

export default TableRow;
