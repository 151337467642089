import * as actionTypes from 'containers/Profile/action-types';
import { ProfileType } from 'utils/types';
import StoreUtils from 'utils/store';
import ProfileUtils from 'utils/profile';
import Validations from 'utils/validations';

type StateType = {
  readonly loadingProfile: boolean;
  readonly profile: ProfileType;
  readonly showModal: boolean;
  readonly step: number; // 1 -start, 2 - form, 3 - confirmation
  readonly transientProfile: ProfileType;
  readonly transientProfileInvalidFields: Array<string>;
  readonly validatingProfile: boolean;
};

const initialState: StateType = {
  loadingProfile: false,
  profile: null,
  showModal: false,
  step: 1,
  transientProfile: ProfileUtils.createEmptyProfile(),
  transientProfileInvalidFields: [],
  validatingProfile: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.BILLING_ADDRESS_INFO_CLEAR:
      return {
        ...state,
        transientProfile: {
          ...state.transientProfile,
          billing: {
            ...state.transientProfile.billing,
            companyInfo: {
              name: '',
              street1: '',
              street2: '',
              city: '',
              state: null,
              zipCode: ''
            }
          }
        },
        transientProfileInvalidFields: state.transientProfileInvalidFields.filter(
          attribute => attribute !== 'billing.companyInfo.zipCode'
        )
      };
    case actionTypes.COMPANY_ADDRESS_INFO_COPY:
      return {
        ...state,
        transientProfile: {
          ...state.transientProfile,
          billing: {
            ...state.transientProfile.billing,
            companyInfo: {
              name: state.transientProfile.company.name,
              street1: state.transientProfile.company.street1,
              street2: state.transientProfile.company.street2,
              city: state.transientProfile.company.city,
              state: state.transientProfile.company.state,
              zipCode: Validations.isZipCodeValid(state.transientProfile.company.zipCode)
                ? state.transientProfile.company.zipCode
                : ''
            }
          }
        },
        transientProfileInvalidFields: state.transientProfileInvalidFields.filter(
          attribute => attribute !== 'billing.companyInfo.zipCode'
        )
      };
    case actionTypes.LOADING_PROFILE_SET:
      return {
        ...state,
        loadingProfile: action.payload
      };
    case actionTypes.MODAL_SHOW_SET:
      return {
        ...state,
        showModal: action.payload
      };
    case actionTypes.MODAL_START:
      return {
        ...state,
        showModal: true,
        step: initialState.step,
        transientProfile: initialState.transientProfile,
        transientProfileInvalidFields: initialState.transientProfileInvalidFields
      };
    case actionTypes.PROFILE_SET:
      return {
        ...state,
        profile: action.payload
      };
    case actionTypes.STEP_VALUE_SET:
      return {
        ...state,
        step: action.payload
      };
    case actionTypes.TRANSIENT_PROFILE_SET:
      return {
        ...state,
        transientProfile: action.payload
      };
    case actionTypes.TRANSIENT_PROFILE_VALUE_CHANGE:
      return {
        ...state,
        transientProfile: StoreUtils.updateObjectAttribute(
          state.transientProfile,
          action.payload.id,
          action.payload.value
        )
      };
    case actionTypes.TRANSIENT_PROFILE_INVALID_FIELDS_SET:
      return {
        ...state,
        transientProfileInvalidFields: action.payload
      };
    case actionTypes.VALIDATION_PROFILE_SET:
      return {
        ...state,
        validatingProfile: action.payload
      };
    default:
      return state;
  }
};

export default reducer;
