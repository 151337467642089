import * as React from 'react';
import {
  Dropdown as ReactBootstrapDropDown,
  DropdownButton as ReactBootstrapDropDownButton
} from 'react-bootstrap';
import './styles.scss';

type Item = {
  id: string;
  label: any;
  disabled?: boolean;
};

interface Props {
  items: Array<Item>;
  selectedItemId: string;
  title?: string;
  onSelect: any;
}

const Dropdown: React.FC<Props> = ({ items, selectedItemId, title = null, onSelect }) => {
  let dropDownTitle = title;
  if (dropDownTitle === null) {
    const selectedItem = items.find(item => item.id === selectedItemId);
    if (selectedItem !== undefined) {
      dropDownTitle = selectedItem.label;
    } else {
      dropDownTitle = '';
    }
  }
  return (
    <ReactBootstrapDropDownButton
      className="custom-dropdown"
      id="custom-dropdown"
      variant="light"
      title={dropDownTitle}
      onClick={e => e.stopPropagation()}
    >
      {items.map(item => (
        <ReactBootstrapDropDown.Item
          key={`item-${item.id}`}
          eventKey={item.id}
          className="dropdown-item"
          onSelect={() => onSelect(item.id)}
          disabled={item.disabled !== undefined ? item.disabled : false}
        >
          {item.label}
        </ReactBootstrapDropDown.Item>
      ))}
    </ReactBootstrapDropDownButton>
  );
};

export default Dropdown;
