import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import Button from 'react-bootstrap/Button';
import './styles.scss';

interface Props {
  onContinue: any;
}

const BeginNewProfileModal: React.FC<Props> = ({ onContinue }) => (
  <Modal backdrop="static" dialogClassName="begin-new-profile-modal" keyboard={false} show>
    <Modal.Body>
      <div className="bnpm-content">
        <div className="user">
          <FontAwesomeIcon color="#47535E" icon={faUser} size="3x" />
        </div>
        <div className="title">Let’s get your company profile set up first</div>
        <div className="message">
          We just need a bit more info before you start building your first coupon.
        </div>
        <Button className="btn-continue" onClick={onContinue}>
          CONTINUE TO COMPANY PROFILE SET UP
        </Button>
      </div>
    </Modal.Body>
  </Modal>
);

export default BeginNewProfileModal;
