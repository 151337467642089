import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from 'containers/Profile/actions';
import { ProfileFormValueValidation, ProfileType } from 'utils/types';
import { RootState } from 'store/storeInstance';

interface Props {
  children(BasicProps): React.ReactElement;
}

const BasicForm: React.FC<Props> = ({ children }) => {
  const dispatch = useDispatch();

  const profile = useSelector((state: RootState) => state.profile.transientProfile);
  const invalidFields = useSelector(
    (state: RootState) => state.profile.transientProfileInvalidFields
  );

  const onSameInfoChange = (id: string, value: boolean) => {
    dispatch(actions.changeTransientProfileAttributeValue(id, value));
    dispatch(actions.copyCompanyAddressInfo());
  };

  const onValueChange = (id: string, value: string) => {
    dispatch(actions.changeTransientProfileAttributeValue(id, value));
  };

  const onValueValidation = (args: ProfileFormValueValidation) => {
    dispatch(actions.validateTransientProfileAttribute(args));
  };

  const onValueValidationWithAction = action => {
    dispatch(action);
  };

  return children({
    invalidFields,
    profile,
    onSameInfoChange,
    onValueChange,
    onValueValidation,
    onValueValidationWithAction
  });
};

export default BasicForm;
