import React from 'react';
import InputMask from 'react-input-mask';
import './styles.scss';

interface Props {
  disabled?: boolean;
  invalid?: boolean;
  mask?: string;
  placeholder?: string;
  value: string;
  onBlur?: any;
  onChange: any;
}

const ProfileTextInput: React.FC<Props> = ({
  disabled = false,
  invalid = false,
  mask = null,
  placeholder,
  value,
  onBlur = null,
  onChange
}) => {
  const props = {
    className: invalid ? 'invalid' : null,
    disabled,
    placeholder,
    value,
    type: 'text',
    onBlur: onBlur !== null ? event => onBlur(event.target.value) : null,
    onChange: event => onChange(event.target.value)
  };
  return (
    <div className="profile-input">
      {mask === null ? <input {...props} /> : <InputMask {...props} mask={mask} maskChar={null} />}
    </div>
  );
};

export default ProfileTextInput;
