/* eslint-disable no-alert */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Alert, Col, Row, Spinner } from 'react-bootstrap';
import { SearchInput } from '@spins/react-ui-library';
import { useQuery, useMutation } from '@apollo/react-hooks';
import {
  BrandType,
  CouponRow,
  CouponStatusEnum,
  CouponStatusName,
  UserLevelType
} from 'utils/types';
import UsersUtils from 'utils/users';

import PageHeader from 'components/PageHeader';
import Widget from 'components/Widget';
import CouponsEmptyList from 'components/Coupons/EmptyList';
import StatusFilterDropdown from 'components/Coupons/StatusFilterDropdown';
import CouponList from './CouponList';
import {
  GetCouponsByOrderIdQuery as getCouponsByOrderIdQuery,
  GetCouponsByOrderIdQueryVariables as getCouponsByOrderIdQueryVariables,
  GetCouponsByOrderIdDocument as getCouponsByOrderIdDocument,
  UpdateApprovalStatusMutation as updateApprovalStatusMutation,
  UpdateApprovalStatusMutationVariables as updateApprovalStatusMutationVariables,
  UpdateApprovalStatusDocument as updateApprovalStatusDocument
} from '../../generated/graphql';
import './styles.scss';

interface Props {
  currentBrand: BrandType;
}

const Coupons: React.FC<Props> = () => {
  const [coupons, setCoupons] = useState<Array<CouponRow>>([]);
  const [searchText, setSearchText] = useState<string>('');
  const [status, setStatus] = useState<string>('all');

  const currentBrand = useSelector((state: any) => state.authentication.currentBrand);
  const userLevel = useSelector<UserLevelType>((state: any) => state.authentication.userLevel);

  const { data, error, loading, refetch } = useQuery<
    getCouponsByOrderIdQuery,
    getCouponsByOrderIdQueryVariables
  >(getCouponsByOrderIdDocument, {
    fetchPolicy: 'cache-and-network',
    variables: {
      filter: { client: { eq: currentBrand.id } },
      status: status === CouponStatusEnum.ALL ? null : status
    }
  });

  const [updateApprovalStatus] = useMutation<
    updateApprovalStatusMutation,
    updateApprovalStatusMutationVariables
  >(updateApprovalStatusDocument, {
    fetchPolicy: 'no-cache',
    onCompleted(updateCouponData) {
      if (updateCouponData.updateApprovalStatus === null) {
        alert('An error occurred while updating the coupon currentStatus, please try again later.');
      } else {
        refetch();
      }
    },
    onError(err) {
      if (err) {
        alert(err.message);
      }
    }
  });

  const handleStatusChange = (couponId, status) => {
    updateApprovalStatus({
      variables: {
        id: couponId,
        approvalStatus: status === 'scheduled' ? 'approved' : status
      }
    });
  };

  const mapCoupons = couponList => {
    return couponList.map(coupon => {
      const ios = coupon.ios.map(io => {
        let statusOptions = null;
        let onStatusChange = null;

        if (UsersUtils.isAdmin(userLevel as string)) {
          if (io.status === CouponStatusEnum.PENDING) {
            statusOptions = [CouponStatusEnum.HOLD_FOR_PAYMENT, CouponStatusEnum.SCHEDULED];
            onStatusChange = (id, status) => handleStatusChange(id, status);
          } else if (io.status === CouponStatusEnum.HOLD_FOR_PAYMENT) {
            statusOptions = [CouponStatusEnum.SCHEDULED];
            onStatusChange = (id, status) => handleStatusChange(id, status);
          }
        }

        return {
          ...io,
          statusOptions,
          onStatusChange
        };
      });

      return {
        ...coupon,
        ios: ios
      };
    });
  };

  useEffect(() => {
    if (data && data.listCouponsByOrderId) {
      setCoupons(mapCoupons(data.listCouponsByOrderId));
    }
  }, [data]);

  const handleSearch = (value: string) => {
    setSearchText(value);
  };

  const emptyList = data?.listCouponsByOrderId.length === 0 && status === CouponStatusEnum.ALL;

  return (
    <div className="coupons-container">
      <Row>
        <PageHeader direction="rows">
          <PageHeader.Title title="Digital Coupons" />
          {/*
            fix: disable create coupon
              <CreateCouponButton />
          */}
        </PageHeader>
      </Row>
      <Row>
        <Col className="mt-4">
          {loading && !error && (
            <Col lg={{ span: 4, offset: 4 }} className="coupons-container__loading text-center">
              <Spinner animation="border" className="loading-icon" />
            </Col>
          )}
          {!loading && !error && emptyList && (
            <Col lg={{ span: 4, offset: 4 }}>
              <CouponsEmptyList />
            </Col>
          )}
          {!loading && !error && data && !emptyList && (
            <Widget>
              <Widget.Header>
                <div className="coupons-container__title title">
                  {`Digital Coupons (${coupons.length})`}
                </div>
                <div className="center coupons-container__search">
                  <SearchInput onChange={handleSearch} />
                </div>
                <div className="push-right">
                  <StatusFilterDropdown
                    title={CouponStatusName[status]}
                    selected={status}
                    onSelect={key => {
                      setStatus(key);
                    }}
                  />
                </div>
              </Widget.Header>
              <Widget.Body>
                {error && (
                  <Col lg={{ span: 4, offset: 4 }} className="mt-4">
                    <Alert variant="danger">An error occurred. Try again later</Alert>
                  </Col>
                )}
                {!error && <CouponList data={coupons} searchText={searchText} />}
              </Widget.Body>
            </Widget>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default Coupons;
