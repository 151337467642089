import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/free-regular-svg-icons';
import './styles.scss';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import ConfirmationModal from 'components/ConfirmationModal';
import { CouponImage } from 'utils/types';

interface Props {
  image?: any;
  onDrop: (any) => void;
}

const OfferImageDropZone: React.FC<Props> = ({ image = null, onDrop }) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const onDropAccepted = files => {
    if (files.length > 0) {
      const image = new Image();
      image.addEventListener('load', () => {
        if (image.width > CouponImage.MAX_WIDTH || image.height > CouponImage.MAX_HEIGHT) {
          setShowError(true);
          setErrorMessage(
            `The image size should be ${CouponImage.MAX_WIDTH}px x ${CouponImage.MAX_HEIGHT}px maximum.`
          );
        } else {
          setShowError(false);
          onDrop(files[0]);
        }
      });
      image.src = URL.createObjectURL(files[0]);
    }
  };

  const onDropRejected = files => {
    if (files.length === 1) {
      // File size to big
      setShowError(true);
      setErrorMessage('File size too big.');
    } else if (files.length > 1) {
      setShowError(true);
      setErrorMessage('Only one file allowed.');
    }
  };

  const onImageRemove = () => {
    setShowConfirmation(true);
  };

  const handleModalClose = () => {
    setShowConfirmation(false);
  };

  const handleModalOk = () => {
    setShowConfirmation(false);
    onDrop(null);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: CouponImage.ACCEPTED_TYPES,
    onDrop: onDropAccepted,
    onDropRejected,
    maxSize: CouponImage.MAX_UPLOAD_SIZE,
    multiple: false
  });

  return (
    <React.Fragment>
      {image !== null ? (
        <div className="offer-image-drop-zone preview">
          <img src={image instanceof File ? URL.createObjectURL(image) : image} alt="preview" />
          <div className="remove-overlay">
            <Button className="btn-remove" onClick={onImageRemove}>
              Remove
            </Button>
          </div>
        </div>
      ) : (
        <div {...getRootProps({ className: 'offer-image-drop-zone' })}>
          <input {...getInputProps()} />
          <div className="inner-container">
            <div className="text-container">
              <div className="icon-container">
                <FontAwesomeIcon className="icon" icon={faFile} size="3x" />
              </div>
              <div className="label">
                <strong>Drag & Drop</strong> your files here or{' '}
                <strong style={{ textDecoration: 'underline ' }}>browse</strong> your computer
              </div>
            </div>
          </div>
        </div>
      )}
      <Alert
        dismissible
        show={showError}
        style={{ marginTop: '10px' }}
        variant="danger"
        onClose={() => setShowError(false)}
      >
        {errorMessage}
      </Alert>
      <ConfirmationModal
        message="Are you sure you want to remove the image?"
        show={showConfirmation}
        onCancel={handleModalClose}
        onOk={handleModalOk}
      />
    </React.Fragment>
  );
};

export default OfferImageDropZone;
