import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { Col, Spinner } from 'react-bootstrap';
import * as actions from 'containers/CouponEdit/actions';
import { CouponType } from 'utils/types';
import CouponsUtils from 'utils/coupons';
import { RootState } from 'store/storeInstance';
import {
  GetCouponQuery as getCouponQuery,
  GetCouponQueryVariables as getCouponQueryVariables,
  GetCouponDocument as getCouponDocument,
  UpdateCouponMutation as updateCouponMutation,
  UpdateCouponMutationVariables as updateCouponMutationVariables,
  UpdateCouponDocument as updateCouponDocument
} from '../../generated/graphql';

interface Props {
  children(coupon: CouponType, handleCouponUpdate, handleCouponCancel, updating): React.ReactNode;
}

const CouponEdit: React.FC<Props> = ({ children }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams<any>();

  const [updating, setUpdating] = useState(false);
  const coupon = useSelector((state: RootState) => state.couponEdit.coupon);
  const loadingCoupon = useSelector((state: RootState) => state.couponEdit.loadingCoupon);

  const onCouponEditLoad = (coupon: CouponType) => {
    dispatch(actions.loadCouponEdit(coupon));
  };

  const onFinish = (couponId: string, history: any) => {
    dispatch(actions.finishEdition(couponId, history));
  };

  const { data, loading } = useQuery<getCouponQuery, getCouponQueryVariables>(getCouponDocument, {
    fetchPolicy: 'no-cache',
    variables: { couponId: params.id }
  });

  useEffect(() => {
    if (data && !loading) {
      dispatch(actions.setLoadingCoupon(false));

      const coupon: CouponType = CouponsUtils.mapCouponFromBackend(data.getCoupon);
      onCouponEditLoad(coupon);
    } else {
      dispatch(actions.setLoadingCoupon(true));
    }
  }, [data, loading]);

  const [updateCoupon] = useMutation<updateCouponMutation, updateCouponMutationVariables>(
    updateCouponDocument,
    {
      fetchPolicy: 'no-cache',
      onCompleted(data) {
        setUpdating(false);
        if (data.updateCoupon === null) {
          alert('An error occurred while updating the coupon, please try again later.');
        } else {
          onFinish(data.updateCoupon.id, history);
        }
      },
      onError(err) {
        setUpdating(false);
        console.log(err);
      }
    }
  );

  const handleCouponUpdate = couponToUpdate => {
    setUpdating(true);
    updateCoupon({
      variables: {
        coupon: couponToUpdate
      }
    });
  };

  const handleCouponCancel = () => {
    onFinish(coupon.id, history);
  };

  return (
    <div className="coupon-edit">
      {loadingCoupon ? (
        <Col lg={{ span: 4, offset: 4 }} className="text-center" style={{ marginTop: '200px' }}>
          <Spinner animation="border" className="loading-icon" />
        </Col>
      ) : (
        <div>{children(coupon, handleCouponUpdate, handleCouponCancel, updating)}</div>
      )}
    </div>
  );
};

export default CouponEdit;
