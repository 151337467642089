import * as actionTypes from 'containers/CouponCreation/action-types';
import { BuildOfferFormType, RetailerCampaigns } from 'utils/types';

type StateType = {
  readonly currentStep: number;
  readonly offerForm: BuildOfferFormType;
  readonly selectedProductsIds: Array<string>;
  readonly selectedRetailers: Array<RetailerCampaigns>;
  readonly showConfirmationModal: boolean;
  readonly termsAndConditions: boolean;
  readonly isCreating: boolean;
};

const initialState: StateType = {
  currentStep: 1,
  offerForm: {
    blendedGS1: '',
    campaignImage: null,
    description: '',
    faceValue: null,
    legalCopy: '',
    manufacturerId: '',
    maxValue: null,
    notes: '',
    productsImage: null,
    purchaseRequirementDescription: '',
    quantity: 1,
    itemsRewardQuantity: 1,
    title: '',
    type: null
  },
  selectedProductsIds: [],
  selectedRetailers: [],
  showConfirmationModal: false,
  termsAndConditions: true,
  isCreating: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CAMPAIGN_DETAILS_ADD:
      return {
        ...state,
        selectedRetailers: state.selectedRetailers.map(retailerCampaigns => {
          if (retailerCampaigns.retailer.id === action.payload.retailerId) {
            return {
              ...retailerCampaigns,
              campaigns: retailerCampaigns.campaigns.concat([action.payload.campaign])
            };
          }
          return retailerCampaigns;
        })
      };
    case actionTypes.CAMPAIGN_DETAILS_REMOVE:
      return {
        ...state,
        selectedRetailers: state.selectedRetailers.map(retailerCampaigns => {
          if (retailerCampaigns.retailer.id === action.payload.retailerId) {
            return {
              ...retailerCampaigns,
              campaigns: retailerCampaigns.campaigns.filter(
                (campaign, index) => index !== action.payload.index
              )
            };
          }
          return retailerCampaigns;
        })
      };
    case actionTypes.CAMPAIGN_DETAILS_VALUE_CHANGE:
      return {
        ...state,
        selectedRetailers: state.selectedRetailers.map(retailerCampaigns => {
          if (retailerCampaigns.retailer.id === action.payload.retailerId) {
            return {
              ...retailerCampaigns,
              campaigns: retailerCampaigns.campaigns.map((campaign, index) => {
                if (index === action.payload.index) {
                  return {
                    ...campaign,
                    [action.payload.attribute]: action.payload.value
                  };
                }
                return campaign;
              })
            };
          }
          return retailerCampaigns;
        })
      };
    case actionTypes.FORM_SET:
      return {
        ...state,
        offerForm: {
          blendedGS1: action.payload.blendedGS1
            ? action.payload.blendedGS1
            : initialState.offerForm.blendedGS1,
          campaignImage: action.payload.campaignImage,
          description: action.payload.description,
          faceValue: action.payload.faceValue,
          legalCopy: action.payload.legalCopy,
          manufacturerId: action.payload.manufacturerId
            ? action.payload.manufacturerId
            : initialState.offerForm.manufacturerId,
          maxValue: action.payload.maxValue
            ? action.payload.maxValue
            : initialState.offerForm.maxValue,
          notes: action.payload.notes ? action.payload.notes : initialState.offerForm.notes,
          productsImage: action.payload.productImage
            ? action.payload.productImage
            : initialState.offerForm.productsImage,
          purchaseRequirementDescription: action.payload.purchaseRequirementDescription,
          quantity: action.payload.quantity
            ? action.payload.quantity
            : initialState.offerForm.quantity,
          itemsRewardQuantity: action.payload.itemsRewardQuantity
            ? action.payload.itemsRewardQuantity
            : initialState.offerForm.itemsRewardQuantity,
          title: action.payload.title,
          type: action.payload.type
        },
        selectedProductsIds: action.payload.productsIds.slice(),
        selectedRetailers: action.payload.campaigns.slice()
      };
    case actionTypes.NEW_COUPON_CONFIRMATION_SHOW_SET:
      return {
        ...state,
        showConfirmationModal: action.payload
      };
    case actionTypes.NEW_COUPON_START:
      return {
        ...state,
        currentStep: initialState.currentStep,
        offerForm: initialState.offerForm,
        selectedProductsIds: initialState.selectedProductsIds,
        selectedRetailers: initialState.selectedRetailers,
        termsAndConditions: initialState.termsAndConditions,
        isCreating: initialState.isCreating
      };
    case actionTypes.OFFER_FORM_VALUE_CHANGE:
      return {
        ...state,
        offerForm: {
          ...state.offerForm,
          [action.payload.attribute]: action.payload.value
        }
      };
    case actionTypes.OFFER_TYPE_CHANGE:
      return {
        ...state,
        offerForm: {
          ...state.offerForm,
          maxValue: initialState.offerForm.maxValue,
          quantity: initialState.offerForm.quantity,
          itemsRewardQuantity: initialState.offerForm.itemsRewardQuantity
        }
      };
    case actionTypes.PRODUCTS_SELECT:
      return {
        ...state,
        selectedProductsIds: action.payload
      };
    case actionTypes.RETAILER_SET:
      return {
        ...state,
        selectedRetailers: action.payload
      };
    case actionTypes.STEP_GO_TO:
      return {
        ...state,
        currentStep: action.payload
      };
    case actionTypes.TERMS_AND_CONDITIONS_CHANGE:
      return {
        ...state,
        termsAndConditions: action.payload
      };
    case actionTypes.IS_CREATING_CHANGE:
      return {
        ...state,
        isCreating: action.payload
      };
    default:
      return state;
  }
};

export default reducer;
