import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AuthSession, useAuthSession } from '@spins/react-sso-provider';
import * as actions from 'containers/Authentication/actions';
import { RootState } from 'store/storeInstance';
import { ROUTES } from 'utils/routes';

interface Props {
  children: React.ReactNode;
}

const UserProvider: React.FC<Props> = ({ children }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { user }: AuthSession = useAuthSession();
  const isLoggedIn = useSelector((state: RootState) => state.authentication.isLoggedIn);

  useEffect(() => {
    const client = user.clients.find(client => client.is_active);

    if (client) {
      dispatch(actions.requestLogin(user.email, user.firstName, user.lastName, client));
    } else {
      console.log('No current client/brand is active, back to login.');
      history.push(ROUTES.REDIRECT_CMS);
    }
  }, [user]);

  return <>{isLoggedIn ? children : <div className="app-loader" />}</>;
};

export default UserProvider;
