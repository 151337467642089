/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import Dropdown from 'react-bootstrap/Dropdown';
import SearchInput from 'components/SearchInput';
import Checkbox from 'components/Checkbox';
import './styles.scss';
import Button from 'react-bootstrap/Button';

const CustomToggle = React.forwardRef(({ onClick }: any, ref) => {
  const handleClick = e => {
    e.preventDefault();
    e.stopPropagation();
    onClick(e);
  };

  return (
    <div ref={ref as any} role="button" onClick={handleClick}>
      <FontAwesomeIcon className="filter-icon" icon={faFilter} />
    </div>
  );
});

const CustomMenu = React.forwardRef(
  ({ children, style, className, 'aria-labelledby': labeledBy }: any, ref) => {
    return (
      <div ref={ref as any} style={style} className={className} aria-labelledby={labeledBy}>
        {children}
      </div>
    );
  }
);

interface ClearAllOptionProps {
  selectedCount: number;
  onClearAllSelectedOptions(): void;
}

const ClearAllOption: React.FC<ClearAllOptionProps> = ({
  selectedCount,
  onClearAllSelectedOptions
}) => (
  <div className="selection-container">
    <span>Selected {selectedCount}</span>
    <Button
      className="clear-all-selected-options"
      variant="link"
      disabled={selectedCount === 0}
      onClick={onClearAllSelectedOptions}
    >
      Clear all
    </Button>
  </div>
);

type Option = {
  id: string;
  label: string;
};

interface Props {
  options: Array<Option>;
  selectedOptionsIds: Array<string>;
  title: string;
  onSelect(id: string): void;
  onClearAllSelectedOptions(): void;
}

const Carbon8Filter: React.FC<Props> = ({
  options,
  selectedOptionsIds,
  title,
  onSelect,
  onClearAllSelectedOptions
}) => {
  const [searchText, setSearchText] = useState('');
  return (
    <div className="carbon-8-filter">
      <Dropdown>
        <Dropdown.Toggle as={CustomToggle} id="carbon8-dropdown" />
        <Dropdown.Menu as={CustomMenu}>
          <SearchInput
            placeholder={`Search ${title}`}
            text={searchText}
            onChange={value => setSearchText(value)}
          />
          <div className="options-container">
            <ClearAllOption
              selectedCount={selectedOptionsIds.length}
              onClearAllSelectedOptions={onClearAllSelectedOptions}
            />
            {options
              .filter(
                option =>
                  searchText === '' ||
                  option.label.toLowerCase().indexOf(searchText.toLowerCase()) > -1
              )
              .map(option => (
                <div key={option.id} className="option-container">
                  <Checkbox
                    id={option.id}
                    checked={selectedOptionsIds.some(id => id === option.id)}
                    label={option.label}
                    onChange={onSelect}
                  />
                </div>
              ))}
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default Carbon8Filter;
