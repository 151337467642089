import React from 'react';
import { Route, Switch } from 'react-router';
import { AuthApp, SSOConfiguration } from '@spins/react-sso-provider';
import { Spinner } from 'react-bootstrap';
import { ROUTES } from 'utils/routes';
// import first than Main so particular styles defined in components have more importance than then one defined in bootstrap
import 'styles/custom-bootstrap.scss';
import { getChangeBrandUrl, getCmsLoginUrl } from 'utils/authentication';
import Main from 'containers/Main';
import UserProvider from './UserProvider';

import './App.scss';

const ssoConfiguration: SSOConfiguration = {
  BearerGrant: process.env.REACT_APP_BEARER_GRANT as string,
  SsoUrl: process.env.REACT_APP_AUTH_API_URL as string,
  SsoClientId: process.env.REACT_APP_AUTH_CLIENT_ID as string
};

const App: React.FC = () => (
  <Switch>
    <Route
      exact
      path={ROUTES.CHANGE_BRAND}
      component={() => {
        // @ts-ignore
        window.location = getChangeBrandUrl();
        return null;
      }}
    />
    <Route
      exact
      path={ROUTES.REDIRECT_CMS}
      component={() => {
        // @ts-ignore
        window.location = getCmsLoginUrl();
        return null;
      }}
    />
    <AuthApp
      appId={process.env.REACT_APP_CLIENT_ID as string}
      ssoConfiguration={ssoConfiguration}
      loader={
        <div className="app-loader">
          <Spinner animation="border" variant="primary" />
        </div>
      }
    >
      <UserProvider>
        <Main />
      </UserProvider>
    </AuthApp>
  </Switch>
);

export default App;
