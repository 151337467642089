import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from '@apollo/react-hooks';
import { ProfileType } from 'utils/types';
import { RootState } from 'store/storeInstance';
import * as actions from 'containers/Profile/actions';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import ProfileUtils from 'utils/profile';
import {
  CreateProfileMutation as createProfileMutation,
  CreateProfileMutationVariables as createProfileMutationVariables,
  CreateProfileDocument as createProfileDocument,
  UpdateProfileMutation as updateProfileMutation,
  UpdateProfileMutationVariables as updateProfileMutationVariables,
  UpdateProfileDocument as updateProfileDocument
} from '../../../generated/graphql';

import './styles.scss';

interface Props {
  onComplete: any;
}

const SaveProfileButton: React.FC<Props> = ({ onComplete }) => {
  const dispatch = useDispatch();

  const [processing, setProcessing] = useState(false);
  const currentBrand = useSelector((state: RootState) => state.authentication.currentBrand);
  const profile = useSelector((state: RootState) => state.profile.profile);
  const transientProfile = useSelector((state: RootState) => state.profile.transientProfile);
  const transientProfileInvalidFields = useSelector(
    (state: RootState) => state.profile.transientProfileInvalidFields
  );
  const validatingProfile = useSelector((state: RootState) => state.profile.validatingProfile);

  const onProfileSet = (value: ProfileType) => {
    dispatch(actions.setProfile(value));
  };

  const [createProfile] = useMutation<createProfileMutation, createProfileMutationVariables>(
    createProfileDocument,
    {
      fetchPolicy: 'no-cache',
      onCompleted(data) {
        setProcessing(false);

        if (data && data.createProfile) {
          const newProfile = ProfileUtils.cloneProfile(transientProfile);
          onProfileSet(newProfile);
          onComplete();
        } else {
          alert('Error occurred while creating your profile');
        }
      },
      onError(err) {
        setProcessing(false);
        alert(`Error occurred while creating your profile: ${err.message}`);
      }
    }
  );

  const [updateProfile] = useMutation<updateProfileMutation, updateProfileMutationVariables>(
    updateProfileDocument,
    {
      fetchPolicy: 'no-cache',
      onCompleted(data) {
        setProcessing(false);

        if (data && data.updateProfile) {
          const newProfile = ProfileUtils.cloneProfile(transientProfile);
          onProfileSet(newProfile);
          onComplete();
        } else {
          alert('Error occurred while updating your profile');
        }
      },
      onError(err) {
        setProcessing(false);
        alert(`Error occurred while updating your profile: ${err.message}`);
      }
    }
  );

  const handleClick = () => {
    const mutation = profile === null ? createProfile : updateProfile;
    setProcessing(true);
    mutation({
      variables: {
        profile: ProfileUtils.mapToBackend(currentBrand.id, transientProfile)
      }
    });
  };

  return (
    <Button
      className="btn-save-profile"
      disabled={
        processing ||
        transientProfileInvalidFields.length > 0 ||
        validatingProfile ||
        !ProfileUtils.isProfileComplete(transientProfile)
      }
      onClick={handleClick}
    >
      {processing && (
        <Spinner
          as="span"
          animation="border"
          className="save-profile-spinner"
          size="sm"
          role="status"
          aria-hidden="true"
        />
      )}
      SAVE
    </Button>
  );
};

export default SaveProfileButton;
