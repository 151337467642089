import * as React from 'react';
import './styles.scss';

interface Props {
  title: string;
}

const Title: React.FC<Props> = ({ title }) => <div className="page-header-title">{title}</div>;

export default Title;
