import * as actionTypes from 'containers/Profile/action-types';
import { ProfileFormValueValidation, ProfileType } from 'utils/types';

export const clearBillingAddressInfo = () => ({
  type: actionTypes.BILLING_ADDRESS_INFO_CLEAR
});

export const copyCompanyAddressInfo = () => ({
  type: actionTypes.COMPANY_ADDRESS_INFO_COPY
});

export const changeTransientProfileAttributeValue = (id, value) => ({
  type: actionTypes.TRANSIENT_PROFILE_VALUE_CHANGE,
  payload: { id, value }
});

export const setInvalidFields = (value: Array<any>) => ({
  type: actionTypes.TRANSIENT_PROFILE_INVALID_FIELDS_SET,
  payload: value
});

export const setLoadingProfile = value => ({
  type: actionTypes.LOADING_PROFILE_SET,
  payload: value
});

export const setProfile = (value: ProfileType) => ({
  type: actionTypes.PROFILE_SET,
  payload: value
});

export const setShowModal = value => ({
  type: actionTypes.MODAL_SHOW_SET,
  payload: value
});

export const setStep = value => ({
  type: actionTypes.STEP_VALUE_SET,
  payload: value
});

export const setTransientProfile = (value: ProfileType) => ({
  type: actionTypes.TRANSIENT_PROFILE_SET,
  payload: value
});

export const setValidatingProfile = (value: boolean) => ({
  type: actionTypes.VALIDATION_PROFILE_SET,
  payload: value
});

export const startModal = () => ({
  type: actionTypes.MODAL_START
});

export const validateTransientProfileAttribute = ({
  id,
  invalidFields,
  value,
  validate
}: ProfileFormValueValidation) => {
  if (value !== '') {
    if (validate(value)) {
      if (invalidFields.some(attribute => id === attribute)) {
        return setInvalidFields(invalidFields.filter(attribute => id !== attribute));
      }
    } else {
      if (!invalidFields.some(attribute => id === attribute)) {
        return setInvalidFields(invalidFields.concat([id]));
      }
    }
  } else {
    if (invalidFields.some(attribute => id === attribute)) {
      return setInvalidFields(invalidFields.filter(attribute => id !== attribute));
    }
  }
  return setInvalidFields(invalidFields);
};

export const validatePhoneNumber = (id, value, invalidFields) => ({
  type: actionTypes.PHONE_NUMBER_VALIDATE,
  payload: { id, value, invalidFields }
});
