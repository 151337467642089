import * as React from 'react';
import { Switch } from 'react-router';
import { ROUTES } from 'utils/routes';
import Coupons from 'containers/Coupons';
import CouponCreation from 'containers/CouponCreation';
import CouponDetails from 'containers/CouponDetails';
import CompanyProfile from 'containers/Profile/CompanyProfile';
import CouponEditProducts from 'containers/CouponEdit/Products';
import CouponEditRetailers from 'containers/CouponEdit/Retailers';
import CouponEditDetails from 'containers/CouponEdit/Details';
import ErrorBoundary from 'components/ErrorBoundary';
import PrivateRoute from 'components/PrivateRoute';

const Main: React.FC = () => (
  <ErrorBoundary>
    <Switch>
      <PrivateRoute exact path={ROUTES.COMPANY_PROFILE} component={CompanyProfile} footer />
      <PrivateRoute exact path={ROUTES.COUPONS} component={Coupons} footer />
      <PrivateRoute exact path={ROUTES.COUPON_CREATION} component={CouponCreation} />
      <PrivateRoute exact path={ROUTES.COUPON_DETAILS()} component={CouponDetails} footer />
      <PrivateRoute exact path={ROUTES.COUPON_EDIT_DETAILS()} component={CouponEditDetails} />
      <PrivateRoute exact path={ROUTES.COUPON_EDIT_PRODUCTS()} component={CouponEditProducts} />
      <PrivateRoute exact path={ROUTES.COUPON_EDIT_RETAILERS()} component={CouponEditRetailers} />
    </Switch>
  </ErrorBoundary>
);

export default Main;
