import * as actionTypes from 'containers/CouponEdit/action-types';
import { CouponType } from 'utils/types';

type StateType = {
  readonly coupon: CouponType;
  readonly loadingCoupon: boolean;
  readonly saveAvailable: boolean;
};

const initialState: StateType = {
  coupon: null,
  loadingCoupon: false,
  saveAvailable: true
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.COUPON_LOADING_SET:
      return {
        ...state,
        loadingCoupon: action.payload
      };
    case actionTypes.COUPON_SAVE_AVAILABLE_SET:
      return {
        ...state,
        saveAvailable: action.payload
      };
    case actionTypes.COUPON_SET:
      return {
        ...state,
        coupon: action.payload
      };
    case actionTypes.COUPON_EDITION_RESET:
      return {
        ...state,
        coupon: initialState.coupon
      };
    default:
      return state;
  }
};

export default reducer;
