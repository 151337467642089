import * as React from 'react';
import './styles.scss';

interface Props {
  children: React.ReactNode;
}

const Header: React.FC<Props> = ({ children }) => <div className="widget-header">{children}</div>;

export default Header;
