import React from 'react';
import ProfileFormField from 'components/Profile/FormField';
import ProfileTextInput from 'components/Profile/TextInput';
import Validations from 'utils/validations';
import StateSelect from 'components/Profile/StateSelect';
import 'containers/Profile/form-styles.scss';
import BasicForm from 'containers/Profile/BasicForm';
import { validatePhoneNumber } from 'containers/Profile/actions';

const CompanyAddressForm: React.FC = () => (
  <BasicForm>
    {({
      invalidFields,
      profile,
      onValueChange,
      onValueValidation,
      onValueValidationWithAction
    }) => (
      <div className="address-form">
        <div className="title">Company Address</div>
        <div className="field-container">
          <ProfileFormField
            id="company.name"
            label="company name"
            onChange={(id, value) => onValueChange(id, value)}
          >
            {(invalid, onBlur, onChange) => (
              <ProfileTextInput
                placeholder="Enter Company Name"
                value={profile.company.name}
                onChange={onChange}
              />
            )}
          </ProfileFormField>
        </div>
        <div className="field-container">
          <ProfileFormField
            id="company.street1"
            label="street"
            onChange={(id, value) => onValueChange(id, value)}
          >
            {(invalid, onBlur, onChange) => (
              <ProfileTextInput
                placeholder="Enter Street Address"
                value={profile.company.street1}
                onChange={onChange}
              />
            )}
          </ProfileFormField>
        </div>
        <div className="field-container">
          <ProfileFormField
            id="company.street2"
            label="street 2"
            onChange={(id, value) => onValueChange(id, value)}
          >
            {(invalid, onBlur, onChange) => (
              <ProfileTextInput
                placeholder="Enter Street Address"
                value={profile.company.street2}
                onChange={onChange}
              />
            )}
          </ProfileFormField>
        </div>
        <div className="field-container">
          <div className="city-container">
            <ProfileFormField
              id="company.city"
              label="city"
              onChange={(id, value) => onValueChange(id, value)}
            >
              {(invalid, onBlur, onChange) => (
                <ProfileTextInput
                  placeholder="Enter City"
                  value={profile.company.city}
                  onChange={onChange}
                />
              )}
            </ProfileFormField>
          </div>
          <div className="special-field-container">
            <ProfileFormField
              id="company.state"
              label="state"
              onChange={(id, value) => onValueChange(id, value)}
            >
              {(invalid, onBlur, onChange) => (
                <StateSelect value={profile.company.state} onChange={onChange} />
              )}
            </ProfileFormField>
          </div>
          <div className="special-field-container">
            <ProfileFormField
              errorMessage="Zip Code invalid"
              id="company.zipCode"
              invalid={invalidFields.some(attribute => attribute === 'company.zipCode')}
              label="zipcode"
              validator={Validations.isZipCodeValid}
              onBlur={(id, value, validate) =>
                onValueValidation({ id, value, invalidFields, validate })
              }
              onChange={(id, value) => onValueChange(id, value)}
            >
              {(invalid, onBlur, onChange) => (
                <ProfileTextInput
                  invalid={invalid}
                  placeholder="Enter Zip Code"
                  value={profile.company.zipCode}
                  onChange={onChange}
                  onBlur={onBlur}
                />
              )}
            </ProfileFormField>
          </div>
        </div>
        <div className="field-container">
          <ProfileFormField
            errorMessage="Please enter a valid 10 digit phone number"
            id="company.phoneNumber"
            invalid={invalidFields.some(attribute => attribute === 'company.phoneNumber')}
            label="phone number"
            onBlur={(id, value) => {
              onValueValidationWithAction(validatePhoneNumber(id, value, invalidFields));
            }}
            onChange={(id, value) => onValueChange(id, value)}
          >
            {(invalid, onBlur, onChange) => (
              <ProfileTextInput
                invalid={invalid}
                mask="(999) 999-9999"
                placeholder="Enter Phone Number"
                value={profile.company.phoneNumber}
                onChange={onChange}
                onBlur={onBlur}
              />
            )}
          </ProfileFormField>
        </div>
      </div>
    )}
  </BasicForm>
);

export default CompanyAddressForm;
