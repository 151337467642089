import React from 'react';
import classNames from 'classnames';
import { OfferTypeItem } from 'utils/types';

import './styles.scss';

type OfferTypeItemType = {
  id: OfferTypeItem;
  label: string;
  description: string;
};

interface Props {
  selectedType?: OfferTypeItem;
  onSelect: any;
  disabled?: boolean;
}

const types: Array<OfferTypeItemType> = [
  {
    id: 'dollar',
    label: '$',
    description: '$ discount'
  },
  {
    id: 'percentage',
    label: '%',
    description: '% discount'
  },
  {
    id: 'bogo',
    label: 'BOGO',
    description: 'buy x get y'
  },
  {
    id: 'free',
    label: 'free',
    description: 'free item'
  }
];

const OfferType: React.FC<Props> = ({ selectedType, onSelect, disabled }) => (
  <div className="offer-type">
    {types.map(type => {
      const selected = selectedType === type.id;
      const itemClass = classNames({
        'offer-type-item': true,
        'offer-type-item__disabled': disabled,
        selected: selected
      });
      const labelClass = classNames({
        label: true,
        selected: selected
      });
      const descriptionClass = classNames({
        description: true,
        selected: selected
      });
      return (
        <div
          key={type.id}
          className={itemClass}
          onClick={() => !selected && !disabled && onSelect(type.id)}
          data-testid={`offer-type-input-${type.id}`}
        >
          <div className={labelClass}>{type.label}</div>
          <div className={descriptionClass}>{type.description}</div>
        </div>
      );
    })}
  </div>
);

export default OfferType;
