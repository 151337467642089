import { createStore, combineReducers, compose, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import couponCreationReducer from 'containers/CouponCreation/reducer';
import couponCreationSagas from 'containers/CouponCreation/sagas';
import welcomeModalReducer from 'containers/WelcomeModal/reducer';
import profileReducer from 'containers/Profile/reducer';
import profileSagas from 'containers/Profile/sagas';
import authenticationReducer from 'containers/Authentication/reducer';
import authenticationSagas from 'containers/Authentication/sagas';
import couponEditReducer from 'containers/CouponEdit/reducer';
import couponEditSagas from 'containers/CouponEdit/sagas';

const sagaMiddleware = createSagaMiddleware();

const reducers = combineReducers({
  authentication: authenticationReducer,
  couponCreation: couponCreationReducer,
  couponEdit: couponEditReducer,
  profile: profileReducer,
  welcomeModal: welcomeModalReducer
});

let composeEnhancers = compose;
if (process.env.NODE_ENV === 'development') {
  composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
}

const configureStore = (initialState = {}) => {
  const store = createStore(
    reducers,
    initialState,
    composeEnhancers(applyMiddleware(sagaMiddleware))
  );
  [authenticationSagas, couponCreationSagas, profileSagas, couponEditSagas].map(saga =>
    sagaMiddleware.run(saga)
  );
  return store;
};

export default configureStore;
