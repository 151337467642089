import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/storeInstance';
import { Col, Row } from 'react-bootstrap';
import BuildOffer from 'containers/CouponCreation/BuildOffer';
import CouponEdit from 'containers/CouponEdit/index';
import CouponEditPageHeader from 'components/CouponEdit/PageHeader';
import CouponsUtils from 'utils/coupons';

const CouponEditDetails: React.FC = () => {
  const offerForm = useSelector((state: RootState) => state.couponCreation.offerForm);

  const handleSave = async (handleCouponUpdate, coupon) => {
    let campaignImage = null;
    if (offerForm.campaignImage instanceof File) {
      campaignImage = await CouponsUtils.mapImageFile(offerForm.campaignImage);
    }
    let productImage = null;
    if (offerForm.productsImage !== null && offerForm.productsImage instanceof File) {
      productImage = await CouponsUtils.mapImageFile(offerForm.productsImage);
    }
    handleCouponUpdate({
      id: coupon.id,
      ...CouponsUtils.mapOfferFormToBackend(offerForm, campaignImage, productImage)
    });
  };

  return (
    <CouponEdit>
      {(coupon, handleCouponUpdate, handleCouponCancel, updating) => {
        if (coupon !== null) {
          return (
            <React.Fragment>
              <Row>
                <CouponEditPageHeader
                  coupon={coupon}
                  section="Details"
                  saveDisabled={!CouponsUtils.isOfferFormValid(offerForm)}
                  saving={updating}
                  onCancel={handleCouponCancel}
                  onSave={() => handleSave(handleCouponUpdate, coupon)}
                />
              </Row>
              <Row>
                <Col className="mt-4">
                  <BuildOffer />
                </Col>
              </Row>
            </React.Fragment>
          );
        }
        return null;
      }}
    </CouponEdit>
  );
};

export default CouponEditDetails;
