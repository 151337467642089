import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '@apollo/react-hooks';
import client from 'apollo/apollo-client-products';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import ProductsSelectionWidget from 'components/CouponCreation/ProductsSelectionWidget';
import * as actions from 'containers/CouponCreation/actions';
import { RootState } from 'store/storeInstance';
import {
  GetProductsQuery as getProductsQuery,
  GetProductsQueryVariables as getProductsQueryVariables,
  GetProductsDocument as getProductsDocument
} from '../../../generated/graphql';

const ProductSelection: React.FC = () => {
  const dispatch = useDispatch();

  const currentBrand = useSelector((state: RootState) => state.authentication.currentBrand);
  const selectedProductsIds = useSelector(
    (state: RootState) => state.couponCreation.selectedProductsIds
  );
  const [products, setProducts] = useState([]);

  const { data, error, loading } = useQuery<getProductsQuery, getProductsQueryVariables>(
    getProductsDocument,
    {
      client,
      fetchPolicy: 'cache-and-network',
      variables: { clientId: currentBrand.id }
    }
  );

  useEffect(() => {
    window.scrollTo(0, 0);

    if (!loading && data && data.listProducts) {
      setProducts(data.listProducts.items);
    }
  }, [data, loading]);

  const mapProducts = () => {
    return products.map(product => {
      let upcName = 'N/A';
      const upcs = product.upc.filter(upc => upc.type === 'GTIN');

      if (upcs.length > 0) {
        upcName = upcs[0].value;
      }

      return {
        id: product.id,
        brand: currentBrand.name,
        name: product.name,
        upc: upcName
      };
    });
  };

  return (
    <div className="products-selection-widget">
      {loading && (
        <Col lg={{ span: 4, offset: 4 }} className="text-center" style={{ marginTop: '200px' }}>
          <Spinner animation="border" className="loading-icon" />
        </Col>
      )}
      {error && (
        <Col lg={{ span: 4, offset: 4 }} className="mt-3">
          <Alert variant="danger">An error occurred. Try again later</Alert>
        </Col>
      )}
      {!loading && data.listProducts && (
        <ProductsSelectionWidget
          products={mapProducts()}
          selectedProductsIds={selectedProductsIds}
          onAllClick={products =>
            dispatch(actions.selectAllProducts(products, selectedProductsIds))
          }
          onProductClick={productId =>
            dispatch(actions.selectProduct(productId, selectedProductsIds))
          }
        />
      )}
    </div>
  );
};

export default ProductSelection;
