import React from 'react';
import { Table, Image, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { RetailerCampaign } from 'utils/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Button } from '@spins/react-ui-library';

import Widget from 'components/Widget';
import Formats from 'utils/formats';

import './styles.scss';

interface Props {
  campaigns: Array<RetailerCampaign>;
  handleRetailersEdit: Function;
  isAdmin: boolean;
}

const InfoTooltip = ({ id, text }) => (
  <OverlayTrigger
    placement="top"
    overlay={
      <Tooltip id={id} style={{ fontSize: '12px' }}>
        {text}
      </Tooltip>
    }
  >
    <FontAwesomeIcon icon={faInfoCircle} />
  </OverlayTrigger>
);

const ViewDetails: React.FC<Props> = ({ campaigns, handleRetailersEdit, isAdmin }) => {
  return (
    <Widget className="view-retailers">
      <Widget.Header>
        <div className="title">{`Retailers (${campaigns.length})`}</div>
        {isAdmin && (
          <Button
            id="view-retailers__edit"
            className="push-right view-retailers__edit"
            onClick={() => handleRetailersEdit()}
          >
            EDIT
          </Button>
        )}
      </Widget.Header>
      <Widget.Body>
        <div className="view-retailers__container">
          <Table className="view-retailers__table">
            <thead>
              <tr>
                <th className="view-retailers__table-header border-right" colSpan={5}>
                  Insertion Order Details
                </th>
                <th className="view-retailers__table-header" colSpan={3}>
                  Performance
                </th>
              </tr>
            </thead>
            <colgroup className="view-retailers__table-colgroup">
              <col />
              <col />
              <col />
              <col />
              <col />
              <col />
              <col />
              <col />
            </colgroup>
            <thead>
              <tr>
                <th>Retailer</th>
                <th>
                  <span>Campaign Duration</span>{' '}
                  <InfoTooltip
                    id="tooltip-duration"
                    text="Duration is the length of time you want this deal to be exposed to consumers at this retailer so they can clip and add this deal to their accounts"
                  />
                </th>
                <th>
                  <span>Extended Period</span>{' '}
                  <InfoTooltip
                    id="tooltip-redemption"
                    text="While consumers will no longer be able to add this deal to their shopping cart, this extension will maximize consumer conversion rate on this campaign"
                  />
                </th>
                <th>
                  <span>Max Clips</span>{' '}
                  <InfoTooltip
                    id="tooltip-max-clips"
                    text="Maximum clips is the total number of maximum number times you will allow this deal to be clipped and added to consumer's digital shopping baskets at this retailer in total"
                  />
                </th>
                <th className="border-right">
                  <span>Clip Budget</span>{' '}
                  <InfoTooltip
                    id="tooltip-max-clip-budget"
                    text="The maximum spend for this retailer based on the number of clips multiplied by the per clip cost"
                  />
                </th>
                <th className="view-retailers__table-clips">
                  <span>Clips</span>{' '}
                  <InfoTooltip id="tooltip-clips" text="Number of times this IO has been clipped" />
                </th>
                <th>
                  <span>Redemptions</span>{' '}
                  <InfoTooltip
                    id="tooltip-redemptions"
                    text="Number of times this IO has been redeemed"
                  />
                </th>
                <th>
                  <span>Redemption Rate</span>{' '}
                  <InfoTooltip id="tooltip-redemption-rate" text="Rate: Redemptions / Clips" />
                </th>
              </tr>
            </thead>
            <tbody>
              {campaigns.map((campaign: RetailerCampaign, key: number) => {
                return (
                  <tr className="view-retailers__row" key={key}>
                    <td>
                      {campaign.retailer.logo !== null && (
                        <>
                          <span className="view-retailers__row-image-container">
                            <Image className="image" src={campaign.retailer.logo} />
                          </span>
                          <span className="view-retailers__row-title">
                            {campaign.retailer.name}
                          </span>
                        </>
                      )}
                      {campaign.retailer.logo === null && (
                        <div className="view-retailers__row-name">
                          <span className="view-retailers__row-title">
                            {campaign.retailer.name}
                          </span>
                        </div>
                      )}
                    </td>
                    <td>{campaign.campaign.duration}</td>
                    <td>
                      {campaign.campaign.extendedDays === 0
                        ? '0'
                        : `${campaign.campaign.extendedDays} Days`}
                    </td>
                    <td>{Formats.formatNumber(campaign.campaign.maxClips)}</td>
                    <td className="border-right">
                      {Formats.currency(campaign.campaign.maxClipsBudget)}
                    </td>
                    <td className="view-retailers__table-clips">
                      {campaign.campaign.clips !== null
                        ? Formats.formatNumber(campaign.campaign.clips)
                        : '-'}
                    </td>
                    <td>
                      {campaign.campaign.redemptions !== null
                        ? Formats.formatNumber(campaign.campaign.redemptions)
                        : '-'}
                    </td>
                    <td>
                      {campaign.campaign.redemptionRate !== null
                        ? `${campaign.campaign.redemptionRate}%`
                        : '-'}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </Widget.Body>
    </Widget>
  );
};

export default ViewDetails;
