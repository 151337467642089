import React from 'react';
import { Button, Image } from 'react-bootstrap';
import { OfferType } from 'utils/types';

import Widget from 'components/Widget';
import CouponsUtils from 'utils/coupons';

import './styles.scss';

interface Coupon {
  title: string;
  description: string;
  campaignImageUrl: string;
  faceValueOffer: number;
  gs1DataBar: string;
  manufacturerOfferId: string;
  maxValueOffer?: number;
  offerType: OfferType;
  purchaseRequirementDescription: string;
  quantity?: number;
  itemsRewardQuantity?: number;
  productsSize: number;
}

interface Props {
  coupon: Coupon;
  handleDetailsEdit: Function;
  handleProductsModal: Function;
  isAdmin: boolean;
}

const ViewDetails: React.FC<Props> = ({
  coupon,
  isAdmin,
  handleDetailsEdit,
  handleProductsModal
}) => {
  return (
    <Widget className="view-details">
      <Widget.Header>
        <div className="title">Offer Details</div>
        {isAdmin && (
          <Button className="push-right view-details__edit" onClick={() => handleDetailsEdit()}>
            EDIT
          </Button>
        )}
      </Widget.Header>
      <Widget.Body>
        <div className="view-details__container-left">
          <div className="view-details__image">
            <Image className="image" src={coupon.campaignImageUrl} fluid />
          </div>
          <div className="view-details__details">
            <p className="view-details__details-title">{coupon.title}</p>
            <p className="view-details__details-description">{coupon.description}</p>
          </div>
        </div>
        <div className="view-details__container-right">
          <div className="view-details__offer">
            <p className="view-details__offer-title">Offer Type</p>
            <p className="view-details__offer-description">
              {CouponsUtils.getOfferType(coupon.offerType)}
            </p>
          </div>
          <div className="view-details__face">
            <p className="view-details__face-title">Face Value</p>
            <p className="view-details__face-description">
              {coupon.offerType === OfferType.PercentDiscount
                ? `${coupon.faceValueOffer}%`
                : `$${coupon.faceValueOffer}`}
            </p>
          </div>
          {coupon.offerType === OfferType.PercentDiscount && (
            <div className="view-details__budget">
              <p className="view-details__budget-title">Max Budget</p>
              <p className="view-details__budget-description">${coupon.maxValueOffer}</p>
            </div>
          )}
          {coupon.offerType === OfferType.PercentDiscount && (
            <div className="view-details__quantity">
              <p className="view-details__quantity-title">Quantity</p>
              <p className="view-details__quantity-description">{coupon.quantity}</p>
            </div>
          )}
          {coupon.offerType === OfferType.Buyxgety && (
            <div className="view-details__buy">
              <p className="view-details__buy-title">BUY X</p>
              <p className="view-details__buy-description">{coupon.quantity}</p>
            </div>
          )}
          {coupon.offerType === OfferType.Buyxgety && (
            <div className="view-details__buy">
              <p className="view-details__buy-title">GET Y</p>
              <p className="view-details__buy-description">{coupon.itemsRewardQuantity}</p>
            </div>
          )}
          <div className="view-details__manufacturer">
            <p className="view-details__manufacturer-title">Manufacturer Offer ID </p>
            <p className="view-details__manufacturer-description">{coupon.manufacturerOfferId}</p>
          </div>
          <div className="view-details__gs1">
            <p className="view-details__gs1-title">Blended GS1 Databar</p>
            <p className="view-details__gs1-description">{coupon.gs1DataBar}</p>
          </div>
          <div className="view-details__products">
            <p className="view-details__products-title">Products</p>
            <p className="view-details__products-description">
              <Button
                className="view-details__products-open"
                variant="link"
                onClick={() => handleProductsModal()}
              >
                {coupon.productsSize}
              </Button>
            </p>
          </div>
        </div>
      </Widget.Body>
    </Widget>
  );
};

export default ViewDetails;
