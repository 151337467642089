import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import './styles.scss';
import Button from 'react-bootstrap/Button';

interface SearchInputIconProps {
  clear: boolean;
  onChange: (string) => void;
}

const SearchInputIcon: React.FC<SearchInputIconProps> = ({ clear, onChange }) => {
  if (clear) {
    return (
      <Button variant="link" className="btn-clear-search" onClick={() => onChange('')}>
        <FontAwesomeIcon className="icon" icon={faTimes} color="#989898" />
      </Button>
    );
  }
  return <FontAwesomeIcon className="icon" icon={faSearch} color="#cfcfcf" />;
};

interface Props {
  placeholder?: string;
  text: string;
  onChange: (string) => void;
}

const SearchInput: React.FC<Props> = ({ placeholder = 'Search...', text, onChange }) => (
  <div className="search-container">
    <input
      className="search"
      type="text"
      placeholder={placeholder}
      value={text}
      onChange={event => onChange(event.target.value)}
    />
    <SearchInputIcon onChange={onChange} clear={text.length > 0} />
  </div>
);

export default SearchInput;
