import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from 'containers/CouponCreation/actions';
import { RetailerType } from 'utils/types';
import { RootState } from 'store/storeInstance';
import { RetailerUpdate } from 'containers/CouponCreation/actions';
import { sortBy } from 'lodash';
import MarketsSelectionModal from 'components/CouponCreation/MarketsSelectionModal';
import RetailersProvider from 'containers/RetailersProvider';
import RetailerSelectionWidget from 'components/CouponCreation/RetailerSelectionWidget';

const RetailerSelection: React.FC = () => {
  const dispatch = useDispatch();

  const [mounted, setMounted] = useState(false);
  const [retailer, setRetailer] = useState(null);
  const [selectedMarketsIds, setSelectedMarketsIds] = useState([]);
  const [showMarketsModal, setShowMarketsModal] = useState(false);
  const selectedRetailers = useSelector(
    (state: RootState) => state.couponCreation.selectedRetailers
  );

  useEffect(() => {
    if (!mounted) {
      window.scrollTo(0, 0);
      setMounted(true);
    }
  });

  const handleRetailerSelection = (retailer: RetailerType) => {
    if (retailer.marketsIds.length === 0) {
      dispatch(actions.selectRetailer(retailer, selectedRetailers));
    } else {
      setRetailer(retailer);
      setSelectedMarketsIds(
        retailer.marketsIds.filter(marketId =>
          selectedRetailers.some(selectedRetailer => selectedRetailer.retailer.id === marketId)
        )
      );
      setShowMarketsModal(true);
    }
  };

  const handleModalCancel = () => {
    setRetailer(null);
    setSelectedMarketsIds([]);
    setShowMarketsModal(false);
  };

  const handleModalOk = (retailersMap: Map<string, RetailerType>) => {
    const retailersToUpdate: Array<RetailerUpdate> = retailer.marketsIds.map(marketId => {
      const market: RetailerType = retailersMap.get(marketId);
      const selected = selectedMarketsIds.some(id => id === marketId);

      return { retailer: market, selected };
    });

    setRetailer(null);
    setSelectedMarketsIds([]);
    setShowMarketsModal(false);
    dispatch(actions.updateRetailers(retailersToUpdate, selectedRetailers));
  };

  const handleModalSelect = (id: string) => {
    let newSelectedMarketsIds;
    if (selectedMarketsIds.some(marketId => marketId === id)) {
      newSelectedMarketsIds = selectedMarketsIds.filter(marketId => marketId !== id);
    } else {
      newSelectedMarketsIds = selectedMarketsIds.concat([id]);
    }

    setSelectedMarketsIds(newSelectedMarketsIds);
  };

  return (
    <RetailersProvider>
      {(retailersMap: Map<string, RetailerType>) => {
        const retailers: Array<RetailerType> = sortBy(
          Array.from(retailersMap.values()).filter(
            (retailer: RetailerType) => retailer.parentRetailerId === null
          ),
          retailer => retailer.name
        );

        return (
          <>
            <RetailerSelectionWidget
              retailers={retailers}
              selectedRetailers={selectedRetailers.map(campaign =>
                campaign.retailer.parentRetailerId !== null
                  ? campaign.retailer.parentRetailerId
                  : campaign.retailer.id
              )}
              onRetailerClick={handleRetailerSelection}
            />
            {retailer !== null && (
              <MarketsSelectionModal
                markets={retailer.marketsIds.map(marketId => {
                  const market = retailersMap.get(marketId);
                  return {
                    id: marketId,
                    name: market.name
                  };
                })}
                retailerName={retailer.name}
                selectedMarketsIds={selectedMarketsIds}
                show={showMarketsModal}
                onCancel={handleModalCancel}
                onOk={() => handleModalOk(retailersMap)}
                onSelect={handleModalSelect}
              />
            )}
          </>
        );
      }}
    </RetailersProvider>
  );
};

export default RetailerSelection;
