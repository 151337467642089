import React from 'react';
import 'components/CouponCreation/Breadcrumb/styles.scss';
import CouponCreationBreadcrumbItem from 'components/CouponCreation/BreadcrumbItem';

interface Props {
  steps: Array<string>;
  stepSelected: number;
}

const CouponCreationBreadcrumb: React.FC<Props> = ({ steps, stepSelected }) => (
  <div className="coupon-creation-breadcrumb">
    {steps.map((step, index) => (
      <React.Fragment key={index}>
        {index > 0 && <div className="separator" />}
        <div className="item">
          <CouponCreationBreadcrumbItem
            done={stepSelected > index + 1}
            label={step}
            number={index + 1}
            selected={stepSelected > index}
          />
        </div>
      </React.Fragment>
    ))}
  </div>
);

export default CouponCreationBreadcrumb;
