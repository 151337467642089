import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { Button } from '@spins/react-ui-library';
import { Alert, Col, Row, Spinner } from 'react-bootstrap';
import { Coupon } from 'utils/types';
import { ROUTES } from 'utils/routes';
import { RootState } from 'store/storeInstance';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';

import Formats from 'utils/formats';
import UsersUtils from 'utils/users';
import PageHeader from 'components/PageHeader';
import StatusBanner from 'components/CouponDetails/StatusBanner';
import ViewDetails from 'containers/CouponDetails/ViewDetails';
import ViewRetailers from 'containers/CouponDetails/ViewRetailers';
import PerformanceSummary from 'containers/CouponDetails/PerformanceSummary';
import RetailersUtils from 'utils/retailers';
import ProductsWidgetModal from 'components/CouponDetails/ProductsWidgetModal';

import {
  ApprovalStatus,
  GetCouponQuery as getCouponQuery,
  GetCouponQueryVariables as getCouponQueryVariables,
  GetCouponDocument as getCouponDocument,
  UpdateApprovalStatusMutation as updateApprovalStatusMutation,
  UpdateApprovalStatusMutationVariables as updateApprovalStatusMutationVariables,
  UpdateApprovalStatusDocument as updateApprovalStatusDocument
} from '../../generated/graphql';

import './styles.scss';

interface CouponDate {
  startDate: string | null;
  expirationDate: string | null;
}

const CouponDetails: React.FC = () => {
  const [coupon, setCoupon] = useState<Coupon>({
    id: null,
    title: null,
    description: null,
    status: null,
    io: null,
    products: [],
    gs1Databar: null,
    campaignImageUrl: null,
    faceValueOffer: 0,
    legal: null,
    manufactererOfferId: null,
    maxValueOffer: 0,
    offerType: null,
    productImageUrl: null,
    purhcaseRequierementDescription: null
  });

  const [openProductsModal, setOpenProductsModal] = useState<boolean>(false);
  const [campaigns, setCampaigns] = useState([]);
  const [couponDate, setCouponDate] = useState<CouponDate>({
    startDate: null,
    expirationDate: null
  });
  const [updateStatusLoading, setUpdateStatusLoading] = useState<boolean>(false);
  const currentBrand = useSelector((state: RootState) => state.authentication.currentBrand);
  const userLevel = useSelector((state: RootState) => state.authentication.userLevel);
  const params = useParams<any>();
  const history = useHistory();

  const handleDetailsEdit = () => {
    history.push(ROUTES.COUPON_EDIT_DETAILS(params.id));
  };

  const handleProductsEdit = () => {
    history.push(ROUTES.COUPON_EDIT_PRODUCTS(params.id));
  };

  const handleRetailersEdit = () => {
    history.push(ROUTES.COUPON_EDIT_RETAILERS(params.id));
  };

  const handleProductsModal = () => {
    setOpenProductsModal(!openProductsModal);
  };

  const [updateApprovalStatus] = useMutation<
    updateApprovalStatusMutation,
    updateApprovalStatusMutationVariables
  >(updateApprovalStatusDocument, {
    fetchPolicy: 'no-cache',
    onCompleted(updateCouponData) {
      setUpdateStatusLoading(false);
      if (updateCouponData.updateApprovalStatus === null) {
        alert('An error occurred while updating the coupon currentStatus, please try again later.');
      } else {
        setCoupon({
          ...coupon,
          status: updateCouponData.updateApprovalStatus.status
        });
      }
    },
    onError(err) {
      if (err) {
        setUpdateStatusLoading(false);
        alert(err.message);
      }
    }
  });

  const handleStatusChange = couponId => {
    setUpdateStatusLoading(true);
    updateApprovalStatus({
      variables: {
        id: couponId,
        approvalStatus: ApprovalStatus.Approved
      }
    });
  };

  const renderApproveButton = (couponId, status) => {
    let button: any;

    if (status !== null) {
      switch (status) {
        case 'pending':
          button = (
            <Button
              id="btn-approve"
              loading={updateStatusLoading}
              onClick={() => handleStatusChange(couponId)}
              className="btn-approve push-right btn btn-primary"
            >
              APPROVE
            </Button>
          );
          break;
        case 'holdForPayment':
          button = (
            <Button
              id="btn-approve"
              loading={updateStatusLoading}
              onClick={() => handleStatusChange(couponId)}
              className="btn-approve push-right btn btn-primary"
            >
              APPROVE
            </Button>
          );
          break;
        default:
          button = (
            <Button
              id="btn-approve"
              className="btn-approve-disabled push-right btn btn-primary"
              onClick={() => console.log('approve')}
              disabled
            >
              APPROVED
            </Button>
          );
          break;
      }
    }

    return button;
  };

  const getCampaigns = campaigns => {
    return campaigns.map(campaign => {
      setCouponDate({
        startDate: campaign.startDate !== null ? Formats.date(campaign.startDate) : 'N/A',
        expirationDate:
          campaign.expirationDate !== null ? Formats.date(campaign.expirationDate) : 'N/A'
      });
      return {
        retailer: RetailersUtils.mapRetailerFromBackend(campaign.retailerDetail),
        campaign: {
          clips: campaign.clips,
          redemptions: campaign.redemptions,
          redemptionRate: campaign.redemptionRate,
          duration: campaign.duration,
          expiration: campaign.expirationDate,
          extendedDays: campaign.extendedDays,
          extension: campaign.extendedDays > 0,
          maxClips: campaign.maxClips,
          maxClipsBudget: campaign.maxClipsBudget,
          startDate: campaign.startDate
        }
      };
    });
  };

  const { data, error, loading } = useQuery<getCouponQuery, getCouponQueryVariables>(
    getCouponDocument,
    {
      fetchPolicy: 'no-cache',
      variables: { couponId: params.id }
    }
  );

  useEffect(() => {
    if (data && data.getCoupon) {
      setCoupon(data.getCoupon as Coupon);
      setCampaigns(getCampaigns(data.getCoupon.campaigns));
    }
  }, [data]);

  const isAdmin = UsersUtils.isAdmin(userLevel as string);

  return (
    <div className="coupon-details">
      {loading && (
        <Col lg={{ span: 4, offset: 4 }} className="text-center" style={{ marginTop: '200px' }}>
          <Spinner animation="border" className="loading-icon" />
        </Col>
      )}
      {error && (
        <Col lg={{ span: 4, offset: 4 }} className="mt-4">
          <Alert variant="danger">An error occurred. Try again later</Alert>
        </Col>
      )}
      {data && data.getCoupon && coupon.id && (
        <React.Fragment>
          <Row>
            <PageHeader>
              <PageHeader.Breadcrumb labels={['Digital Coupons Campaigns', `IO # ${coupon.io}`]} />
              <div className="coupon-details__header-right">
                <span className="coupon-details__header-right-dates">
                  <FontAwesomeIcon className="icon" icon={faCalendar} size="1x" />{' '}
                  {`${couponDate.startDate} - ${couponDate.expirationDate}`}
                </span>
                {isAdmin && renderApproveButton(coupon.id, coupon.status)}
              </div>
            </PageHeader>
          </Row>
          <Row>
            <StatusBanner status={coupon.status} endDate={couponDate.expirationDate} />
          </Row>
          <Row>
            <Col className="mt-4" lg={12}>
              <ViewDetails
                coupon={{
                  title: coupon.title,
                  description: coupon.description,
                  campaignImageUrl: coupon.campaignImageUrl,
                  faceValueOffer: coupon.faceValueOffer,
                  gs1DataBar: coupon.gs1Databar,
                  manufacturerOfferId: coupon.manufactererOfferId,
                  maxValueOffer: coupon.maxValueOffer,
                  offerType: coupon.offerType,
                  purchaseRequirementDescription: coupon.purhcaseRequierementDescription,
                  quantity: coupon.quantity,
                  itemsRewardQuantity: coupon.itemsRewardQuantity,
                  productsSize: coupon.products.length
                }}
                isAdmin={isAdmin}
                handleDetailsEdit={handleDetailsEdit}
                handleProductsModal={handleProductsModal}
              />
            </Col>
          </Row>
          <Row>
            <Col className="mt-4" lg={12}>
              <PerformanceSummary
                redemptionRate={coupon.redemptionRate}
                clipsLeft={coupon.percentageClipsLeft}
                maxClips={coupon.maxClips}
                clipsRemaining={coupon.clipsRemaining}
              />
            </Col>
          </Row>
          <Row>
            <Col className="mt-4" lg={12}>
              <ViewRetailers
                isAdmin={isAdmin}
                campaigns={campaigns}
                handleRetailersEdit={handleRetailersEdit}
              />
            </Col>
          </Row>
          <ProductsWidgetModal
            clientId={currentBrand.id}
            show={openProductsModal}
            isAdmin={isAdmin}
            productsIds={coupon.products}
            handleEdit={handleProductsEdit}
            handleClose={handleProductsModal}
          />
        </React.Fragment>
      )}
    </div>
  );
};

export default CouponDetails;
