import React, { useState } from 'react';
import { Col } from 'react-bootstrap';
import { RetailerType } from 'utils/types';
import RetailerItem from 'components/CouponCreation/RetailerItem';
import { SearchInput } from '@spins/react-ui-library';
import Widget from 'components/Widget';

import './styles.scss';

interface Props {
  retailers: Array<RetailerType>;
  selectedRetailers?: Array<string>;
  onRetailerClick: (RetailerType) => void;
}

const RetailerSelectionWidget: React.FC<Props> = ({
  retailers,
  selectedRetailers,
  onRetailerClick
}) => {
  const [searchText, setSearchText] = useState('');
  const filteredRetailers = retailers.filter(
    retailer =>
      searchText === '' || retailer.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1
  );

  return (
    <div className="retailer-selection-widget">
      <Widget>
        <Widget.Header>
          <Col>
            <div className="title">{`Total Available Retailers (${filteredRetailers.length})`}</div>
          </Col>
          <Col>
            <div className="search">
              <SearchInput onChange={value => setSearchText(value)} />
            </div>
          </Col>
          <Col />
        </Widget.Header>
        <Widget.Body>
          <div className="message">
            Select your retailer, and enter details like clips and time periods on the next step!
          </div>
          <div className="retailer-container">
            {filteredRetailers.map(retailer => {
              const selected = selectedRetailers.some(id => id === retailer.id);
              return (
                <div key={retailer.id}>
                  <RetailerItem
                    retailer={retailer}
                    selected={selected}
                    onChange={() => onRetailerClick(retailer)}
                  />
                </div>
              );
            })}
          </div>
        </Widget.Body>
      </Widget>
    </div>
  );
};

export default RetailerSelectionWidget;
