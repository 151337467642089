import React from 'react';
import './styles.scss';

interface Props {
  children: (
    invalid: boolean,
    onBlur: (string) => void,
    onChange: (string) => void
  ) => React.ReactElement;
  errorMessage?: string;
  id: string;
  invalid?: boolean;
  label: string;
  validator?: (string) => boolean;
  onBlur?(id: string, value: any, validate?: (string) => boolean): void;
  onChange(id: string, value: any): void;
}

const ProfileFormField: React.FC<Props> = ({
  children,
  errorMessage = 'Error',
  invalid = false,
  id,
  label,
  validator = null,
  onBlur,
  onChange
}) => {
  const handleBlur = value => {
    onBlur && onBlur(id, value, validator);
  };
  const handleChange = value => {
    onChange(id, value);
  };
  return (
    <div className="profile-form-field">
      <div className="label">{label}</div>
      {children(invalid, handleBlur, handleChange)}
      {invalid && <div className="error">{errorMessage}</div>}
    </div>
  );
};

export default ProfileFormField;
