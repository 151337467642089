import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import 'components/CouponCreation/BreadcrumbItem/styles.scss';

interface Props {
  done?: boolean;
  label: string;
  number: number;
  selected?: boolean;
}

const CouponCreationBreadcrumbItem: React.FC<Props> = ({
  done = false,
  label,
  number,
  selected = false
}) => (
  <div className="coupon-creation-breadcrumb-item">
    <div className={`number${selected ? ' selected' : ''}`}>
      {done ? <FontAwesomeIcon icon={faCheck} size="xs" /> : number}
    </div>
    <div className="label">{label}</div>
  </div>
);

export default CouponCreationBreadcrumbItem;
