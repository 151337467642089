import React from 'react';
import { validatePhoneNumber } from 'containers/Profile/actions';
import BasicForm from 'containers/Profile/BasicForm';
import Checkbox from 'components/Checkbox';
import ProfileFormField from 'components/Profile/FormField';
import ProfileTextInput from 'components/Profile/TextInput';
import StateSelect from 'components/Profile/StateSelect';
import Validations from 'utils/validations';

import './styles.scss';

const BillingAddressForm: React.FC = () => (
  <BasicForm>
    {({
      invalidFields,
      profile,
      onSameInfoChange,
      onValueChange,
      onValueValidation,
      onValueValidationWithAction
    }) => (
      <div className="address-form billing-address-form">
        <div className="title">Billing Address</div>
        <div className="chk-same-info-container">
          <Checkbox
            label="Use same address as Company Info"
            id="billing.useSameAsCompany"
            checked={
              profile.billing.useSameAsCompany === null ? false : profile.billing.useSameAsCompany
            }
            onChange={id =>
              onSameInfoChange(
                id,
                profile.billing.useSameAsCompany === null ? true : !profile.billing.useSameAsCompany
              )
            }
          />
        </div>
        <div className="field-container">
          <ProfileFormField
            id="billing.companyInfo.name"
            label="company name"
            onChange={(id, value) => onValueChange(id, value)}
          >
            {(invalid, onBlur, onChange) => (
              <ProfileTextInput
                disabled={profile.billing.useSameAsCompany}
                placeholder="Enter Company Name"
                value={profile.billing.companyInfo.name}
                onChange={onChange}
              />
            )}
          </ProfileFormField>
        </div>
        <div className="field-container">
          <ProfileFormField
            id="billing.companyInfo.street1"
            label="street"
            onChange={(id, value) => onValueChange(id, value)}
          >
            {(invalid, onBlur, onChange) => (
              <ProfileTextInput
                disabled={profile.billing.useSameAsCompany}
                placeholder="Enter Street Address"
                value={profile.billing.companyInfo.street1}
                onChange={onChange}
              />
            )}
          </ProfileFormField>
        </div>
        <div className="field-container">
          <ProfileFormField
            id="billing.companyInfo.street2"
            label="street 2"
            onChange={(id, value) => onValueChange(id, value)}
          >
            {(invalid, onBlur, onChange) => (
              <ProfileTextInput
                disabled={profile.billing.useSameAsCompany}
                placeholder="Enter Street Address"
                value={profile.billing.companyInfo.street2}
                onChange={onChange}
              />
            )}
          </ProfileFormField>
        </div>
        <div className="field-container">
          <div className="city-container">
            <ProfileFormField
              id="billing.companyInfo.city"
              label="city"
              onChange={(id, value) => onValueChange(id, value)}
            >
              {(invalid, onBlur, onChange) => (
                <ProfileTextInput
                  disabled={profile.billing.useSameAsCompany}
                  placeholder="Enter City"
                  value={profile.billing.companyInfo.city}
                  onChange={onChange}
                />
              )}
            </ProfileFormField>
          </div>
          <div className="special-field-container">
            <ProfileFormField
              id="billing.companyInfo.state"
              label="state"
              onChange={(id, value) => onValueChange(id, value)}
            >
              {(invalid, onBlur, onChange) => (
                <StateSelect
                  disabled={profile.billing.useSameAsCompany}
                  value={profile.billing.companyInfo.state}
                  onChange={onChange}
                />
              )}
            </ProfileFormField>
          </div>
          <div className="special-field-container">
            <ProfileFormField
              errorMessage="Zip Code invalid"
              id="billing.companyInfo.zipCode"
              invalid={invalidFields.some(attribute => attribute === 'billing.companyInfo.zipCode')}
              label="zipcode"
              validator={Validations.isZipCodeValid}
              onBlur={(id, value, validate) =>
                onValueValidation({ id, value, invalidFields, validate })
              }
              onChange={(id, value) => onValueChange(id, value)}
            >
              {(invalid, onBlur, onChange) => (
                <ProfileTextInput
                  disabled={profile.billing.useSameAsCompany}
                  invalid={invalid}
                  placeholder="Enter Zip Code"
                  value={profile.billing.companyInfo.zipCode}
                  onChange={onChange}
                  onBlur={onBlur}
                />
              )}
            </ProfileFormField>
          </div>
        </div>
        <div className="title" style={{ marginTop: '30px' }}>
          Billing Contact Information
        </div>
        <div className="field-container">
          <ProfileFormField
            id="billing.contactName"
            label="name"
            onChange={(id, value) => onValueChange(id, value)}
          >
            {(invalid, onBlur, onChange) => (
              <ProfileTextInput
                placeholder="Enter Billing Contact Name"
                value={profile.billing.contactName}
                onChange={onChange}
              />
            )}
          </ProfileFormField>
        </div>
        <div className="field-container">
          <ProfileFormField
            errorMessage="Email invalid"
            id="billing.contactEmail"
            invalid={invalidFields.some(attribute => attribute === 'billing.contactEmail')}
            label="email"
            validator={Validations.isEmailValid}
            onBlur={(id, value, validate) =>
              onValueValidation({ id, value, invalidFields, validate })
            }
            onChange={(id, value) => onValueChange(id, value)}
          >
            {(invalid, onBlur, onChange) => (
              <ProfileTextInput
                invalid={invalid}
                placeholder="Enter Billing Email"
                value={profile.billing.contactEmail}
                onChange={onChange}
                onBlur={onBlur}
              />
            )}
          </ProfileFormField>
        </div>
        <div className="field-container">
          <ProfileFormField
            errorMessage="Please enter a valid 10 digit phone number"
            id="billing.contactPhoneNumber"
            invalid={invalidFields.some(attribute => attribute === 'billing.contactPhoneNumber')}
            label="phone number"
            validator={Validations.isPhoneNumberValid}
            onBlur={(id, value) => {
              onValueValidationWithAction(validatePhoneNumber(id, value, invalidFields));
            }}
            onChange={(id, value) => onValueChange(id, value)}
          >
            {(invalid, onBlur, onChange) => (
              <ProfileTextInput
                invalid={invalid}
                mask="(999) 999-9999"
                placeholder="Enter Billing Phone Number"
                value={profile.billing.contactPhoneNumber}
                onChange={onChange}
                onBlur={onBlur}
              />
            )}
          </ProfileFormField>
        </div>
      </div>
    )}
  </BasicForm>
);

export default BillingAddressForm;
