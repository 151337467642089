import { RetailerType } from 'utils/types';

class RetailersUtils {
  static mapRetailerFromBackend(backendRetailer: any): RetailerType {
    return {
      id: backendRetailer.id,
      parentRetailerId: backendRetailer.parentRetailer,
      name: backendRetailer.name,
      clipFee: backendRetailer.clipFee !== null ? backendRetailer.clipFee : 1,
      leadTimeInDays: backendRetailer.leadTime ? backendRetailer.leadTime : 0,
      minClips: backendRetailer.minimumClip !== null ? backendRetailer.minimumClip : 1,
      setUpFee: backendRetailer.setUpFee !== null ? backendRetailer.setUpFee : null,
      storesCount: backendRetailer.storeCount,
      marketsIds: backendRetailer.markets.map(market => market.id),
      logo: backendRetailer.logo
    };
  }

  static mapRetailersFromBackend(backendRetailers: Array<any>): Map<string, RetailerType> {
    const result = new Map();
    backendRetailers.forEach(backendRetailer => {
      result.set(backendRetailer.id, RetailersUtils.mapRetailerFromBackend(backendRetailer));
    });
    return result;
  }
}

export default RetailersUtils;
