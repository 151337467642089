export const CAMPAIGN_DETAILS_ADD = 'CouponCreation/campaign-campaigns-add';
export const CAMPAIGN_DETAILS_REMOVE = 'CouponCreation/campaign-campaigns-remove';
export const CAMPAIGN_DETAILS_VALUE_CHANGE = 'CouponCreation/campaign-campaigns-value-change';
export const FORM_SET = 'CouponCreation/form-set';
export const NEW_COUPON_CONFIRMATION_SHOW_SET = 'CouponCreation/new-coupon-confirmation-show-set';
export const NEW_COUPON_CONFIRMATION_SHOW = 'CouponCreation/new-coupon-confirmation-show';
export const NEW_COUPON_START = 'CouponCreation/new-coupon-start';
export const OFFER_FORM_VALUE_CHANGE = 'CouponCreation/offer-form-value-change';
export const OFFER_TYPE_CHANGE = 'CouponCreation/offer-type-change';
export const PRODUCTS_SELECT = 'CouponCreation/products-select';
export const RETAILER_SET = 'CouponCreation/campaign-set';
export const STEP_GO_TO = 'CouponCreation/step-go-to';
export const TERMS_AND_CONDITIONS_CHANGE = 'CouponCreation/terms-and-conditions-change';
export const IS_CREATING_CHANGE = 'CouponCreation/is-creating-change';
