import React, { createRef, useEffect } from 'react';
import 'components/CouponCreation/OfferInput/styles.scss';

interface Props {
  disabled?: boolean;
  placeholder?: string;
  value: string;
  onChange: any;
}

const OfferTextarea: React.FC<Props> = ({
  disabled = false,
  placeholder,
  value,
  onChange,
  ...props
}) => {
  const ref = createRef<HTMLTextAreaElement>();

  const setHeight = () => {
    // Reset field height
    ref.current.style.height = 'inherit';

    // Get the computed styles for the element
    const computed = window.getComputedStyle(ref.current);

    // Calculate the height
    const height =
      parseInt(computed.getPropertyValue('border-top-width'), 10) +
      parseInt(computed.getPropertyValue('padding-top'), 10) +
      ref.current.scrollHeight +
      parseInt(computed.getPropertyValue('padding-bottom'), 10) +
      parseInt(computed.getPropertyValue('border-bottom-width'), 10);

    ref.current.style.height = height + 'px';
  };

  const handleChange = (event, onChange) => {
    onChange(event.target.value);
    setHeight();
  };

  useEffect(() => setHeight());

  return (
    <div className="offer-input">
      <textarea
        {...props}
        rows={1}
        disabled={disabled}
        placeholder={placeholder}
        value={value}
        onChange={event => handleChange(event, onChange)}
        ref={ref}
      />
    </div>
  );
};

export default OfferTextarea;
