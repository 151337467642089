import React from 'react';
import './styles.scss';
import Formats from 'utils/formats';
import { OfferTypeItem } from 'utils/types';

interface ItemProps {
  label: string;
  value: string;
}

const Item: React.FC<ItemProps> = ({ label, value }) => (
  <div className="item">
    <div className="value">{value}</div>
    <div className="label">{label}</div>
  </div>
);

interface Props {
  offerType?: OfferTypeItem;
  offerValue?: number;
  productsCount?: number;
}

const CampaignSummary: React.FC<Props> = ({
  productsCount = 0,
  offerType = null,
  offerValue = 0
}) => {
  let offerValueText = '-';

  if (offerValue > 0) {
    if (offerType === null || offerType !== 'percentage') {
      offerValueText = Formats.currency(offerValue, 2);
    } else {
      offerValueText = Formats.percentage(offerValue, 0, false);
    }
  }

  return (
    <div className="campaign-summary">
      <Item label="products" value={productsCount > 0 ? Formats.number(productsCount) : '-'} />
      <Item label="offer value" value={offerValueText} />
    </div>
  );
};

export default CampaignSummary;
