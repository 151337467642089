import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sortBy } from 'lodash';
import { Col, Row } from 'react-bootstrap';
import { RetailerCampaigns, RetailerType, UserLevelType } from 'utils/types';
import { RootState } from 'store/storeInstance';
import * as creationActions from 'containers/CouponCreation/actions';
import CouponEdit from 'containers/CouponEdit/index';
import CouponsUtils from 'utils/coupons';
import CouponEditPageHeader from 'components/CouponEdit/PageHeader';
import CampaignSummary from 'components/CouponCreation/CampaignSummary';
import RetailerDetailsWidget from 'components/RetailerDetailsWidget';
import UsersUtils from 'utils/users';
import Widget from 'components/Widget';

const CouponEditRetailers: React.FC = () => {
  const dispatch = useDispatch();

  const offerForm = useSelector((state: RootState) => state.couponCreation.offerForm);
  const saveAvailable = useSelector((state: RootState) => state.couponEdit.saveAvailable);
  const selectedProductsIds = useSelector(
    (state: RootState) => state.couponCreation.selectedProductsIds
  );
  const selectedRetailers = useSelector(
    (state: RootState) => state.couponCreation.selectedRetailers
  );
  const userLevel = useSelector((state: RootState) => state.authentication.userLevel);

  const onRetailerRemove = (
    retailer: RetailerType,
    selectedRetailers: Array<RetailerCampaigns>
  ) => {
    dispatch(creationActions.selectRetailer(retailer, selectedRetailers));
  };

  const onValueChange = (retailerId: string, index: number, attribute, value) => {
    dispatch(creationActions.changeCampaignDetailsValue(retailerId, index, attribute, value));
  };

  const handleSave = async (handleCouponUpdate, coupon) => {
    handleCouponUpdate({
      id: coupon.id,
      campaigns: CouponsUtils.mapRetailersCampaignToBackend(selectedRetailers)
    });
  };

  return (
    <CouponEdit>
      {(coupon, handleCouponUpdate, handleCouponCancel, updating) => {
        if (coupon !== null) {
          return (
            <React.Fragment>
              <Row>
                <CouponEditPageHeader
                  coupon={coupon}
                  section="Retailers"
                  saveDisabled={!saveAvailable}
                  saving={updating}
                  onCancel={handleCouponCancel}
                  onSave={() => handleSave(handleCouponUpdate, coupon)}
                />
              </Row>
              <Row>
                <Col className="mt-4" lg={8}>
                  {sortBy(selectedRetailers, campaign => campaign.retailer.name).map(
                    (campaign, index) => (
                      <div key={campaign.retailer.id} className={index > 0 ? 'mt-4' : null}>
                        <RetailerDetailsWidget
                          currentUserIsAdmin={UsersUtils.isAdmin(userLevel)}
                          key={campaign.retailer.id}
                          retailerCampaigns={campaign}
                          onRetailerRemove={
                            selectedRetailers.length > 1
                              ? retailer => onRetailerRemove(retailer, selectedRetailers)
                              : null
                          }
                          onValueChange={(index, attribute, value) =>
                            onValueChange(campaign.retailer.id, index, attribute, value)
                          }
                        />
                      </div>
                    )
                  )}
                </Col>
                <Col className="mt-4" lg={4}>
                  <Row>
                    <Col>
                      <Widget>
                        <Widget.Header>
                          <div className="title">Digital Coupon Campaign Summary</div>
                        </Widget.Header>
                        <Widget.Body>
                          <CampaignSummary
                            offerType={offerForm.type}
                            offerValue={offerForm.faceValue}
                            productsCount={selectedProductsIds.length}
                          />
                        </Widget.Body>
                      </Widget>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </React.Fragment>
          );
        }
        return null;
      }}
    </CouponEdit>
  );
};

export default CouponEditRetailers;
