import React from 'react';
import { Container } from 'react-bootstrap';

import './styles.scss';

const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <Container fluid>
        <div className="footer__text">
          <span>&copy; 2021 Destini LLC. All rights reserved.</span>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
