import React from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { RootState } from 'store/storeInstance';

import CampaignSummary from 'components/CouponCreation/CampaignSummary';
import CouponEdit from 'containers/CouponEdit/index';
import CouponEditPageHeader from 'components/CouponEdit/PageHeader';
import ProductSelection from 'containers/CouponCreation/ProductSelection';
import Widget from 'components/Widget';

const CouponEditProducts: React.FC = () => {
  const selectedProductsIds = useSelector(
    (state: RootState) => state.couponCreation.selectedProductsIds
  );

  const handleSave = async (handleCouponUpdate, coupon) => {
    handleCouponUpdate({
      id: coupon.id,
      products: selectedProductsIds
    });
  };

  return (
    <CouponEdit>
      {(coupon, handleCouponUpdate, handleCouponCancel, updating) => {
        if (coupon !== null) {
          return (
            <React.Fragment>
              <Row>
                <CouponEditPageHeader
                  coupon={coupon}
                  section="Products"
                  saveDisabled={selectedProductsIds.length === 0}
                  saving={updating}
                  onCancel={handleCouponCancel}
                  onSave={() => handleSave(handleCouponUpdate, coupon)}
                />
              </Row>
              <Row>
                <Col className="mt-4" lg={8}>
                  <ProductSelection />
                </Col>
                <Col className="mt-4" lg={4}>
                  <Row>
                    <Col>
                      <Widget>
                        <Widget.Header>
                          <div className="title">Digital Coupon Campaign Summary</div>
                        </Widget.Header>
                        <Widget.Body>
                          <CampaignSummary
                            offerType={coupon.type}
                            offerValue={coupon.faceValue}
                            productsCount={coupon.productsIds.length}
                          />
                        </Widget.Body>
                      </Widget>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </React.Fragment>
          );
        }
        return null;
      }}
    </CouponEdit>
  );
};

export default CouponEditProducts;
