import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import './styles.scss';

interface Props {
  onClose: any;
}

const NewProfileConfirmationModal: React.FC<Props> = ({ onClose }) => {
  const [clocks, setClocks] = useState(3);
  useEffect(() => {
    const timer = setInterval(() => {
      setClocks(clocks - 1);
      if (clocks - 1 === 0) {
        onClose();
      }
    }, 1000);
    return () => clearInterval(timer);
  });

  return (
    <Modal
      backdrop="static"
      centered
      dialogClassName="new-profile-confirmation-modal"
      keyboard={false}
      show
    >
      <Modal.Body>
        <div className="ncpm-content">
          <FontAwesomeIcon color="#95CE38" icon={faCheckCircle} size="9x" />
          <div className="title">
            <div>
              <strong>Awesome! Your company profile is set up</strong>
            </div>
          </div>
          <div className="message">
            Your order will be processed in the next 48 business hours. If there are questions about
            your submission a coupon expert will contact you.
          </div>
          <div className="message">{`Redirecting to coupon builder in ${clocks}...`}</div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default NewProfileConfirmationModal;
