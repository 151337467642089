import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './styles.scss';

interface Props {
  message: string;
  show: boolean;
  onCancel(): void;
  onOk(): void;
}

const ConfirmationModal: React.FC<Props> = ({ message, show, onCancel, onOk }) => (
  <Modal centered dialogClassName="confirmation-modal" size="sm" show={show} onHide={onCancel}>
    <Modal.Body>{message}</Modal.Body>
    <Modal.Footer>
      <Button className="btn-ok" onClick={onOk}>
        Ok
      </Button>
      <Button className="btn-cancel" onClick={onCancel}>
        Cancel
      </Button>
    </Modal.Footer>
  </Modal>
);

export default ConfirmationModal;
