import React from 'react';
import { Route, RouteProps } from 'react-router-dom';

import Layout from 'components/Layout';

interface PrivateRouteProps {
  component: React.ReactNode;
  footer?: boolean;
}

const PrivateRoute: React.FC<PrivateRouteProps & RouteProps> = ({
  component: Component,
  footer,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={props => (
        <Layout footer={footer}>
          <Component {...props} />
        </Layout>
      )}
    />
  );
};

export default PrivateRoute;
