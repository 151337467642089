import { takeEvery, call, put } from 'redux-saga/effects';
import { PHONE_NUMBER_VALIDATE } from 'containers/Profile/action-types';
import { setInvalidFields, setValidatingProfile } from 'containers/Profile/actions';
import { ValidatePhoneNumberDocument as validatePhoneNumberDocument } from 'generated/graphql';
import client from 'apollo/apollo-client-coupons';

function* validatePhoneNumberSaga({ payload: { id, invalidFields, value } }) {
  yield put(setValidatingProfile(true));
  try {
    const { data } = yield call(client.query, {
      query: validatePhoneNumberDocument,
      variables: { phoneNumber: value },
      fetchPolicy: 'no-cache'
    });
    yield put(setValidatingProfile(false));
    if (data === null) {
      yield put(setInvalidFields(invalidFields.concat([id])));
    } else {
      yield put(setInvalidFields(invalidFields.filter(attribute => id !== attribute)));
    }
  } catch {
    yield put(setValidatingProfile(false));
    yield put(setInvalidFields(invalidFields.concat([id])));
  }
}

export default function* couponCreationSagas() {
  yield takeEvery(PHONE_NUMBER_VALIDATE as any, validatePhoneNumberSaga);
}
