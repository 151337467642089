import * as actionTypes from 'containers/Authentication/actionTypes';
import { BrandType, UserInfoType, UserLevelType, HamburgerMenuType } from 'utils/types';

type StateType = {
  readonly currentBrand: BrandType;
  readonly isLoggedIn: boolean;
  readonly userInfo: UserInfoType;
  readonly userLevel: UserLevelType;
  readonly hamburgerMenuItems: Array<HamburgerMenuType>;
};

export const initialState: StateType = {
  currentBrand: null,
  isLoggedIn: false,
  userInfo: null,
  userLevel: null,
  hamburgerMenuItems: []
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.CURRENT_BRAND_SET:
      return { ...state, currentBrand: payload };
    case actionTypes.IS_LOGGED_IN_SET:
      return { ...state, isLoggedIn: payload };
    case actionTypes.USER_INFO_SET:
      return { ...state, userInfo: payload };
    case actionTypes.USER_LEVEL_SET:
      return { ...state, userLevel: payload };
    case actionTypes.HAMBURGER_MENU_ITEMS_SET:
      return { ...state, hamburgerMenuItems: payload };
    default:
      return state;
  }
};
