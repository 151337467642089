class Validations {
  static isEmailValid(email: string) {
    const emailRegularExpression = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    return emailRegularExpression.test(email);
  }

  static isPhoneNumberValid(phoneNumber: string) {
    const rg = /^(\(\d{3}\)) (\d{3})-(\d{4})$/;
    return rg.test(phoneNumber);
  }

  static isZipCodeValid(zipCode: string) {
    const rg = /^[0-9]{5}$/;
    return rg.test(zipCode);
  }
}

export default Validations;
