import React, { useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

interface Props extends RouteComponentProps {
  children: React.ReactElement;
}

const ScrollToTop: React.FC<Props> = ({ children, location }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};

export default withRouter(ScrollToTop);
