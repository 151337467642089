import React from 'react';
import { Button } from '@spins/react-ui-library';
import { CouponType } from 'utils/types';
import PageHeader from 'components/PageHeader';

import './styles.scss';

interface Props {
  coupon: CouponType;
  saveDisabled?: boolean;
  saving?: boolean;
  section: string;
  onCancel: any;
  onSave: any;
}

const CouponEditPageHeader: React.FC<Props> = ({
  coupon,
  saveDisabled = false,
  saving = false,
  section,
  onCancel,
  onSave
}) => (
  <div className="coupon-edit-page-header">
    <PageHeader>
      <PageHeader.Breadcrumb labels={['Edit Coupon', `${coupon.title}`, section]} />
      <div className="coupon-edit-page-header__button-container push-right">
        <Button id="btn-cancel" className="btn-cancel" onClick={() => onCancel()}>
          CANCEL
        </Button>
        <Button
          id="btn-save"
          className="btn-save"
          loading={saving}
          disabled={saveDisabled || saving}
          onClick={() => onSave()}
        >
          SAVE CHANGES
        </Button>
      </div>
    </PageHeader>
  </div>
);

export default CouponEditPageHeader;
