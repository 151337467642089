import React from 'react';
import { Button } from '@spins/react-ui-library';

import './styles.scss';

type ButtonType = {
  disabled: boolean;
  show: boolean;
  onClick: any;
};

interface Props {
  continueButton?: ButtonType;
  previousButton?: ButtonType;
  submitButton?: React.ReactElement;
  onCancel: any;
}

const CouponCreationButtonsBar: React.FC<Props> = ({
  continueButton = null,
  previousButton = null,
  submitButton = null,
  onCancel
}) => (
  <div className="coupon-creation-buttons-bar">
    <Button id="btn-cancel" className="btn-cancel" onClick={onCancel}>
      Cancel
    </Button>
    <div className="left-buttons">
      {previousButton !== null && (
        <Button
          id="btn-previous"
          className="btn-previous"
          disabled={previousButton.disabled}
          onClick={previousButton.onClick}
        >
          Previous
        </Button>
      )}
      {continueButton !== null && (
        <Button
          id="btn-continue"
          className="btn-continue"
          disabled={continueButton.disabled}
          onClick={continueButton.onClick}
        >
          Continue to Next Step
        </Button>
      )}
      {submitButton !== null && React.cloneElement(submitButton)}
    </div>
  </div>
);

export default CouponCreationButtonsBar;
