import React from 'react';
import { RetailerType } from 'utils/types';
import './styles.scss';
import Checkbox from 'components/Checkbox';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Image from 'react-bootstrap/Image';

const InfoTooltip = ({ id, text }) => (
  <OverlayTrigger
    placement="top"
    overlay={
      <Tooltip id={id} style={{ fontSize: '12px' }}>
        {text}
      </Tooltip>
    }
  >
    <div className="tooltip-overlay" />
  </OverlayTrigger>
);

interface Props {
  retailer: RetailerType;
  selected?: boolean;
  onChange: any;
}

const RetailerItem: React.FC<Props> = ({ retailer, selected = false, onChange }) => {
  const handleName = name => {
    if (name.length < 16) {
      return name;
    } else {
      return `${name.substring(0, 13)}...`;
    }
  };

  return (
    <div className="retailer-item">
      {retailer.logo ? (
        retailer.logo.includes('Retailer_Icon_Reversed') ? (
          <div className="logo-name-container">
            <Image src={retailer.logo} alt="logo" fluid />
            <div>{handleName(retailer.name)}</div>
          </div>
        ) : (
          <div className="logo-container">
            <Image src={retailer.logo} alt="logo" fluid />
          </div>
        )
      ) : (
        <div className="name-container">
          <div>{retailer.name}</div>
        </div>
      )}
      <div className="checkbox-container">
        <Checkbox checked={selected} id={retailer.id} onChange={onChange} />
      </div>
      <InfoTooltip id={retailer.id} text={retailer.name.toUpperCase()} />
    </div>
  );
};

export default RetailerItem;
