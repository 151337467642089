import React from 'react';
import { Container, Row } from 'react-bootstrap';

import Footer from 'components/Layout/Footer';
import MainHeader from 'containers/MainHeader';
import MainNavigation from 'components/MainNavigation';
import WelcomeModalContainer from 'containers/WelcomeModal';
import Profile from 'containers/Profile';

import './styles.scss';

interface Props {
  children: React.ReactNode;
  footer?: boolean;
}

const Layout: React.FC<Props> = ({ children, footer }) => {
  return (
    <Container fluid>
      <Row>
        <MainHeader />
      </Row>
      <Row>
        <MainNavigation />
      </Row>
      <div className="app">{children}</div>
      {footer && (
        <Row>
          <Footer />
        </Row>
      )}
      {/*
        fix: disable welcome modal
          <WelcomeModalContainer />
      */}
      <Profile />
    </Container>
  );
};

export default Layout;
