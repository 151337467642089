import React from 'react';
import { Table } from 'react-bootstrap';
import { CouponRow, IoRow, CouponStatusType } from 'utils/types';
import Formats from 'utils/formats';
import CouponStatus, { EditableType } from 'components/Coupons/Status';
import TableHeader from 'components/Table/Header';

import './styles.scss';

interface CouponTableRow extends CouponRow {
  statusOptions?: Array<CouponStatusType>;
  onStatusChange?(id: string, status: CouponStatusType): void;
}

interface Props {
  row: CouponRow;
  onRowClick?: (id: string) => void;
}

const TableDetails: React.FC<Props> = ({ row, onRowClick }) => {
  return (
    <Table className="table-details" hover>
      <colgroup className="table-details__colgroup">
        <col />
        <col />
        <col />
        <col />
        <col />
        <col />
        <col />
        <col />
        <col />
      </colgroup>
      <thead className="table-details__thead">
        <tr>
          <TableHeader id="io" title="IO" />
          <TableHeader id="retailer" title="Retailer" />
          <TableHeader id="date" title="Date" />
          <TableHeader id="dateCreated" title="Date Created" />
          <TableHeader id="status" title="Status" />
          <TableHeader id="maxClips" title="Max Clips" />
          <TableHeader id="clips" title="Clips" />
          <TableHeader id="dateCreated" title="Redemptions" />
          <TableHeader id="redemptionRate" title="Redemption Rate" />
        </tr>
      </thead>
      <tbody>
        {row.ios.map((io: IoRow, key: number) => {
          let editable: EditableType = null;

          if (io.statusOptions && io.onStatusChange) {
            editable = {
              statusOptions: io.statusOptions,
              onSelect: status => io.onStatusChange(io.id, status)
            };
          }

          return (
            <tr className="table-details__row" onClick={() => onRowClick(io.id)} key={key}>
              <td>{io.io}</td>
              <td>{io.retailers.map(it => it.name).join(', ')}</td>
              <td>
                {io.startDate !== null ? Formats.date(io.startDate) : '-'}-
                {io.expirationDate !== null ? Formats.date(io.expirationDate) : '-'}
              </td>
              <td>{row.createdDate !== null ? Formats.date(row.createdDate) : '-'}</td>
              <td>
                <CouponStatus currentStatus={io.status} editable={editable} />
              </td>
              <td>{io.maxClips !== null ? Formats.formatNumber(io.maxClips) : '-'}</td>
              <td>{io.clips !== null ? Formats.formatNumber(io.clips) : '-'}</td>
              <td>{io.redemptions !== null ? Formats.formatNumber(io.redemptions) : '-'}</td>
              <td>{io.redemptionRate !== null ? `${io.redemptionRate}%` : '-'}</td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default TableDetails;
