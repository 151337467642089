import React, { useEffect, useState } from 'react';
import * as actions from 'containers/CouponCreation/actions';
import { useDispatch, useSelector } from 'react-redux';
import { RetailerType } from 'utils/types';
import { RootState } from 'store/storeInstance';
import { sortBy } from 'lodash';
import NewCouponConfirmationModal from 'components/CouponCreation/ConfirmationModal';
import RetailersProvider from 'containers/RetailersProvider';
import RetailerDetailsWidget from 'components/RetailerDetailsWidget';
import UsersUtils from 'utils/users';

const BuildOfferDetails: React.FC = () => {
  const dispatch = useDispatch();

  const [mounted, setMounted] = useState(false);
  const offerForm = useSelector((state: RootState) => state.couponCreation.offerForm);
  const selectedRetailers = useSelector(
    (state: RootState) => state.couponCreation.selectedRetailers
  );
  const showConfirmationModal = useSelector(
    (state: RootState) => state.couponCreation.showConfirmationModal
  );
  const userLevel = useSelector((state: RootState) => state.authentication.userLevel);

  useEffect(() => {
    if (!mounted) {
      window.scrollTo(0, 0);
      setMounted(true);
    }
  });

  const onCampaignAdd = (retailer: RetailerType) => {
    dispatch(actions.addCampaignDetails(retailer));
  };

  const onCampaignRemove = (retailerId: string, index: number) => {
    dispatch(actions.removeCampaignDetails(retailerId, index));
  };

  const onValueChange = (retailerId: string, index: number, attribute, value) => {
    dispatch(actions.changeCampaignDetailsValue(retailerId, index, attribute, value));
  };

  return (
    <RetailersProvider>
      {(retailersMap: Map<string, RetailerType>) => {
        return (
          <>
            {sortBy(selectedRetailers, retailerCampaigns => retailerCampaigns.retailer.name).map(
              (retailerCampaigns, index) => (
                <div key={retailerCampaigns.retailer.id} className={index > 0 ? 'mt-4' : null}>
                  <RetailerDetailsWidget
                    currentUserIsAdmin={UsersUtils.isAdmin(userLevel)}
                    retailerCampaigns={retailerCampaigns}
                    parentRetailerName={
                      retailerCampaigns.retailer.parentRetailerId !== null
                        ? retailersMap.get(retailerCampaigns.retailer.parentRetailerId).name
                        : null
                    }
                    onCampaignAdd={onCampaignAdd}
                    onCampaignRemove={onCampaignRemove}
                    onValueChange={(index, attribute, value) =>
                      onValueChange(retailerCampaigns.retailer.id, index, attribute, value)
                    }
                  />
                </div>
              )
            )}
            <NewCouponConfirmationModal
              show={showConfirmationModal}
              title={offerForm.description}
            />
          </>
        );
      }}
    </RetailersProvider>
  );
};

export default BuildOfferDetails;
