import React from 'react';
import Table from 'react-bootstrap/Table';
import Carbon8Filter from 'components/Carbon8Filter';
import TableHeader from 'components/Table/Header';
import { CouponRow, CouponStatusType } from 'utils/types';
import { EditableType } from 'components/Coupons/Status';
import TableRow from 'components/Coupons/Table/Row';
import './styles.scss';

interface CouponTableRow extends CouponRow {
  statusOptions?: Array<CouponStatusType>;
  onStatusChange?(id: string, status: CouponStatusType): void;
}

interface Props {
  currentSortHeader: string;
  orderIds: Array<string>;
  rows: Array<CouponRow>;
  selectedOrdersIds: Array<string>;
  sortingAsc: boolean;
  onOrderIdSelect(id: string): void;
  onClearAllSelectedOptions(): void;
  onRowClick: (string) => void;
  onSort(attribute: string, ascending: boolean): void;
}

const CouponsTable: React.FC<Props> = ({
  currentSortHeader,
  orderIds,
  rows,
  selectedOrdersIds,
  sortingAsc,
  onOrderIdSelect,
  onClearAllSelectedOptions,
  onRowClick,
  onSort
}) => {
  const handleOnSort = (key, ascending) => {
    onSort(key, ascending);
  };

  return (
    <Table className="coupons-table">
      <colgroup className="coupons-table-colgroup">
        <col />
        <col />
        <col />
        <col />
        <col />
        <col />
        <col />
        <col />
        <col />
        <col />
      </colgroup>
      <thead>
        <tr>
          <TableHeader
            ascending={currentSortHeader === 'orderId' ? sortingAsc : null}
            className="border-right"
            id="orderId"
            title="Offer ID"
            onSort={handleOnSort}
          >
            <div className="carbon8-container">
              <Carbon8Filter
                options={orderIds.map(orderId => ({ id: orderId, label: orderId }))}
                selectedOptionsIds={selectedOrdersIds}
                title=""
                onSelect={onOrderIdSelect}
                onClearAllSelectedOptions={onClearAllSelectedOptions}
              />
            </div>
          </TableHeader>
          <th>Image</th>
          <TableHeader
            ascending={currentSortHeader === 'title' ? sortingAsc : null}
            id="title"
            title="Title"
            onSort={handleOnSort}
          />
          <TableHeader
            ascending={currentSortHeader === 'createdDate' ? sortingAsc : null}
            id="createdDate"
            title="Date Created"
            onSort={handleOnSort}
          />
          <TableHeader
            ascending={currentSortHeader === 'numberOfIos' ? sortingAsc : null}
            id="numberOfIos"
            title="# of IOs"
            onSort={handleOnSort}
          />
          <TableHeader
            ascending={currentSortHeader === 'maxClips' ? sortingAsc : null}
            id="maxClips"
            title="Max Clips"
            onSort={handleOnSort}
          />
          <TableHeader
            ascending={currentSortHeader === 'clips' ? sortingAsc : null}
            id="clips"
            title="Clips"
            onSort={handleOnSort}
          />
          <TableHeader
            ascending={currentSortHeader === 'redemptions' ? sortingAsc : null}
            id="redemptions"
            title="Redemptions"
            onSort={handleOnSort}
          />
          <TableHeader
            ascending={currentSortHeader === 'redemptionRate' ? sortingAsc : null}
            id="redemptionRate"
            title="Redemption Rate"
            onSort={handleOnSort}
          />
          <TableHeader id="show" title="" />
        </tr>
      </thead>
      <tbody className="coupons-table-tbody">
        {rows.map((row: CouponRow, key: number) => {
          return <TableRow row={row} key={key} onRowClick={onRowClick} />;
        })}
      </tbody>
    </Table>
  );
};

export default CouponsTable;
