import React from 'react';
import moment from 'moment';
import { CampaignDetails, RetailerCampaigns } from 'utils/types';
import DatePicker from 'components/DatePicker';
import Dropdown from 'components/Dropdown';
import Formats from 'utils/formats';
import Checkbox from 'components/Checkbox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import NumberFormat from 'react-number-format';
import './styles.scss';

const AVAILABLE_DAYS = ['30', '60', '90'];

const DatesOverlapTooltip = ({ id, text }) => (
  <OverlayTrigger
    placement="right"
    overlay={
      <Tooltip id={id} style={{ fontSize: '12px' }}>
        {text}
      </Tooltip>
    }
  >
    <div className="tooltip-dates-overlap"> </div>
  </OverlayTrigger>
);

const isDisabled = (retailerCampaigns, index, days) => {
  const { campaigns } = retailerCampaigns;
  const nextCampaign = campaigns[index + 1];
  if (nextCampaign !== undefined && nextCampaign.startDate !== null) {
    const currentCampaign = campaigns[index];
    return moment(currentCampaign.startDate)
      .add(days, 'day')
      .isAfter(moment(nextCampaign.startDate));
  }
  return false;
};

const isExtendable = (retailerCampaigns, index) => {
  const { campaigns } = retailerCampaigns;
  const nextCampaign = campaigns[index + 1];
  if (nextCampaign !== undefined && nextCampaign.startDate !== null) {
    const currentCampaign = campaigns[index];
    return moment(currentCampaign.startDate)
      .add(60, 'day')
      .isAfter(moment(nextCampaign.startDate));
  }
  return false;
};

interface Props {
  campaign: CampaignDetails;
  currentUserIsAdmin: boolean;
  index: number;
  readOnly?: boolean;
  retailerCampaigns: RetailerCampaigns;
  startDate: string;
  onCampaignRemove?(retailerId: string, index: number): void;
  onValueChange?(index: number, attribute: string, value: any): any;
}

const RetailerDetailsRow: React.FC<Props> = ({
  campaign,
  currentUserIsAdmin,
  index,
  readOnly,
  retailerCampaigns,
  startDate,
  onCampaignRemove,
  onValueChange
}) => {
  return (
    <div className="retailer-campaign-details-row details-data">
      <div>
        {readOnly ? (
          <span>{Formats.date(campaign.startDate, 'MM/DD/YY')}</span>
        ) : (
          <DatePicker
            data-testid={`date-picker-${retailerCampaigns.retailer.id}-${index}`}
            minDate={startDate ? moment(startDate).format() : null}
            value={campaign.startDate ? campaign.startDate : null}
            onChange={value => onValueChange(index, 'startDate', value)}
          />
        )}
      </div>
      <div>
        {readOnly ? (
          <span>{`${campaign.duration} Days`}</span>
        ) : (
          <Dropdown
            items={AVAILABLE_DAYS.map(days =>
              isDisabled(retailerCampaigns, index, days)
                ? {
                    id: days,
                    label: (
                      <div>
                        <span>{days} Days</span>
                        <DatesOverlapTooltip
                          id="tooltip-dates-overlap"
                          text="This duration will conflict with other scheduled campaigns at this retailer"
                        />
                      </div>
                    ),
                    disabled: true
                  }
                : {
                    id: days,
                    label: `${days} Days`,
                    disabled: false
                  }
            )}
            selectedItemId={campaign.duration.toString()}
            onSelect={value => onValueChange(index, 'duration', parseInt(value))}
          />
        )}
      </div>
      <div>{campaign.expiration ? Formats.date(campaign.expiration, 'MM/DD/YY') : ''}</div>
      <div className="extended-check-box">
        {readOnly ? (
          campaign.extension ? (
            <span>+30 days</span>
          ) : (
            <span>No</span>
          )
        ) : isExtendable(retailerCampaigns, index) ? (
          <div>
            <span>+30 Days</span>
            <DatesOverlapTooltip
              id="tooltip-dates-overlap"
              text="This duration will conflict with other scheduled campaigns at this retailer"
            />
          </div>
        ) : (
          <React.Fragment>
            <Checkbox
              id={`extended-check-${retailerCampaigns.retailer.id}-${index}`}
              data-testid={`extended-check-${retailerCampaigns.retailer.id}-${index}`}
              checked={campaign.extension ? campaign.extension : false}
              onChange={() =>
                onValueChange(index, 'extension', campaign.extension ? !campaign.extension : true)
              }
            />
            <span>+30 days</span>
          </React.Fragment>
        )}
      </div>
      <div className="max-clips">
        {readOnly ? (
          <span>{Formats.number(campaign.maxClips)}</span>
        ) : (
          <React.Fragment>
            <NumberFormat
              data-testid={`max-clips-${retailerCampaigns.retailer.id}-${index}`}
              value={campaign.maxClips ? campaign.maxClips.toString() : ''}
              thousandSeparator
              decimalScale={0}
              allowNegative={false}
              onChange={event => {
                const parsedValue = parseFloat(event.target.value.replace(',', ''));
                onValueChange(index, 'maxClips', parsedValue);
              }}
            />
          </React.Fragment>
        )}
      </div>
      <div>{campaign.maxClipsBudget ? Formats.currency(campaign.maxClipsBudget) : ''}</div>
      {!readOnly && onCampaignRemove && retailerCampaigns.campaigns.length > 1 ? (
        <div
          className="remove-icon"
          data-testid="btn-remove-campaign"
          onClick={() => onCampaignRemove(retailerCampaigns.retailer.id, index)}
        >
          <FontAwesomeIcon color="#3F3F3F" icon={faTrashAlt} size="lg" />
        </div>
      ) : (
        <div />
      )}
    </div>
  );
};

export default RetailerDetailsRow;
