import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import { ROUTES } from 'utils/routes';
import { RootState } from 'store/storeInstance';
import * as actions from 'containers/CouponCreation/actions';
import { RetailerType } from 'utils/types';
import BuildOffer from 'containers/CouponCreation/BuildOffer';
import BuildOfferDetails from 'containers/CouponCreation/Details';
import CouponCreationBreadcrumb from 'components/CouponCreation/Breadcrumb';
import CouponCreationButtonsBar from 'components/CouponCreation/ButtonsBar';
import CampaignSummary from 'components/CouponCreation/CampaignSummary';
import CouponPreview from 'components/CouponCreation/CouponPreview';
import CouponsUtils from 'utils/coupons';
import PageHeader from 'components/PageHeader';
import ProductSelection from 'containers/CouponCreation/ProductSelection';
import RetailerSelection from 'containers/CouponCreation/RetailerSelection';
import RetailersSummaryWidget from 'components/CouponCreation/RetailersSummaryWidget';
import RetailersProvider from 'containers/RetailersProvider';
import SubmitCouponButton from 'containers/CouponCreation/SubmitButton';
import Widget from 'components/Widget';

import './styles.scss';

const CouponCreation: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const currentStep = useSelector((state: RootState) => state.couponCreation.currentStep);
  const offerForm = useSelector((state: RootState) => state.couponCreation.offerForm);
  const selectedProductsIds = useSelector(
    (state: RootState) => state.couponCreation.selectedProductsIds
  );
  const selectedRetailers = useSelector(
    (state: RootState) => state.couponCreation.selectedRetailers
  );
  const termsAndConditions = useSelector(
    (state: RootState) => state.couponCreation.termsAndConditions
  );

  const getButtonsBarProps = () => {
    const buttonsBarProps = {};
    switch (currentStep) {
      case 1:
        buttonsBarProps['continueButton'] = {
          disabled: selectedProductsIds.length === 0,
          show: true,
          onClick: () => dispatch(actions.goToStep(currentStep + 1))
        };
        break;
      case 2:
        buttonsBarProps['continueButton'] = {
          disabled: !CouponsUtils.isOfferFormValid(offerForm),
          show: true,
          onClick: () => dispatch(actions.goToStep(currentStep + 1))
        };
        buttonsBarProps['previousButton'] = {
          show: true,
          onClick: () => dispatch(actions.goToStep(currentStep - 1))
        };
        break;
      case 3:
        buttonsBarProps['continueButton'] = {
          disabled: selectedRetailers.length === 0,
          show: true,
          onClick: () => dispatch(actions.goToStep(currentStep + 1))
        };
        buttonsBarProps['previousButton'] = {
          show: true,
          onClick: () => dispatch(actions.goToStep(currentStep - 1))
        };
        break;
      case 4:
        buttonsBarProps['submitButton'] = <SubmitCouponButton />;
        buttonsBarProps['previousButton'] = {
          show: true,
          onClick: () => {
            dispatch(actions.goToStep(currentStep - 1));
            selectedRetailers.forEach(retailer => {
              let index = -1;
              retailer.campaigns.map(campaign => {
                index += 1;
                return dispatch(
                  actions.changeCampaignDetailsValue(retailer.retailer.id, index, 'maxClips', 0)
                );
              });
            });
          }
        };
        break;
    }
    return buttonsBarProps;
  };

  const renderContent = () => {
    let component = null;
    switch (currentStep) {
      case 1:
        component = <ProductSelection />;
        break;
      case 2:
        component = <BuildOffer />;
        break;
      case 3:
        component = <RetailerSelection />;
        break;
      case 4:
        component = <BuildOfferDetails />;
        break;
    }
    return component;
  };

  const renderCouponPreview = () => {
    const couponPreview = (
      <Row className="mt-4">
        <Col>
          <Widget>
            <Widget.Header>
              <div className="title">Coupon Preview</div>
            </Widget.Header>
            <Widget.Body>
              <CouponPreview
                title={offerForm.title}
                description={offerForm.description}
                requirements={offerForm.purchaseRequirementDescription}
                image={offerForm.campaignImage}
              />
            </Widget.Body>
          </Widget>
        </Col>
      </Row>
    );

    return couponPreview;
  };

  const onTermsAndConditionsChange = (value: boolean) => {
    dispatch(actions.changeTermsAndConditions(value));
  };

  return (
    <div className="coupon-creation">
      <Row>
        <PageHeader direction="columns">
          <div className="title">Digital Coupon Campaign Builder</div>
          <CouponCreationBreadcrumb
            steps={['Select Products', 'Build Offer', 'Select Retailers', 'Campaign Details']}
            stepSelected={currentStep}
          />
        </PageHeader>
      </Row>
      <Row>
        <Col className="mt-4" lg={8}>
          {renderContent()}
        </Col>
        <Col className="mt-4" lg={4}>
          <Row>
            <Col>
              <Widget>
                <Widget.Header>
                  <div className="title">Digital Coupon Campaign Summary</div>
                </Widget.Header>
                <Widget.Body>
                  <CampaignSummary
                    offerType={offerForm.type}
                    offerValue={offerForm.faceValue}
                    productsCount={selectedProductsIds.length}
                  />
                </Widget.Body>
              </Widget>
            </Col>
          </Row>
          {currentStep === 2 ? renderCouponPreview() : ''}
          <Row className="mt-4">
            <Col>
              <RetailersProvider>
                {(retailersMap: Map<string, RetailerType>) => (
                  <RetailersSummaryWidget
                    button={currentStep === 4 && <SubmitCouponButton />}
                    campaigns={selectedRetailers}
                    retailersMap={retailersMap}
                    showTermsAndConditions={currentStep === 4}
                    termsAndConditions={termsAndConditions}
                    onTermsAndConditionsChange={onTermsAndConditionsChange}
                  />
                )}
              </RetailersProvider>
            </Col>
          </Row>
          {currentStep > 2 ? renderCouponPreview() : ''}
        </Col>
      </Row>
      <Row>
        <div className="buttons-bar-container">
          <div className="separator" />
          <CouponCreationButtonsBar
            onCancel={() => history.push(ROUTES.COUPONS)}
            {...getButtonsBarProps()}
          />
        </div>
      </Row>
    </div>
  );
};

export default CouponCreation;
