import React, { useEffect, useState } from 'react';
import Paginator from 'components/Paginator';

interface Props {
  children: (indexStart: number, indexEnd: number) => React.ReactNode;
  perPage: number;
  total: number;
}

const PaginatorWrapper: React.FC<Props> = ({ children, perPage, total }) => {
  const [currentPage, setCurrentPage] = useState(1);
  // If total elements change, go to first page
  useEffect(() => {
    setCurrentPage(1);
  }, [total]);

  const indexStart = (currentPage - 1) * perPage;
  let indexEnd = Math.min(indexStart + perPage, total);
  return (
    <React.Fragment>
      {children(indexStart, indexEnd)}
      <Paginator
        currentPage={currentPage}
        perPage={perPage}
        total={total}
        onPreviousClick={page => setCurrentPage(page)}
        onNextClick={page => setCurrentPage(page)}
      />
    </React.Fragment>
  );
};

export default PaginatorWrapper;
