import * as React from 'react';
import classNames from 'classnames';
import Header from 'components/Widget/Header';
import Body from 'components/Widget/Body';

import './styles.scss';

interface Props {
  className?: string;
  children: React.ReactNode;
}

class Widget extends React.Component<Props> {
  static Header = Header;
  static Body = Body;

  render() {
    const { className, children } = this.props;
    return (
      <div
        className={classNames(
          {
            widget: true
          },
          className
        )}
      >
        {children}
      </div>
    );
  }
}

export default Widget;
