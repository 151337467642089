import { ProfileType } from 'utils/types';

class ProfileUtils {
  static cloneProfile(profile: ProfileType): ProfileType {
    return {
      ...profile
    };
  }

  static createEmptyProfile(): ProfileType {
    return {
      company: {
        name: '',
        street1: '',
        street2: '',
        city: '',
        state: null,
        zipCode: '',
        phoneNumber: ''
      },
      billing: {
        useSameAsCompany: null,
        companyInfo: {
          name: '',
          street1: '',
          street2: '',
          city: '',
          state: null,
          zipCode: '',
          phoneNumber: ''
        },
        contactName: '',
        contactEmail: '',
        contactPhoneNumber: ''
      },
      posRequired: false,
      clearing: {
        companyName: '',
        contactName: '',
        email: '',
        phoneNumber: ''
      }
    };
  }

  static isProfileComplete(profile: ProfileType): boolean {
    const companyComplete =
      profile.company.name !== '' &&
      profile.company.street1 !== '' &&
      profile.company.city !== '' &&
      profile.company.state !== null &&
      profile.company.zipCode !== '' &&
      profile.company.phoneNumber !== '';

    let billingComplete =
      profile.billing.contactName !== '' &&
      profile.billing.contactEmail !== '' &&
      profile.billing.contactPhoneNumber !== '';
    if (!profile.billing.useSameAsCompany) {
      billingComplete =
        billingComplete &&
        profile.billing.companyInfo.name !== '' &&
        profile.billing.companyInfo.street1 !== '' &&
        profile.billing.companyInfo.city !== '' &&
        profile.billing.companyInfo.state !== null &&
        profile.billing.companyInfo.zipCode !== '' &&
        profile.billing.contactName !== '' &&
        profile.billing.contactEmail !== '' &&
        profile.billing.contactPhoneNumber !== '';
    }
    const clearingComplete =
      profile.clearing.companyName !== '' &&
      profile.clearing.contactName !== '' &&
      profile.clearing.email !== '' &&
      profile.clearing.phoneNumber !== '';

    return companyComplete && billingComplete && clearingComplete;
  }

  static mapFromBackend(profile: any): ProfileType {
    const useSameAsCompany =
      profile.companyAddress.company === profile.billingAddress.company &&
      profile.companyAddress.street === profile.billingAddress.street &&
      profile.companyAddress.street2 === profile.billingAddress.street2 &&
      profile.companyAddress.city === profile.billingAddress.city &&
      profile.companyAddress.state === profile.billingAddress.state &&
      profile.companyAddress.zipcode === profile.billingAddress.zipcode &&
      profile.companyAddress.phoneNumber === profile.billingAddress.phoneNumber;
    return {
      company: {
        name: profile.companyAddress.company,
        street1: profile.companyAddress.street,
        street2: profile.companyAddress.street2 ? profile.companyAddress.street2 : '',
        city: profile.companyAddress.city,
        state: profile.companyAddress.state,
        zipCode: profile.companyAddress.zipcode,
        phoneNumber: profile.companyAddress.phoneNumber
      },
      billing: {
        useSameAsCompany: useSameAsCompany,
        companyInfo: {
          name: useSameAsCompany ? '' : profile.billingAddress.company,
          street1: useSameAsCompany ? '' : profile.billingAddress.street,
          street2: useSameAsCompany
            ? ''
            : profile.billingAddress.street2
            ? profile.billingAddress.street2
            : '',
          city: useSameAsCompany ? '' : profile.billingAddress.city,
          state: useSameAsCompany ? '' : profile.billingAddress.state,
          zipCode: useSameAsCompany ? '' : profile.billingAddress.zipcode,
          phoneNumber: useSameAsCompany ? '' : profile.billingAddress.phoneNumber
        },
        contactName: profile.billingInfo.name,
        contactEmail: profile.billingInfo.email,
        contactPhoneNumber: profile.billingInfo.phoneNumber
      },
      posRequired: profile.clearingInfo.posRequired,
      clearing: {
        companyName: profile.clearingInfo.clearingAgentCompany,
        contactName: profile.clearingInfo.clearingAgentContactName,
        email: profile.clearingInfo.clearingAgentEmail,
        phoneNumber: profile.clearingInfo.clearingAgentPhoneNumber
      }
    };
  }

  static mapToBackend(client: string, profile: ProfileType): any {
    const result = {
      client,
      billingInfo: {
        name: profile.billing.contactName,
        phoneNumber: profile.billing.contactPhoneNumber,
        email: profile.billing.contactEmail
      },
      billingAddress: null,
      companyAddress: {
        company: profile.company.name,
        street: profile.company.street1,
        street2: profile.company.street2 !== '' ? profile.company.street2 : undefined,
        city: profile.company.city,
        state: profile.company.state,
        zipcode: profile.company.zipCode,
        phoneNumber: profile.company.phoneNumber
      },
      PORequirement: profile.posRequired,
      clearingInfo: {
        posRequired: profile.posRequired,
        clearingAgentCompany: profile.clearing.companyName,
        clearingAgentContactName: profile.clearing.contactName,
        clearingAgentEmail: profile.clearing.email,
        clearingAgentPhoneNumber: profile.clearing.phoneNumber
      }
    };
    if (profile.billing.useSameAsCompany) {
      result.billingAddress = { ...result.companyAddress };
    } else {
      result.billingAddress = {
        company: profile.billing.companyInfo.name,
        street: profile.billing.companyInfo.street1,
        street2:
          profile.billing.companyInfo.street2 !== ''
            ? profile.billing.companyInfo.street2
            : undefined,
        city: profile.billing.companyInfo.city,
        state: profile.billing.companyInfo.state,
        zipcode: profile.billing.companyInfo.zipCode
      };
    }
    return result;
  }
}

export default ProfileUtils;
