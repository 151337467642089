import * as actionTypes from 'containers/Authentication/actionTypes';
import { BrandType, UserInfoType, UserLevelType, HamburgerMenuType } from 'utils/types';

export const requestLogin = (email, firstName, lastName, client) => ({
  type: actionTypes.LOGIN_REQUEST,
  payload: { email, firstName, lastName, client }
});

export const setCurrentBrand = (value: BrandType) => ({
  type: actionTypes.CURRENT_BRAND_SET,
  payload: value
});

export const setIsLoggedIn = value => ({
  type: actionTypes.IS_LOGGED_IN_SET,
  payload: value
});

export const setUserInfo = (value: UserInfoType) => ({
  type: actionTypes.USER_INFO_SET,
  payload: value
});

export const setUserLevel = (value: UserLevelType) => ({
  type: actionTypes.USER_LEVEL_SET,
  payload: value
});

export const setHamburgerMenuItems = (value: Array<HamburgerMenuType>) => ({
  type: actionTypes.HAMBURGER_MENU_ITEMS_SET,
  payload: value
});
