import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from '@spins/react-ui-library';
import moment from 'moment';
import * as actions from 'containers/CouponCreation/actions';
import CouponsUtils, { ImageType } from 'utils/coupons';
import { RootState } from 'store/storeInstance';
import {
  CreateCouponMutation as createCouponMutation,
  CreateCouponMutationVariables as createCouponMutationVariables,
  CreateCouponDocument as createCouponDocument
} from '../../../generated/graphql';
import './styles.scss';

const SubmitCouponButton: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const currentBrand = useSelector((state: RootState) => state.authentication.currentBrand);
  const offerForm = useSelector((state: RootState) => state.couponCreation.offerForm);
  const selectedProductsIds = useSelector(
    (state: RootState) => state.couponCreation.selectedProductsIds
  );
  const selectedRetailers = useSelector(
    (state: RootState) => state.couponCreation.selectedRetailers
  );
  const termsAndConditions = useSelector(
    (state: RootState) => state.couponCreation.termsAndConditions
  );
  const isCreating = useSelector((state: RootState) => state.couponCreation.isCreating);
  const userInfo = useSelector((state: RootState) => state.authentication.userInfo);

  const [createCoupon] = useMutation<createCouponMutation, createCouponMutationVariables>(
    createCouponDocument,
    {
      fetchPolicy: 'no-cache',
      onCompleted(data) {
        if (data && data.createCoupon) {
          dispatch(actions.showConfirmationModal(history));
        } else {
          dispatch(actions.changeIsCreating(false));
        }
      },
      onError(err) {
        dispatch(actions.changeIsCreating(false));
        console.log(err);
      }
    }
  );

  const createNewCouponData = (campaignImage: ImageType, productImage: ImageType = null) => {
    const campaigns = CouponsUtils.mapRetailersCampaignToBackend(selectedRetailers);

    return {
      client: currentBrand.id,
      createDate: moment().format('YYYY-MM-DD'),
      products: selectedProductsIds,
      campaigns,
      userEmail: userInfo.email,
      username: `${userInfo.firstName} ${userInfo.lastName}`,
      ...CouponsUtils.mapOfferFormToBackend(offerForm, campaignImage, productImage)
    };
  };

  const handleClick = () => {
    dispatch(actions.changeIsCreating(true));
    const promises: Array<Promise<ImageType>> = [];
    promises.push(CouponsUtils.mapImageFile(offerForm.campaignImage));
    if (offerForm.productsImage !== null) {
      promises.push(CouponsUtils.mapImageFile(offerForm.productsImage));
    }
    Promise.all(promises)
      .then((values: Array<ImageType>) => {
        const coupon = createNewCouponData(values[0], values.length > 1 ? values[1] : null);
        createCoupon({
          variables: {
            coupon: coupon as any
          }
        });
      })
      .catch(error => {
        dispatch(actions.changeIsCreating(false));
        console.log(error);
      });
  };

  return (
    <Button
      id="btn-coupon-submit"
      className="btn-coupon-submit"
      disabled={
        isCreating || !CouponsUtils.areCampaignsValid(selectedRetailers) || !termsAndConditions
      }
      loading={isCreating}
      onClick={() => handleClick()}
    >
      Submit Order
    </Button>
  );
};

export default SubmitCouponButton;
