type EnumLiteralsOf<T extends object> = T[keyof T];

export type BrandType = {
  id: string;
  name: string;
};

export interface RetailerCampaigns {
  retailer: RetailerType;
  campaigns: Array<CampaignDetails>;
}

export interface RetailerCampaign {
  retailer: RetailerType;
  campaign: CampaignDetails;
}

export interface CampaignDetails {
  duration: 30 | 60 | 90;
  expiration?: string;
  extendedDays?: number;
  extension?: boolean;
  maxClips?: number;
  maxClipsBudget?: number;
  clips?: number;
  redemptions?: number;
  redemptionRate?: number;
  startDate?: string;
  readOnly?: boolean;
}

type CompanyInfoType = {
  name: string;
  street1: string;
  street2: string;
  city: string;
  state: string;
  zipCode: string;
  phoneNumber: string;
};

export const CouponStatusEnum = Object.freeze({
  ALL: 'all',
  PENDING: 'pending',
  SCHEDULED: 'scheduled',
  ACTIVE: 'active',
  ARCHIVED: 'archived',
  HOLD_FOR_PAYMENT: 'holdForPayment'
});

export type CouponStatusType = EnumLiteralsOf<typeof CouponStatusEnum>;

export const CouponStatusName: Record<CouponStatusType, string> = {
  all: 'All',
  pending: 'Pending',
  scheduled: 'Scheduled',
  active: 'Active',
  archived: 'Archived',
  holdForPayment: 'On Hold'
};

export type BuildOfferFormType = {
  blendedGS1: string;
  campaignImage: any;
  description: string;
  faceValue: number;
  legalCopy: string;
  manufacturerId: string;
  maxValue: number;
  notes: string;
  productsImage: any;
  purchaseRequirementDescription: string;
  quantity: number;
  itemsRewardQuantity: number;
  title: string;
  type: OfferTypeItem;
};

export interface RetailerRow {
  name: string;
}

export interface IoRow {
  id: string;
  io: string;
  retailers: Array<RetailerRow>;
  extendedDays: number;
  status: CouponStatusType;
  duration: number;
  clips: number;
  maxClips: number;
  maxClipsBudget: number;
  redemptionRate: number;
  redemptions: number;
  startDate: string;
  expirationDate: string;
  statusOptions?: Array<CouponStatusType>;
  onStatusChange?(id: string, status: CouponStatusType): void;
}

export interface CouponRow {
  orderId: string;
  title: string;
  description: string;
  image: string;
  numberOfIos: number;
  maxClips: number;
  clips: number;
  redemptions: number;
  redemptionRate: number;
  ios: Array<IoRow>;
  createdDate: string;
}

export type CouponType = {
  blendedGS1?: string;
  campaignImage: any;
  campaigns: Array<RetailerCampaigns>;
  description: string;
  faceValue: number;
  id: string;
  legalCopy: string;
  manufacturerId?: string;
  maxValue: number;
  notes: string;
  productImage?: any;
  productsIds: Array<string>;
  purchaseRequirementDescription: string;
  quantity: number;
  itemsRewardQuantity: number;
  title: string;
  type: OfferTypeItem;
};

export type Coupon = {
  id: string;
  title: string;
  description: string;
  status: string;
  io: string;
  products: string[];
  gs1Databar?: string;
  campaignImageUrl?: string;
  faceValueOffer?: number;
  legal?: string;
  manufactererOfferId?: string;
  maxValueOffer?: number;
  offerType?: OfferType;
  productImageUrl?: string;
  purhcaseRequierementDescription: string;
  quantity?: number;
  itemsRewardQuantity?: number;
  redemptionRate?: number;
  percentageClipsLeft?: number;
  maxClips?: number;
  clipsRemaining?: number;
};

export type OfferTypeItem = 'dollar' | 'percentage' | 'bogo' | 'free';

export enum OfferType {
  DollarDiscount = 'dollarDiscount',
  PercentDiscount = 'percentDiscount',
  Buyxgety = 'buyxgety',
  FreeItem = 'freeItem'
}

export type ProfileType = {
  company: CompanyInfoType;
  billing: {
    useSameAsCompany: boolean;
    companyInfo?: CompanyInfoType;
    contactName: string;
    contactEmail: string;
    contactPhoneNumber: string;
  };
  posRequired: boolean;
  clearing: {
    companyName: string;
    contactName: string;
    email: string;
    phoneNumber: string;
  };
};

export type RetailerType = {
  id: string;
  parentRetailerId: string;
  name: string;
  storesCount: number;
  clipFee?: number;
  updatedClipFee?: number;
  setUpFee?: number;
  minClips?: number;
  leadTimeInDays: number;
  marketsIds: Array<string>;
  logo?: string;
};

export type UserInfoType = {
  firstName: string;
  lastName: string;
  email: string;
};

export const UserLevel = Object.freeze({
  DESTINI_ADMIN: 'destini-admin',
  CLIENT_ADMIN: 'client-admin',
  CLIENT_USER: 'client-user'
});

export type UserLevelType = EnumLiteralsOf<typeof UserLevel>;

export type HamburgerMenuType = {
  icon?: string;
  name: string;
  selected?: boolean;
  url?: string;
};

export const CouponImage = Object.freeze({
  MAX_WIDTH: 800,
  MAX_HEIGHT: 800,
  MAX_UPLOAD_SIZE: 500000, // 500 bytes
  ACCEPTED_TYPES: ['image/png', 'image/jpeg']
});

export type ProfileFormValueValidation = {
  id: string;
  value: string;
  invalidFields: Array<string>;
  validate: (string) => boolean;
};
