import React from 'react';
import { CouponStatusName, CouponStatusType } from 'utils/types';
import './styles.scss';
import Dropdown from 'components/Dropdown';

export type EditableType = {
  statusOptions: Array<CouponStatusType>;
  onSelect(status: CouponStatusType): void;
};

interface Props {
  currentStatus: CouponStatusType;
  editable?: EditableType;
}

const CouponStatus: React.FC<Props> = ({ currentStatus, editable = undefined }) => (
  <div className="coupon-status">
    <div className={`circle ${currentStatus}`} />
    {!editable ? (
      <div className="label">{CouponStatusName[currentStatus]}</div>
    ) : (
      <Dropdown
        items={editable.statusOptions.map(item => ({ id: item, label: CouponStatusName[item] }))}
        selectedItemId={currentStatus}
        title={CouponStatusName[currentStatus]}
        onSelect={editable.onSelect}
      />
    )}
  </div>
);

export default CouponStatus;
