import numeral from 'numeral';
import moment from 'moment';

class Formats {
  static currency(value: number, decimals: number = 0) {
    return numeral(value).format(`$0,0.${Array(decimals + 1).join('0')}`);
  }

  static date(value: string, format: string = 'L') {
    return moment(value).format(format);
  }

  static number(value: number, decimals: number = 0, sign: boolean = false) {
    return numeral(value).format(`${sign ? '+' : ''}0,0.${Array(decimals + 1).join('0')}`);
  }

  static percentage(value: number, decimals: number = 0, sign: boolean = true): string {
    return numeral(value / 100).format(
      `${value > 0 && sign ? '+' : ''}0,0.${Array(decimals + 1).join('0')}%`
    );
  }

  static formatNumber(value: number): string {
    return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }

  /**
   * Transform phone number as (XXX) XXX-XXXX to XXX-XXX-XXXX
   * @param value
   */
  static transformPhoneNumber(value: string): string {
    const regex: RegExp = /(\(\d{3}\)) (\d{3})-(\d{4})/;
    if (regex.test(value)) {
      const matches = value.match(regex);
      if (matches.length > 3) {
        return `${matches[1].slice(1, 4)}-${matches[2]}-${matches[3]}`;
      }
    }
    return value;
  }
}

export default Formats;
