import * as React from 'react';
import Nav from 'react-bootstrap/Nav';
import { LinkContainer } from 'react-router-bootstrap';
import { ROUTES } from 'utils/routes';

import './styles.scss';

const MainNavigation: React.FC = () => {
  return (
    <div className="main-navigation">
      <Nav as="ul" activeKey={ROUTES.COUPONS || ROUTES.COUPON_CREATION}>
        <Nav.Item as="li" bsPrefix="navigation-item">
          <LinkContainer exact to={ROUTES.COUPONS}>
            <Nav.Link bsPrefix="navigation-link" eventKey={ROUTES.COUPONS}>
              Digital Coupons
            </Nav.Link>
          </LinkContainer>
        </Nav.Item>
      </Nav>
    </div>
  );
};

export default MainNavigation;
