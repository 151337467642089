import { takeEvery, put, call, select } from 'redux-saga/effects';
import * as actionTypes from 'containers/CouponEdit/action-types';
import * as actions from 'containers/CouponEdit/actions';
import { RetailerCampaigns, CouponType } from 'utils/types';
import * as creationActions from 'containers/CouponCreation/actions';
import { ROUTES } from 'utils/routes';
import { selectedRetailersSelector } from 'containers/CouponCreation/selectors';
import { CAMPAIGN_DETAILS_VALUE_CHANGE } from 'containers/CouponCreation/action-types';

function* campaignDetailsValueChangeSaga({ payload }) {
  switch (payload.attribute) {
    case 'duration':
    case 'startDate':
    case 'extension':
      // Check if changing one of these attributes affect the rest of the values
      let differentValues = false;
      const selectedRetailers: Array<RetailerCampaigns> = yield select(state =>
        selectedRetailersSelector(state)
      );
      selectedRetailers.forEach(retailerCampaign => {
        differentValues =
          differentValues ||
          retailerCampaign.campaigns.some(details => details[payload.attribute] !== payload.value);
      });
      yield put(actions.setSaveAvailable(!differentValues));
      break;
    case 'maxClips':
      yield put(actions.setSaveAvailable(!Number.isNaN(payload.value)));
      break;
  }
}

function* finishCouponEditionSaga({ payload }) {
  yield put(actions.setCoupon(null));
  yield put(actions.setSaveAvailable(true));
  payload.history.push(ROUTES.COUPON_DETAILS(payload.couponId));
}

function* loadCouponSaga({ payload }) {
  yield put(actions.setCoupon(payload));
  yield put(creationActions.setForm(payload));
}

function* resetCouponSaga() {
  yield put(actions.setSaveAvailable(true));
}

export default function* couponEditSagas() {
  yield takeEvery(CAMPAIGN_DETAILS_VALUE_CHANGE as any, campaignDetailsValueChangeSaga);
  yield takeEvery(actionTypes.COUPON_EDITION_FINISH as any, finishCouponEditionSaga);
  yield takeEvery(actionTypes.COUPON_LOAD as any, loadCouponSaga);
  yield takeEvery(actionTypes.COUPON_EDITION_RESET as any, resetCouponSaga);
}
