import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import CompanyAddressForm from 'containers/Profile/CompanyAddressForm';
import BillingAddressForm from 'containers/Profile/BillingAddressForm';
import AdditionalInfoForm from 'containers/Profile/AdditionalInfoForm';
import SaveProfileButton from 'containers/Profile/SaveButton';
import 'containers/Profile/FormModal/styles.scss';

interface Props {
  onClose: any;
  onComplete: any;
}

const NewProfileFormModal: React.FC<Props> = ({ onClose, onComplete }) => (
  <Modal dialogClassName="new-profile-form-modal" keyboard={false} show onHide={onClose}>
    <Modal.Header closeButton>Company Profile</Modal.Header>
    <Modal.Body>
      <div className="npfm-content">
        <div className="form-container">
          <div>Company Info</div>
          <CompanyAddressForm />
        </div>
        <div className="form-container">
          <div>Billing</div>
          <BillingAddressForm />
        </div>
        <div className="form-container">
          <div>Additional Info</div>
          <AdditionalInfoForm />
        </div>
      </div>
    </Modal.Body>
    <Modal.Footer>
      <Button className="btn-cancel" onClick={onClose}>
        CANCEL
      </Button>
      <SaveProfileButton onComplete={onComplete} />
    </Modal.Footer>
  </Modal>
);

export default NewProfileFormModal;
