import React from 'react';
import InputMask from 'react-input-mask';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import './styles.scss';

interface Props {
  errorMessage?: string;
  numbersToShow: number;
  placeholder: string;
  value?: string;
  width?: number;
  onChange(value: string): void;
}

const MASK_CHAR = '_';
const REG_EXP = new RegExp(MASK_CHAR, 'g');

const SingleNumericInput: React.FC<Props> = ({
  errorMessage = '',
  numbersToShow,
  placeholder,
  value = '',
  width = 100,
  onChange,
  ...props
}) => {
  let mask = '';
  for (let i = 0; i < numbersToShow; i++) {
    mask += '9';
  }
  const isInvalid = value.length > 0 && value.length < numbersToShow;
  const isValid = value.length === numbersToShow;
  return (
    <div className="single-numeric-input">
      <InputMask
        {...props}
        className={isInvalid ? 'invalid' : null}
        mask={mask}
        maskChar={MASK_CHAR}
        placeholder={placeholder}
        style={{ width: `${width}px` }}
        value={value}
        onChange={event => onChange(event.target.value.replace(REG_EXP, ''))}
      />
      {isValid && (
        <div className="green-check">
          <FontAwesomeIcon icon={faCheck} size="xs" />
        </div>
      )}
      {isInvalid && <div className="error">{errorMessage}</div>}
    </div>
  );
};

export default SingleNumericInput;
