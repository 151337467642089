import _ from 'lodash';
import * as actionTypes from 'containers/CouponCreation/action-types';
import { RetailerCampaigns, CampaignDetails, CouponType, RetailerType } from 'utils/types';

const createDefaultCampaign = (retailer: RetailerType): CampaignDetails => ({
  duration: 30,
  expiration: null,
  maxClips: retailer.minClips,
  maxClipsBudget: retailer.updatedClipFee
    ? retailer.minClips * retailer.updatedClipFee
    : retailer.minClips * retailer.clipFee,
  startDate: null
});

export const addCampaignDetails = (retailer: RetailerType) => {
  return {
    type: actionTypes.CAMPAIGN_DETAILS_ADD,
    payload: { retailerId: retailer.id, campaign: createDefaultCampaign(retailer) }
  };
};

export const changeCampaignDetailsValue = (retailerId, index: number, attribute, value) => ({
  type: actionTypes.CAMPAIGN_DETAILS_VALUE_CHANGE,
  payload: { retailerId, index, attribute, value }
});

export const changeOfferFormValue = (attribute, value) => ({
  type: actionTypes.OFFER_FORM_VALUE_CHANGE,
  payload: { attribute, value }
});

export const changeOfferType = () => ({
  type: actionTypes.OFFER_TYPE_CHANGE
});

export const changeTermsAndConditions = (value: boolean) => ({
  type: actionTypes.TERMS_AND_CONDITIONS_CHANGE,
  payload: value
});

export const changeIsCreating = (value: boolean) => ({
  type: actionTypes.IS_CREATING_CHANGE,
  payload: value
});

export const goToStep = step => ({
  type: actionTypes.STEP_GO_TO,
  payload: step
});

export const selectAllProducts = (
  productsIds: Array<string>,
  selectedProductsIds: Array<string>
) => {
  let ids = [];
  if (selectedProductsIds.length === 0) {
    ids = productsIds.slice();
  } else {
    const intersection = _.intersection(productsIds, selectedProductsIds);
    if (intersection.length === productsIds.length) {
      // All products were already selected, remove all of them
      ids = _.difference(selectedProductsIds, productsIds);
    } else {
      ids = selectedProductsIds.concat(productsIds);
      ids = _.uniq(ids);
    }
  }
  return {
    type: actionTypes.PRODUCTS_SELECT,
    payload: ids
  };
};

export const selectProduct = (productId: string, selectedProductsIds: Array<string>) => {
  let ids;
  if (selectedProductsIds.some(id => id === productId)) {
    ids = selectedProductsIds.filter(id => id !== productId);
  } else {
    ids = selectedProductsIds.concat([productId]);
  }
  return {
    type: actionTypes.PRODUCTS_SELECT,
    payload: ids
  };
};

export const selectRetailer = (
  retailer: RetailerType,
  selectedRetailers: Array<RetailerCampaigns>
) => {
  let retailers;

  if (selectedRetailers.some(item => item.retailer.id === retailer.id)) {
    retailers = selectedRetailers.filter(item => item.retailer.id !== retailer.id);
  } else {
    const newRetailer: RetailerCampaigns = {
      retailer,
      campaigns: [createDefaultCampaign(retailer)]
    };
    retailers = _.sortBy(
      selectedRetailers.concat([newRetailer]),
      (retailerCampaigns: RetailerCampaigns) => retailerCampaigns.retailer.name
    );
  }

  // Update the clip rate if multiple retailers selected and at least one is non-Kroger
  if (retailers.length > 1 && retailers.some(item => item.retailer.setUpFee > 0)) {
    retailers.forEach(retailer => {
      retailer.retailer.updatedClipFee = 0.08;
    });
  } else {
    retailers.forEach(retailer => {
      retailer.retailer.updatedClipFee = null;
    });
  }

  return {
    type: actionTypes.RETAILER_SET,
    payload: retailers
  };
};

export const newCouponStart = () => {
  return {
    type: actionTypes.NEW_COUPON_START
  };
};

export type RetailerUpdate = {
  retailer: RetailerType;
  selected: boolean;
};

/**
 * Given a list of retailers, will updated selected retailers based on the each retailer update selected attribute.
 * @param retailersToUpdate
 * @param selectedRetailers
 */
export const updateRetailers = (
  retailersToUpdate: Array<RetailerUpdate>,
  selectedRetailers: Array<RetailerCampaigns>
) => {
  const idsToRemove = [];
  const newSelectedRetailers = selectedRetailers.slice();
  retailersToUpdate.forEach(rtu => {
    const found = selectedRetailers.some(
      selectedRetailer => selectedRetailer.retailer.id === rtu.retailer.id
    );
    if (found) {
      if (!rtu.selected) {
        idsToRemove.push(rtu.retailer.id);
      }
    } else if (rtu.selected) {
      // retailer not found and selected, add it
      newSelectedRetailers.push({
        retailer: rtu.retailer,
        campaigns: [createDefaultCampaign(rtu.retailer)]
      });
    }
  });
  return {
    type: actionTypes.RETAILER_SET,
    payload: newSelectedRetailers.filter(item => !idsToRemove.some(id => id === item.retailer.id))
  };
};

export const setForm = (coupon: CouponType) => ({
  type: actionTypes.FORM_SET,
  payload: coupon
});

export const setShowConfirmationModal = value => ({
  type: actionTypes.NEW_COUPON_CONFIRMATION_SHOW_SET,
  payload: value
});

export const showConfirmationModal = history => ({
  type: actionTypes.NEW_COUPON_CONFIRMATION_SHOW,
  payload: history
});

export const removeCampaignDetails = (retailerId, index) => ({
  type: actionTypes.CAMPAIGN_DETAILS_REMOVE,
  payload: { retailerId, index }
});
