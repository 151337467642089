import React from 'react';
import Widget from 'components/Widget';
import { RetailerCampaigns, CampaignDetails, RetailerType } from 'utils/types';
import './styles.scss';
import Table from 'react-bootstrap/Table';
import Checkbox from 'components/Checkbox';
import Formats from 'utils/formats';
import Image from 'react-bootstrap/Image';
import CouponsUtils from 'utils/coupons';

const TERMS_AND_CONDITIONS_LINK =
  'https://destini.co/terms/Destini-Digital-Coupon-Terms-and-Conditions.pdf';

const formatDate = (date: string) => {
  return date !== null ? Formats.date(date) : '';
};

interface Props {
  button?: React.ReactElement;
  campaigns: Array<RetailerCampaigns>;
  retailersMap: Map<string, RetailerType>;
  showTermsAndConditions?: boolean;
  termsAndConditions?: boolean;
  onTermsAndConditionsChange?(value: boolean): void;
}

const RetailersSummaryWidget: React.FC<Props> = ({
  button = null,
  campaigns,
  retailersMap,
  showTermsAndConditions,
  termsAndConditions = true,
  onTermsAndConditionsChange
}) => {
  const rows = [];
  let campaignsTotal = 0;
  campaigns.forEach((retailerCampaigns: RetailerCampaigns) => {
    retailerCampaigns.campaigns.forEach((campaign: CampaignDetails, index) => {
      campaignsTotal += 1;
      rows.push(
        <tr key={`${retailerCampaigns.retailer.id}-${index}`}>
          <td>
            {retailerCampaigns.retailer.logo ? (
              <Image src={retailerCampaigns.retailer.logo} alt="logo" fluid />
            ) : (
              ''
            )}
            {retailerCampaigns.retailer.parentRetailerId !== null
              ? retailersMap.get(retailerCampaigns.retailer.parentRetailerId).name
              : retailerCampaigns.retailer.name}
          </td>
          <td>{formatDate(campaign.startDate)}</td>
          <td>{formatDate(campaign.expiration)}</td>
          <td>{Formats.currency(campaign.maxClipsBudget)}</td>
        </tr>
      );
    });
  });
  return (
    <div className="retailer-summary-widget">
      <Widget>
        <Widget.Header>
          <div className="title">{`Digital Coupon Campaigns (${campaignsTotal})`}</div>
        </Widget.Header>
        <Widget.Body>
          <Table className="retailers-table">
            <colgroup>
              <col />
              <col />
              <col />
              <col />
            </colgroup>
            <thead>
              <tr>
                <th>retailer</th>
                <th>start</th>
                <th>end date</th>
                <th>clip budget</th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </Table>
          {campaignsTotal > 0 && (
            <>
              <div className="total-budget-container">
                <span className="total-budget-text">Set Up Fee</span>
                <span className="total-budget-value">
                  {Formats.currency(CouponsUtils.calculateSetUpFee(campaigns))}
                </span>
              </div>
              <div className="total-budget-container">
                <span className="total-budget-text">Grand Total</span>
                <span className="total-budget-value">
                  {Formats.currency(
                    CouponsUtils.calculateCampaignsTotal(campaigns) +
                      CouponsUtils.calculateSetUpFee(campaigns)
                  )}
                </span>
              </div>
            </>
          )}
          {showTermsAndConditions && onTermsAndConditionsChange && (
            <div className="terms-conditions-container">
              <Checkbox
                id="chk-terms-and-conditions"
                checked={termsAndConditions}
                onChange={() => onTermsAndConditionsChange(!termsAndConditions)}
              />
              <span className="text">
                I agree to{' '}
                <a href={TERMS_AND_CONDITIONS_LINK} rel="noopener noreferrer" target="_blank">
                  terms and conditions
                </a>
              </span>
            </div>
          )}
          {button}
        </Widget.Body>
      </Widget>
    </div>
  );
};

export default RetailersSummaryWidget;
