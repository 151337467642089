import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/Button';
import './styles.scss';

interface Props {
  currentPage: number;
  perPage?: number;
  total: number;
  onPreviousClick: (number) => void;
  onNextClick: (number) => void;
}

const Paginator = ({ currentPage, perPage, total, onPreviousClick, onNextClick }) => {
  const lowerLimit = Math.min(perPage * (currentPage - 1) + 1, total);
  const upperLimit = Math.min(total, currentPage * perPage);
  return (
    <div className="paginator">
      <span className="total">{`${lowerLimit}-${upperLimit} of ${total}`}</span>
      <Button
        disabled={lowerLimit === 1 || total === 0}
        onClick={() => onPreviousClick(currentPage - 1)}
      >
        <FontAwesomeIcon className="icon" icon={faAngleLeft} size="lg" />
      </Button>{' '}
      <Button
        disabled={currentPage * perPage >= total}
        onClick={() => onNextClick(currentPage + 1)}
      >
        <FontAwesomeIcon className="icon" icon={faAngleRight} size="lg" />
      </Button>
    </div>
  );
};

export default Paginator;
