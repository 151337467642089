import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import './styles.scss';

interface Props {
  checked?: boolean;
  disabled?: boolean;
  label?: string;
  id: string;
  onChange: (number) => void;
}

const handleChange = (e, id, onChange) => {
  e.stopPropagation();
  onChange(id);
};

const Checkbox: React.FC<Props> = ({
  checked = false,
  disabled = false,
  id,
  label = '',
  onChange,
  ...props
}) => {
  const checkMarkClasses = classNames({
    'check-mark': true,
    checked: checked,
    disabled: disabled
  });
  return (
    <label className="checkbox" htmlFor={id}>
      {label !== '' ? <span className="label">{label}</span> : null}
      <input
        {...props}
        checked={checked}
        disabled={disabled}
        id={id}
        type="checkbox"
        onChange={e => handleChange(e, id, onChange)}
      />
      <span className={checkMarkClasses}>{checked && <FontAwesomeIcon icon={faCheck} />}</span>
    </label>
  );
};

export default Checkbox;
