export const BILLING_ADDRESS_INFO_CLEAR = 'Profile/billing-address-info-clear';
export const COMPANY_ADDRESS_INFO_COPY = 'Profile/company-address-info-copy';
export const COMPANY_PROFILE_EDIT_START = 'Profile/company-profile-edit-start';
export const LOADING_PROFILE_SET = 'Profile/loading-profile-set';
export const MODAL_SHOW_SET = 'Profile/modal-show-set';
export const MODAL_START = 'Profile/modal-start';
export const PHONE_NUMBER_VALIDATE = 'Profile/phone-number-validate';
export const PROFILE_SET = 'Profile/profile-set';
export const STEP_VALUE_SET = 'Profile/step-value-set';
export const TRANSIENT_PROFILE_SET = 'Profile/transient-profile-set';
export const TRANSIENT_PROFILE_VALUE_CHANGE = 'Profile/transient-profile-value-change';
export const TRANSIENT_PROFILE_INVALID_FIELDS_SET = 'Profile/transient-profile-invalid-fields-set';
export const VALIDATION_PROFILE_SET = 'Profile/validating-profile-set';
