import React from 'react';
import ProfileFormField from 'components/Profile/FormField';
import ProfileTextInput from 'components/Profile/TextInput';
import Validations from 'utils/validations';
import RadioButton from 'components/RadioButton';
import BasicForm from 'containers/Profile/BasicForm';
import './styles.scss';
import { validatePhoneNumber } from 'containers/Profile/actions';

const AdditionalInfoForm: React.FC = () => (
  <BasicForm>
    {({
      invalidFields,
      profile,
      onValueChange,
      onValueValidation,
      onValueValidationWithAction
    }) => (
      <div className="address-form additional-info-form">
        <div>
          <ProfileFormField
            id="posRequired"
            label="do you require pos for orders?"
            onChange={(id, value) => onValueChange(id, value)}
          >
            {(invalid, onBlur, onChange) => (
              <RadioButton
                options={[
                  { id: 1, label: 'Yes' },
                  { id: 0, label: 'No' }
                ]}
                orientation="horizontal"
                value={profile.posRequired ? 1 : 0}
                onChange={id => onChange(id === 1)}
              />
            )}
          </ProfileFormField>
        </div>
        <div className="field-container">
          <ProfileFormField
            id="clearing.companyName"
            label="clearing agent company"
            onChange={(id, value) => onValueChange(id, value)}
          >
            {(invalid, onBlur, onChange) => (
              <ProfileTextInput
                placeholder="Enter Agent Company"
                value={profile.clearing.companyName}
                onChange={onChange}
              />
            )}
          </ProfileFormField>
        </div>
        <div className="field-container">
          <ProfileFormField
            id="clearing.contactName"
            label="clearing agent contact name"
            onChange={(id, value) => onValueChange(id, value)}
          >
            {(invalid, onBlur, onChange) => (
              <ProfileTextInput
                placeholder="Enter Agent Contact Name"
                value={profile.clearing.contactName}
                onChange={onChange}
              />
            )}
          </ProfileFormField>
        </div>
        <div className="field-container">
          <ProfileFormField
            errorMessage="Email invalid"
            id="clearing.email"
            invalid={invalidFields.some(attribute => attribute === 'clearing.email')}
            label="clearing agent email"
            validator={Validations.isEmailValid}
            onBlur={(id, value, validate) =>
              onValueValidation({ id, value, invalidFields, validate })
            }
            onChange={(id, value) => onValueChange(id, value)}
          >
            {(invalid, onBlur, onChange) => (
              <ProfileTextInput
                invalid={invalid}
                placeholder="Enter Agent Contact Email"
                value={profile.clearing.email}
                onChange={onChange}
                onBlur={onBlur}
              />
            )}
          </ProfileFormField>
        </div>
        <div className="field-container">
          <ProfileFormField
            errorMessage="Please enter a valid 10 digit phone number"
            id="clearing.phoneNumber"
            invalid={invalidFields.some(attribute => attribute === 'clearing.phoneNumber')}
            label="clearing agent phone number"
            onBlur={(id, value) => {
              onValueValidationWithAction(validatePhoneNumber(id, value, invalidFields));
            }}
            onChange={(id, value) => onValueChange(id, value)}
          >
            {(invalid, onBlur, onChange) => (
              <ProfileTextInput
                invalid={invalid}
                mask="(999) 999-9999"
                placeholder="Enter Agent Contact Phone Number"
                value={profile.clearing.phoneNumber}
                onChange={onChange}
                onBlur={onBlur}
              />
            )}
          </ProfileFormField>
        </div>
      </div>
    )}
  </BasicForm>
);

export default AdditionalInfoForm;
