import React from 'react';
import { CouponStatusType } from 'utils/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faEye, faHandPaper, faHourglass } from '@fortawesome/free-regular-svg-icons';
import { faCheck, faRocket } from '@fortawesome/free-solid-svg-icons';
import Formats from 'utils/formats';
import './styles.scss';

type Data = {
  icon: IconDefinition;
  subTitle: string;
  title: string;
};

const getDataForStatus = (status: CouponStatusType, endDate: string): Data => {
  switch (status) {
    case 'pending':
      return {
        icon: faEye,
        subTitle:
          'A coupon expert is reviewing your coupon submission and you will be contacted if there are any needed changes. You will then receive an email confirmation once the campaign is scheduled to go live.',
        title: 'Pending'
      };
    case 'scheduled':
      return {
        icon: faCheck,
        subTitle: 'This coupon is loaded and scheduled to run.',
        title: 'Scheduled'
      };
    case 'active':
      return {
        icon: faRocket,
        subTitle: 'Since this coupon is being displayed in campaign apps.',
        title: `This coupon is ACTIVE until ${
          endDate !== null ? Formats.date(endDate, 'l') : 'N/A'
        }`
      };
    case 'archived':
      return {
        icon: faHandPaper,
        subTitle:
          'Since this coupon has reached its limit, it has been turned off and no longer is displayed within campaign apps. To run again, duplicate the offer and change the date range.',
        title: 'This coupon is ARCHIVED'
      };
    case 'holdForPayment':
      return {
        icon: faHourglass,
        subTitle:
          'This campaign is on hold. You will be contacted by your Destini representative or you can reach out to us at destinicoupons@destini.co.',
        title: 'On Hold'
      };
    default:
      return null;
  }
};

interface Props {
  endDate?: string;
  status: CouponStatusType;
}

const StatusBanner: React.FC<Props> = ({ endDate = null, status }) => {
  const data = getDataForStatus(status, endDate);

  return (
    <div className={`status-banner ${status}`}>
      <div className="icon">
        <FontAwesomeIcon icon={data.icon} size="4x" />
      </div>
      <div className="titles">
        <div className="title">{data.title}</div>
        <div className="sub-title">{data.subTitle}</div>
      </div>
    </div>
  );
};

export default StatusBanner;
