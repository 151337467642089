export const ROUTES = {
  AUTH: '/auth',
  COMPANY_PROFILE: '/company-profile',
  COUPONS: '/',
  COUPON_CREATION: '/coupons/new',
  COUPON_DETAILS: (id: string = null) => (id === null ? '/coupons/:id' : `/coupons/${id}`),
  COUPON_EDIT_DETAILS: (id: string = null) =>
    id === null ? '/coupons/edit/details/:id' : `/coupons/edit/details/${id}`,
  COUPON_EDIT_PRODUCTS: (id: string = null) =>
    id === null ? '/coupons/edit/products/:id' : `/coupons/edit/products/${id}`,
  COUPON_EDIT_RETAILERS: (id: string = null) =>
    id === null ? '/coupons/edit/retailers/:id' : `/coupons/edit/retailers/${id}`,
  CHANGE_BRAND: '/change-brand',
  REDIRECT_CMS: '/redirect-cms'
};
