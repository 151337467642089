import * as actionTypes from 'containers/WelcomeModal/action-types';

type StateType = {
  readonly show: boolean;
  readonly step: number;
};

export const initialState: StateType = {
  show: false,
  step: 0
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.WELCOME_MODAL_CLOSE:
      return {
        ...state,
        show: false
      };
    case actionTypes.WELCOME_MODAL_STEP_SET:
      return {
        ...state,
        step: action.payload
      };
    case actionTypes.WELCOME_MODAL_SHOW_SET:
      return {
        ...state,
        show: action.payload
      };
    default:
      return state;
  }
};

export default reducer;
