import { ProfileType } from 'utils/types';

class StoreUtils {
  /**
   * Return a cloned object with the attribute path updated to the given value.
   * @param object - Object to update
   * @param attribute - String with attributes, separated bye dots.
   * @param value - Value to update.
   */
  static updateObjectAttribute(object: object, attribute: string, value: string): ProfileType {
    const attributes = attribute.split('.');
    if (attributes.length === 1) {
      // @ts-ignore
      return { ...object, [attributes[0]]: value };
    } else {
      // @ts-ignore
      return {
        ...object,
        [attributes[0]]: StoreUtils.updateObjectAttribute(
          object[attributes[0]],
          attributes.slice(1).join('.'),
          value
        )
      };
    }
  }
}

export default StoreUtils;
