import React from 'react';
import Modal from 'react-bootstrap/Modal';
import './styles.scss';
import Button from 'react-bootstrap/Button';
import Checkbox from 'components/Checkbox';

type MarketType = {
  id: string;
  name: string;
};

interface Props {
  markets: Array<MarketType>;
  retailerName: string;
  selectedMarketsIds?: Array<string>;
  show: boolean;
  onCancel: any;
  onOk: any;
  onSelect(id: string): void;
}

const MarketsSelectionModal: React.FC<Props> = ({
  markets,
  retailerName,
  selectedMarketsIds = [],
  show,
  onCancel,
  onOk,
  onSelect
}) => (
  <Modal
    backdrop="static"
    centered
    dialogClassName="markets-selection-modal"
    keyboard={false}
    show={show}
  >
    <Modal.Header>
      <Modal.Title>{`Select Markets for ${retailerName}`}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className="msm-content">
        <div className="retailer-name">{retailerName}</div>
        <div className="markets-container">
          {markets.map(market => {
            return (
              <div className="market-item" key={market.id}>
                <Checkbox
                  id={market.id}
                  checked={selectedMarketsIds.some(id => id === market.id)}
                  onChange={onSelect}
                />
                <div className="name">{market.name}</div>
              </div>
            );
          })}
        </div>
      </div>
    </Modal.Body>
    <Modal.Footer>
      <Button className="btn-cancel" onClick={onCancel}>
        CANCEL
      </Button>
      <Button className="btn-ok" onClick={onOk}>
        OK
      </Button>
    </Modal.Footer>
  </Modal>
);

export default MarketsSelectionModal;
