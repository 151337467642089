import React from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { CouponStatusName, CouponStatusType } from 'utils/types';
import './styles.scss';

const getDropDownItem = (key: string, currentFilter: string, label: string, onSelect) => (
  <Dropdown.Item
    key={key}
    active={currentFilter === key}
    eventKey={key}
    onSelect={key => onSelect(key)}
  >
    {label}
  </Dropdown.Item>
);

interface Props {
  title: string;
  selected: string;
  onSelect: (key: CouponStatusType) => any;
}

const StatusFilterDropdown: React.FC<Props> = ({ title, selected, onSelect }) => {
  return (
    <DropdownButton id="status-dropdown" className="status-dropdown" title={title}>
      {Object.entries(CouponStatusName).map(([key, value]) =>
        getDropDownItem(key, selected, value, onSelect)
      )}
    </DropdownButton>
  );
};

export default StatusFilterDropdown;
