import { createSelector } from 'reselect';

const getReducer = state => state.couponCreation;

export const currentStepSelector = createSelector(
  state => getReducer(state).currentStep,
  currentStep => currentStep
);

export const offerFormSelector = createSelector(
  state => getReducer(state).offerForm,
  offerForm => offerForm
);

export const selectedProductsIdsSelector = createSelector(
  state => getReducer(state).selectedProductsIds,
  selectedProductsIds => selectedProductsIds
);

export const selectedRetailersSelector = createSelector(
  state => getReducer(state).selectedRetailers,
  selectedRetailers => selectedRetailers
);

export const showConfirmationModalSelector = createSelector(
  state => getReducer(state).showConfirmationModal,
  showConfirmationModal => showConfirmationModal
);

export const termsAndConditionsSelector = createSelector(
  state => getReducer(state).termsAndConditions,
  termsAndConditions => termsAndConditions
);

export const isCouponCreatingSelector = createSelector(
  state => getReducer(state).isCreating,
  creating => creating
);
