import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import { AppSyncProductsConfig } from './config';

const { graphqlEndpoint, region, apiKey } = AppSyncProductsConfig;

const httpLink = new HttpLink({
  uri: graphqlEndpoint
});

const authLink = setContext((_, { headers }) => {
  return {
    ...headers,
    headers: {
      'x-api-key': apiKey
    }
  };
});

const link = ApolloLink.from([authLink, httpLink]);

const client = new ApolloClient({
  link,
  region,
  cache: new InMemoryCache({
    addTypename: false
  })
});

export default client;
