import * as React from 'react';
import Nav from 'react-bootstrap/Nav';
import { LinkContainer } from 'react-router-bootstrap';
import { ROUTES } from 'utils/routes';
import './styles.scss';

interface Props {
  labels: Array<string>;
}

const Breadcrumb: React.FC<Props> = ({ labels }) => (
  <div className="page-header-breadcrumb">
    {labels.map((label, index) => {
      const first = index === 0;
      const last = labels.length - 1 === index;
      return (
        <span key={index} className={first ? 'first' : last ? 'last' : null}>
          {first ? (
            <LinkContainer exact to={ROUTES.COUPONS}>
              <Nav.Link bsPrefix="navigation-link" eventKey={ROUTES.COUPONS}>
                {label} /&nbsp;
              </Nav.Link>
            </LinkContainer>
          ) : (
            `${label}${!last ? ' / ' : ''}`
          )}
        </span>
      );
    })}
  </div>
);

export default Breadcrumb;
