import * as React from 'react';
import './styles.scss';

interface Props {
  children: React.ReactNode;
}

const Body: React.FC<Props> = ({ children }) => <div className="widget-body">{children}</div>;

export default Body;
