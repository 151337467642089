import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { usePortalCmsLogout } from '@spins/react-sso-provider';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { ROUTES } from 'utils/routes';
import { RootState } from 'store/storeInstance';
import { getCmsLogoutUrl } from 'utils/authentication';
import HamburgerMenu from 'components/HamburgerMenu';

import 'containers/MainHeader/styles.scss';

const MainHeader: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { logout: logoutPortalCsm } = usePortalCmsLogout();

  const currentBrand = useSelector((state: RootState) => state.authentication.currentBrand);
  const userInfo = useSelector((state: RootState) => state.authentication.userInfo);
  const hamburgerMenuItems = useSelector(
    (state: RootState) => state.authentication.hamburgerMenuItems
  );

  let name = '';
  if (userInfo !== null && currentBrand !== null) {
    name = `${userInfo.firstName} ${userInfo.lastName} (${currentBrand.name})`;
  }

  const logout = () => {
    logoutPortalCsm(getCmsLogoutUrl());
  };

  return (
    <div className="main-header">
      <div className="hamburger-menu-container">
        <HamburgerMenu items={hamburgerMenuItems} />
      </div>
      <div className="destini-logo">
        <span className="icon-destiniLogo" />
      </div>
      <div className="project-name">activation</div>
      <div className="dropdown-container">
        <DropdownButton id="main-header-dropdown" data-testid="main-header-dropdown" title={name}>
          <Dropdown.Item
            className="dropdown-item"
            data-testid="dropdown-item-company-profile"
            onSelect={() => history.push(ROUTES.COMPANY_PROFILE)}
          >
            Company Profile
          </Dropdown.Item>
          <Dropdown.Item
            className="dropdown-item"
            data-testid="dropdown-item-change-brand"
            onSelect={() => history.push(ROUTES.CHANGE_BRAND)}
          >
            Switch Brand
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item
            className="dropdown-item"
            data-testid="dropdown-item-logout"
            onSelect={logout}
          >
            Log out
          </Dropdown.Item>
        </DropdownButton>
      </div>
    </div>
  );
};

export default MainHeader;
