import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import Alert from 'react-bootstrap/Alert';
import './styles.scss';

interface Props extends RouteComponentProps {
  children: any;
}

interface State {
  error: string;
  hasError: boolean;
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      hasError: false
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    const { location } = this.props;
    if (location.pathname !== prevProps.location.pathname) {
      this.setState({ hasError: false });
    }
  }

  componentDidCatch(error, errorInfo) {
    console.error(error);
    console.error(errorInfo);
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;
    if (hasError) {
      return (
        <div className="error-boundary">
          <Alert variant="danger">
            Unable to complete the request. Please contact{' '}
            <a
              className="support-mail"
              target="_blank"
              rel="noopener noreferrer"
              href="mailto:couponsupport@destini.co"
            >
              Support
            </a>
            .
          </Alert>
        </div>
      );
    }
    return children;
  }
}

export default withRouter(ErrorBoundary);
