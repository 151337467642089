import * as actionTypes from 'containers/CouponEdit/action-types';
import { CouponType } from 'utils/types';

export const finishEdition = (couponId: string, history) => ({
  type: actionTypes.COUPON_EDITION_FINISH,
  payload: { couponId, history }
});

export const loadCouponEdit = (coupon: CouponType) => ({
  type: actionTypes.COUPON_LOAD,
  payload: coupon
});

export const setCoupon = (value: CouponType) => ({
  type: actionTypes.COUPON_SET,
  payload: value
});

export const setLoadingCoupon = (value: boolean) => ({
  type: actionTypes.COUPON_LOADING_SET,
  payload: value
});

export const setSaveAvailable = (value: boolean) => ({
  type: actionTypes.COUPON_SAVE_AVAILABLE_SET,
  payload: value
});

export const resetCoupon = () => ({
  type: actionTypes.COUPON_EDITION_RESET
});
