import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import { RootState } from 'store/storeInstance';
import * as actions from 'containers/Profile/actions';
import { Col } from 'react-bootstrap';
import { ROUTES } from 'utils/routes';
import AdditionalInfoForm from 'containers/Profile/AdditionalInfoForm';
import BillingAddressForm from 'containers/Profile/BillingAddressForm';
import Button from 'react-bootstrap/Button';
import CompanyAddressForm from 'containers/Profile/CompanyAddressForm';
import PageHeader from 'components/PageHeader';
import ProfileUtils from 'utils/profile';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import SaveProfileButton from 'containers/Profile/SaveButton';
import Widget from 'components/Widget';
import {
  GetProfileQuery as getProfileQuery,
  GetProfileQueryVariables as getProfileQueryVariables,
  GetProfileDocument as getProfileDocument
} from '../../../generated/graphql';

import './styles.scss';

const CompanyProfile: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const currentBrand = useSelector((state: RootState) => state.authentication.currentBrand);
  const loadingProfile = useSelector((state: RootState) => state.profile.loadingProfile);
  const profile = useSelector((state: RootState) => state.profile.profile);

  const onSetLoading = (loading: Boolean) => {
    dispatch(actions.setLoadingProfile(loading));
  };

  const onSetProfile = (profileData: any) => {
    dispatch(actions.setProfile(profileData));
  };

  const onSetTransientProfile = (transientProfileData: any) => {
    dispatch(actions.setTransientProfile(transientProfileData));
  };

  const { data, loading } = useQuery<getProfileQuery, getProfileQueryVariables>(
    getProfileDocument,
    {
      variables: { clientId: currentBrand.id }
    }
  );

  useEffect(() => {
    if (profile !== null) {
      onSetTransientProfile(ProfileUtils.cloneProfile(profile));
    } else {
      onSetLoading(loading);

      if (data && data?.getProfile !== null) {
        const profileData = ProfileUtils.mapFromBackend(data.getProfile);
        onSetProfile(profileData);
        onSetTransientProfile(ProfileUtils.cloneProfile(profileData));
      } else {
        onSetTransientProfile(ProfileUtils.createEmptyProfile());
      }
    }
  }, [data, loading]);

  const handleClick = () => {
    history.push(ROUTES.COUPONS);
  };

  return (
    <div className="company-profile">
      <Row>
        <PageHeader direction="rows">
          <PageHeader.Title title="Company Profile" />
          <div>
            <Button className="btn-cancel" onClick={handleClick}>
              CANCEL
            </Button>
            <SaveProfileButton onComplete={handleClick} />
          </div>
        </PageHeader>
      </Row>
      {loadingProfile ? (
        <Row>
          <Col lg={{ span: 4, offset: 4 }} className="text-center" style={{ marginTop: '200px' }}>
            <Spinner animation="border" className="loading-icon" />
          </Col>
        </Row>
      ) : (
        <Row>
          <Col lg={6} className="mt-4">
            <Widget>
              <Widget.Header>
                <div className="title">Company Info</div>
              </Widget.Header>
              <Widget.Body>
                <CompanyAddressForm />
              </Widget.Body>
            </Widget>
            <div className="mt-4">
              <Widget>
                <Widget.Header>
                  <div className="title">Billing</div>
                </Widget.Header>
                <Widget.Body>
                  <BillingAddressForm />
                </Widget.Body>
              </Widget>
            </div>
          </Col>
          <Col lg={6} className="mt-4">
            <Widget>
              <Widget.Header>
                <div className="title">Additional Info</div>
              </Widget.Header>
              <Widget.Body>
                <AdditionalInfoForm />
              </Widget.Body>
            </Widget>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default CompanyProfile;
