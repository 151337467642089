import React from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import './styles.scss';

interface Props {
  ascending?: boolean;
  children?: React.ReactNode;
  className?: string;
  id: string;
  title: string;
  onSort?: (key, boolean) => void;
}

const TableHeader: React.FC<Props> = ({
  ascending = null,
  children,
  className,
  id,
  title,
  onSort = null
}) => {
  const mainClasses = classNames({
    'table-header': true,
    sorting: onSort !== null,
    [className]: className !== null
  });
  return (
    <th className={mainClasses}>
      <div className="th-container">
        <div
          className="th-clickable"
          onClick={onSort !== null ? () => onSort(id, !ascending) : null}
        >
          {title}
          {onSort !== null && ascending !== null ? (
            <span>
              <FontAwesomeIcon icon={ascending ? faChevronUp : faChevronDown} />
            </span>
          ) : null}
        </div>
        {children}
      </div>
    </th>
  );
};

export default TableHeader;
