import { put, takeEvery } from 'redux-saga/effects';
import { getBaseCmsUrl, mapHamburgerMenuItems } from 'utils/authentication';
import {
  setCurrentBrand,
  setIsLoggedIn,
  setUserInfo,
  setUserLevel,
  setHamburgerMenuItems
} from 'containers/Authentication/actions';
import { LOGIN_REQUEST } from 'containers/Authentication/actionTypes';

function* requestLoginSaga({ payload }) {
  const { email, firstName, lastName, client } = payload;

  if (client) {
    // Check welcome modal
    /* 
      fix: disable welcome modal
        const welcomeModalShown: boolean = WelcomeModalUtils.wasShown();
        if (!welcomeModalShown) {
          WelcomeModalUtils.setShown();
          yield put(setShow(true));
        }
    */
    // Set brand
    yield put(
      setCurrentBrand({
        id: client.client_id,
        name: client.client_name
      })
    );
    // Set user info
    yield put(
      setUserInfo({
        firstName,
        lastName,
        email
      })
    );
    // Set user level
    yield put(setUserLevel(client.level));
    // Set menu
    yield put(setHamburgerMenuItems(mapHamburgerMenuItems(client, getBaseCmsUrl())));
    yield put(setIsLoggedIn(true));
  }
}

export default function* authenticationSagas() {
  yield takeEvery(LOGIN_REQUEST as any, requestLoginSaga);
}
