export const AppSyncConfig = {
  graphqlEndpoint: process.env.REACT_APP_COUPONS_API_ENDPOINT,
  region: 'us-west-2',
  authenticationType: 'API_KEY',
  apiKey: process.env.REACT_APP_COUPONS_API_KEY
};

export const AppSyncProductsConfig = {
  graphqlEndpoint: process.env.REACT_APP_PRODUCTS_API_ENDPOINT,
  region: 'us-west-2',
  authenticationType: 'API_KEY',
  apiKey: process.env.REACT_APP_PRODUCTS_API_KEY
};
