import React from 'react';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import 'components/CouponCreation/OfferInput/styles.scss';

interface Props extends NumberFormatProps {
  disabled?: boolean;
  placeholder?: string;
  onChange: any;
}

const OfferNumberInput: React.FC<Props> = ({
  disabled = false,
  placeholder,
  value,
  onChange,
  ...props
}) => (
  <div className="offer-input">
    <NumberFormat
      {...props}
      disabled={disabled}
      placeholder={placeholder}
      value={value}
      onValueChange={v => {
        if (value !== v.floatValue) {
          // Check original vs current value otherwise the event is fired event if the value doesn't change
          onChange(v.floatValue !== undefined ? v.floatValue : null);
        }
      }}
    />
  </div>
);

export default OfferNumberInput;
