import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import * as actions from 'containers/CouponCreation/actions';
import { CouponImage } from 'utils/types';
import { RootState } from 'store/storeInstance';

import OfferDetailsWidget from 'components/CouponCreation/OfferDetailsWidget';
import OfferType from 'components/CouponCreation/OfferType';
import OfferTextInput from 'components/CouponCreation/OfferInput/TextInput';
import OfferImageDropZone from 'components/CouponCreation/ImageDropzone';
import OfferNumberInput from 'components/CouponCreation/OfferInput/NumberInput';
import OfferTextarea from 'components/CouponCreation/OfferInput/Textarea';
import SingleNumericInput from 'components/CouponCreation/SingleNumericInput';
import Widget from 'components/Widget';

import './styles.scss';

const BuildOffer: React.FC = () => {
  const dispatch = useDispatch();

  const [mounted, setMounted] = useState(false);
  const offerForm = useSelector((state: RootState) => state.couponCreation.offerForm);
  const coupon = useSelector((state: RootState) => state.couponEdit.coupon);

  useEffect(() => {
    if (!mounted) {
      window.scrollTo(0, 0);
      setMounted(true);
    }
  });

  const onOfferTypeChange = () => {
    dispatch(actions.changeOfferType());
  };

  const onOfferFormValueChange = (attribute, value) => {
    dispatch(actions.changeOfferFormValue(attribute, value));
  };

  const handleTypeChange = type => {
    if (offerForm.type !== null) {
      // Clear values only if previously a type was selected
      onOfferTypeChange();
    }

    onOfferFormValueChange('type', type);
  };

  return (
    <div className="build-offer">
      <Widget>
        <Widget.Header>
          <div className="title">Offer Details</div>
        </Widget.Header>
        <Widget.Body>
          <div className="bo-required-fields">* Required fields</div>
          <div className="bo-content">
            <Row>
              <Col>
                <OfferDetailsWidget title="Offer Type *">
                  <OfferType
                    selectedType={offerForm.type}
                    onSelect={handleTypeChange}
                    disabled={coupon?.id}
                  />
                </OfferDetailsWidget>
              </Col>
            </Row>
            <Row className="mt-5">
              <Col lg={3}>
                <OfferDetailsWidget title="Face Value Offer *">
                  <OfferNumberInput
                    data-testid="face-value-input"
                    prefix={offerForm.type !== 'percentage' ? '$' : ''}
                    suffix={offerForm.type === 'percentage' ? '%' : ''}
                    value={offerForm.faceValue}
                    onChange={value => onOfferFormValueChange('faceValue', value)}
                  />
                </OfferDetailsWidget>
              </Col>
              {offerForm.type === 'percentage' && (
                <Col lg={3} data-testid="max-value-input-container">
                  <OfferDetailsWidget title="Max Value Offer">
                    <OfferNumberInput
                      data-testid="max-value-input"
                      prefix="$"
                      value={offerForm.maxValue}
                      onChange={value => onOfferFormValueChange('maxValue', value)}
                    />
                  </OfferDetailsWidget>
                </Col>
              )}
              {(offerForm.type === 'percentage' || offerForm.type === 'bogo') && (
                <Col lg={3} data-testid="quantity-input-container">
                  <OfferDetailsWidget title="BUY X">
                    <OfferNumberInput
                      data-testid="quantity-input"
                      value={offerForm.quantity}
                      onChange={value => onOfferFormValueChange('quantity', value)}
                    />
                  </OfferDetailsWidget>
                </Col>
              )}
              {offerForm.type === 'bogo' && (
                <Col lg={3} data-testid="items-reward-quantity-input-container">
                  <OfferDetailsWidget title="GET Y">
                    <OfferNumberInput
                      data-testid="item-reward-quantity-input"
                      value={offerForm.itemsRewardQuantity}
                      onChange={value => onOfferFormValueChange('itemsRewardQuantity', value)}
                    />
                  </OfferDetailsWidget>
                </Col>
              )}
            </Row>
            <Row className="mt-5">
              <Col>
                <OfferDetailsWidget title="Title *">
                  <OfferTextInput
                    data-testid="title-input"
                    placeholder="E.g. Get $1.50 Off"
                    value={offerForm.title}
                    onChange={value => onOfferFormValueChange('title', value)}
                  />
                </OfferDetailsWidget>
              </Col>
            </Row>
            <Row className="mt-5">
              <Col>
                <OfferDetailsWidget title="Description *">
                  <OfferTextInput
                    data-testid="description-input"
                    placeholder="E.g. Get $1.50 Off Blueberry yogurt any size"
                    value={offerForm.description}
                    onChange={value => onOfferFormValueChange('description', value)}
                  />
                </OfferDetailsWidget>
              </Col>
            </Row>
            <Row className="mt-5">
              <Col>
                <OfferDetailsWidget title="Purchase Requirement Description *">
                  <OfferTextInput
                    data-testid="purchase-requirement-description-input"
                    value={offerForm.purchaseRequirementDescription}
                    onChange={value =>
                      onOfferFormValueChange('purchaseRequirementDescription', value)
                    }
                  />
                </OfferDetailsWidget>
              </Col>
            </Row>
            <Row className="mt-5">
              <Col>
                <OfferDetailsWidget title="Coupon Legal Copy (optional)">
                  <OfferTextarea
                    data-testid="legal"
                    placeholder={
                      'RETAILER: Awesome Bars, LLC, or an affiliate, will reimburse the face value of this coupon plus handling if submitted in compliance with its Coupon Redemption Policy, available upon request. Cash value 1/100 cent. \n' +
                      'CONSUMER: One coupon valid for item(s) indicated. Limit one coupon per purchase of products and quantities stated. Limit of 4 like coupons in same shopping trip. Consumer pays any sales tax. No cash back if coupon value exceeds retail price. Any other use constitutes fraud. VOID IF ALTERED, COPIED, TRANSFERRED, PURCHASED OR SOLD. Valid only in the USA, FPOs and APOs.'
                    }
                    value={offerForm.legalCopy}
                    onChange={value => onOfferFormValueChange('legalCopy', value)}
                  />
                </OfferDetailsWidget>
              </Col>
            </Row>
            <Row className="mt-5">
              <Col lg={12}>
                <OfferDetailsWidget
                  subTitle={`File must be ${CouponImage.MAX_WIDTH}px x ${
                    CouponImage.MAX_HEIGHT
                  }px, 150dpi, ${CouponImage.ACCEPTED_TYPES.map(type =>
                    type.replace('image/', '')
                  ).join(' or ')}`}
                  title="Campaign Image *"
                >
                  <OfferImageDropZone
                    image={offerForm.campaignImage}
                    onDrop={file => onOfferFormValueChange('campaignImage', file)}
                  />
                </OfferDetailsWidget>
              </Col>
            </Row>
            <Row className="mt-5">
              <Col>
                <OfferDetailsWidget title="Manufacturer Offer ID *">
                  <SingleNumericInput
                    data-testid="manufacturer-offer-id"
                    errorMessage="Manufacturer Offer ID must be 6 digits to be valid."
                    numbersToShow={6}
                    placeholder="170341"
                    value={offerForm.manufacturerId}
                    width={75}
                    onChange={value => onOfferFormValueChange('manufacturerId', value)}
                  />
                </OfferDetailsWidget>
              </Col>
            </Row>
            <Row className="mt-5">
              <Col>
                <OfferDetailsWidget title="Blended GS1 Databar *">
                  <SingleNumericInput
                    data-testid="blended-gs1-databar"
                    errorMessage="Blended GS1 Databar must be 37 digits to be valid."
                    numbersToShow={37}
                    placeholder="3242342345987234987238479823749283"
                    value={offerForm.blendedGS1}
                    width={450}
                    onChange={value => onOfferFormValueChange('blendedGS1', value)}
                  />
                </OfferDetailsWidget>
              </Col>
            </Row>
            <Row className="mt-5">
              <Col>
                <OfferDetailsWidget title="Notes (optional)">
                  <OfferTextInput
                    data-testid="notes-input"
                    placeholder="Enter notes about your campaign"
                    value={offerForm.notes}
                    onChange={value => onOfferFormValueChange('notes', value)}
                  />
                </OfferDetailsWidget>
              </Col>
            </Row>
          </div>
        </Widget.Body>
      </Widget>
    </div>
  );
};

export default BuildOffer;
