import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `AWSURL` scalar type provided by AWS AppSync, represents a valid URL string (Ex: <https://www.amazon.com/>). The URL may use any scheme and may also be a local URL (Ex: <http://localhost/>).  URLs without schemes like "**amazon.com**" or "**www.amazon.com**" are considered invalid. URLs which contain double slashes (two consecutive forward slashes) in their path are also considered invalid. */
  AWSURL: any;
  /** The `AWSDate` scalar type provided by AWS AppSync, represents a valid ***extended*** [ISO 8601 Date](https://en.wikipedia.org/wiki/ISO_8601#Calendar_dates) string. In other words, this scalar type accepts date strings of the form `YYYY-MM-DD`.  The scalar can also accept "negative years" of the form `-YYYY` which correspond to years before `0000`. For example, "**-2017-05-01**" and "**-9999-01-01**" are both valid dates.  This scalar type can also accept an optional [time zone offset](https://en.wikipedia.org/wiki/ISO_8601#Time_zone_designators). For example, "**1970-01-01**", "**1970-01-01Z**", "**1970-01-01-07:00**" and "**1970-01-01+05:30**" are all valid dates. The time zone offset must either be `Z` (representing the UTC time zone) or be in the format `±hh:mm:ss`. The seconds field in the timezone offset will be considered valid even though it is not part of the ISO 8601 standard. */
  AWSDate: any;
  /** The `AWSEmail` scalar type provided by AWS AppSync, represents an Email address string that complies with [RFC 822](https://www.ietf.org/rfc/rfc822.txt). For example, "**username@example.com**" is a valid Email address. */
  AWSEmail: any;
  /** The `AWSPhone` scalar type provided by AWS AppSync, represents a valid Phone Number. Phone numbers are serialized and deserialized as Strings. Segments of the phone number may be whitespace delimited or hyphenated.  The number can specify a country code at the beginning. However, United States numbers without country codes are still considered to be valid. */
  AWSPhone: any;
};

export type Account = {
  __typename?: 'Account';
  name: Scalars['String'];
};

export type AccountList = {
  __typename?: 'AccountList';
  data: Array<Maybe<Account>>;
  page?: Maybe<Page>;
};

export type Address = {
  __typename?: 'Address';
  company: Scalars['String'];
  street: Scalars['String'];
  street2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  state: Scalars['String'];
  zipcode: Scalars['Int'];
  phoneNumber?: Maybe<Scalars['AWSPhone']>;
};

export type AddressInput = {
  company: Scalars['String'];
  street: Scalars['String'];
  street2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  state: Scalars['String'];
  zipcode: Scalars['Int'];
  phoneNumber?: Maybe<Scalars['AWSPhone']>;
};

export enum ApprovalStatus {
  Pending = 'pending',
  Approved = 'approved',
  HoldForPayment = 'holdForPayment'
}

export type Billing = {
  __typename?: 'Billing';
  name?: Maybe<Scalars['String']>;
  phoneNumber: Scalars['AWSPhone'];
  email: Scalars['AWSEmail'];
};

export type BillingInput = {
  name?: Maybe<Scalars['String']>;
  phoneNumber: Scalars['AWSPhone'];
  email: Scalars['AWSEmail'];
};

export type Campaign = {
  __typename?: 'Campaign';
  retailer: Scalars['ID'];
  market: Scalars['ID'];
  retailerDetail?: Maybe<Retailer>;
  startDate: Scalars['AWSDate'];
  expirationDate: Scalars['AWSDate'];
  duration: Scalars['Int'];
  maxClips: Scalars['Int'];
  extendedDays?: Maybe<Scalars['Int']>;
  maxClipsBudget: Scalars['Float'];
  tags?: Maybe<MarketTags>;
  clips?: Maybe<Scalars['Int']>;
  redemptions?: Maybe<Scalars['Int']>;
  redemptionRate?: Maybe<Scalars['Float']>;
};

export type Clearing = {
  __typename?: 'Clearing';
  posRequired: Scalars['Boolean'];
  clearingAgentCompany: Scalars['String'];
  clearingAgentContactName: Scalars['String'];
  clearingAgentEmail: Scalars['AWSEmail'];
  clearingAgentPhoneNumber: Scalars['AWSPhone'];
};

export type ClearingInput = {
  posRequired: Scalars['Boolean'];
  clearingAgentCompany: Scalars['String'];
  clearingAgentContactName: Scalars['String'];
  clearingAgentEmail: Scalars['AWSEmail'];
  clearingAgentPhoneNumber: Scalars['AWSPhone'];
};

export type Client = {
  __typename?: 'Client';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
};

export type Coupon = {
  __typename?: 'Coupon';
  id: Scalars['ID'];
  orderId: Scalars['String'];
  io: Scalars['String'];
  client: Scalars['String'];
  title: Scalars['String'];
  campaigns: Array<Maybe<Campaign>>;
  products: Array<Maybe<Scalars['ID']>>;
  description: Scalars['String'];
  purhcaseRequierementDescription: Scalars['String'];
  offerType: OfferType;
  faceValueOffer: Scalars['Float'];
  maxValueOffer?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Int']>;
  status: OfferStatus;
  approvalStatus: ApprovalStatus;
  notes?: Maybe<Scalars['String']>;
  manufactererOfferId?: Maybe<Scalars['String']>;
  gs1Databar?: Maybe<Scalars['String']>;
  productImageUrl?: Maybe<Scalars['String']>;
  campaignImageUrl?: Maybe<Scalars['String']>;
  createDate?: Maybe<Scalars['AWSDate']>;
  expireDate?: Maybe<Scalars['AWSDate']>;
  legal?: Maybe<Scalars['String']>;
  maxClips?: Maybe<Scalars['Int']>;
  clips?: Maybe<Scalars['Int']>;
  redemptions?: Maybe<Scalars['Int']>;
  redemptionRate?: Maybe<Scalars['Float']>;
  percentageClipsLeft?: Maybe<Scalars['Float']>;
  clipsRemaining?: Maybe<Scalars['Int']>;
  itemsRewardQuantity?: Maybe<Scalars['Int']>;
};

export type CouponDetails = {
  __typename?: 'CouponDetails';
  orderId?: Maybe<Scalars['ID']>;
  image?: Maybe<Scalars['String']>;
  createdDate?: Maybe<Scalars['AWSDate']>;
  numberOfIos?: Maybe<Scalars['Int']>;
  maxClips?: Maybe<Scalars['Int']>;
  clips?: Maybe<Scalars['Int']>;
  redemptions?: Maybe<Scalars['Int']>;
  redemptionRate?: Maybe<Scalars['Float']>;
  ios?: Maybe<Array<Maybe<OrderIo>>>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type CreateCampaignInput = {
  retailer: Scalars['ID'];
  startDate: Scalars['AWSDate'];
  expirationDate: Scalars['AWSDate'];
  duration: Scalars['Int'];
  maxClips: Scalars['Int'];
  maxClipsBudget: Scalars['Float'];
  extendedDays?: Maybe<Scalars['Int']>;
};

export type CreateCouponInput = {
  client: Scalars['ID'];
  title: Scalars['String'];
  campaigns: Array<Maybe<CreateCampaignInput>>;
  products: Array<Maybe<Scalars['ID']>>;
  description: Scalars['String'];
  purhcaseRequierementDescription: Scalars['String'];
  userEmail?: Maybe<Scalars['AWSEmail']>;
  username?: Maybe<Scalars['String']>;
  offerType: OfferType;
  faceValueOffer: Scalars['Float'];
  maxValueOffer?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  manufactererOfferId?: Maybe<Scalars['String']>;
  gs1Databar?: Maybe<Scalars['String']>;
  productImage?: Maybe<ImageInput>;
  campaignImage: ImageInput;
  createDate?: Maybe<Scalars['AWSDate']>;
  expireDate?: Maybe<Scalars['AWSDate']>;
  legal?: Maybe<Scalars['String']>;
  itemsRewardQuantity?: Maybe<Scalars['Int']>;
};

export type CreateProfileInput = {
  client: Scalars['String'];
  billingInfo: BillingInput;
  billingAddress: AddressInput;
  companyAddress: AddressInput;
  PORequirement: Scalars['Boolean'];
  clearingInfo: ClearingInput;
};

export type DeleteCouponInput = {
  id: Scalars['ID'];
};

export type DeleteProfileInput = {
  id: Scalars['ID'];
};

export enum ExportTemplate {
  SignalsReportSpreadsheetReady = 'signalsReportSpreadsheetReady'
}

export enum ExportType {
  Xlsx = 'XLSX'
}

export type FeedPeriod = {
  __typename?: 'FeedPeriod';
  FEED_NAME: Scalars['String'];
  END_PERIOD_DATE: Scalars['String'];
};

export type GetAffiliateLinkInput = {
  retailerId: Scalars['String'];
  subId1: Scalars['String'];
  subId2: Scalars['String'];
  bareUrl: Scalars['String'];
};

export type HeliosProduct = {
  __typename?: 'HeliosProduct';
  name: Scalars['String'];
  upc: Scalars['String'];
};

export type ImageInput = {
  data: Scalars['String'];
  type: ImageType;
};

export enum ImageType {
  Png = 'png',
  Jpg = 'jpg',
  Jpeg = 'jpeg'
}

export type MarketTags = {
  __typename?: 'MarketTags';
  sID?: Maybe<Array<Maybe<Scalars['String']>>>;
  tdLinx?: Maybe<Array<Maybe<Scalars['String']>>>;
  marketTag?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createCoupon?: Maybe<Array<Maybe<Coupon>>>;
  createProfile?: Maybe<Profile>;
  deleteCoupon?: Maybe<Coupon>;
  deleteProfile?: Maybe<Profile>;
  updateApprovalStatus: Coupon;
  updateCoupon?: Maybe<Coupon>;
  updateProductLinks?: Maybe<Scalars['String']>;
  updateProfile?: Maybe<Profile>;
};

export type MutationCreateCouponArgs = {
  input: CreateCouponInput;
};

export type MutationCreateProfileArgs = {
  input: CreateProfileInput;
};

export type MutationDeleteCouponArgs = {
  input: DeleteCouponInput;
};

export type MutationDeleteProfileArgs = {
  input: DeleteProfileInput;
};

export type MutationUpdateApprovalStatusArgs = {
  id: Scalars['ID'];
  approvalStatus: ApprovalStatus;
};

export type MutationUpdateCouponArgs = {
  input: UpdateCouponInput;
};

export type MutationUpdateProductLinksArgs = {
  input: UpdateProductLinkInput;
};

export type MutationUpdateProfileArgs = {
  input: UpdateProfileInput;
};

export enum OfferStatus {
  Pending = 'pending',
  Scheduled = 'scheduled',
  Active = 'active',
  Archived = 'archived',
  HoldForPayment = 'holdForPayment'
}

export enum OfferType {
  DollarDiscount = 'dollarDiscount',
  PercentDiscount = 'percentDiscount',
  Buyxgety = 'buyxgety',
  FreeItem = 'freeItem'
}

export type OrderIo = {
  __typename?: 'OrderIO';
  id?: Maybe<Scalars['ID']>;
  io?: Maybe<Scalars['String']>;
  retailers?: Maybe<Array<Maybe<RetailerName>>>;
  startDate?: Maybe<Scalars['AWSDate']>;
  expirationDate?: Maybe<Scalars['AWSDate']>;
  status?: Maybe<OfferStatus>;
  maxClips?: Maybe<Scalars['Int']>;
  clips?: Maybe<Scalars['Int']>;
  redemptions?: Maybe<Scalars['Int']>;
  redemptionRate?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Int']>;
  extendedDays?: Maybe<Scalars['Int']>;
  maxClipsBudget?: Maybe<Scalars['Float']>;
};

export type Page = {
  __typename?: 'Page';
  totalItems: Scalars['Int'];
  totalPages: Scalars['Int'];
  currentPage: Scalars['Int'];
};

export type PageableInput = {
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  sortedBy?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<SortDirection>;
  search?: Maybe<Scalars['String']>;
  searchBy?: Maybe<Scalars['String']>;
};

export type Product = {
  __typename?: 'Product';
  id: Scalars['ID'];
  name: Scalars['String'];
  /** @deprecated Field is deprecated! */
  description?: Maybe<Scalars['String']>;
  upc?: Maybe<Array<Maybe<Upc>>>;
  /** @deprecated Field is deprecated! */
  status?: Maybe<Status>;
  /** @deprecated Field is deprecated! */
  links?: Maybe<Array<Maybe<Scalars['AWSURL']>>>;
};

export type ProductConnection = {
  __typename?: 'ProductConnection';
  items?: Maybe<Array<Maybe<Product>>>;
  /** @deprecated Field is deprecated! */
  nextToken?: Maybe<Scalars['String']>;
  page?: Maybe<Page>;
};

export type ProductLink = {
  __typename?: 'ProductLink';
  client?: Maybe<Scalars['String']>;
  GTIN?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  retailer_id?: Maybe<Scalars['Int']>;
  retailer_name?: Maybe<Scalars['String']>;
};

export type ProductLinkResponse = {
  __typename?: 'ProductLinkResponse';
  timestamp?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  res?: Maybe<Scalars['Int']>;
};

export type ProductList = {
  __typename?: 'ProductList';
  data: Array<Maybe<HeliosProduct>>;
  page?: Maybe<Page>;
};

export type Profile = {
  __typename?: 'Profile';
  client: Scalars['String'];
  billingInfo: Billing;
  billingAddress: Address;
  companyAddress: Address;
  PORequirement: Scalars['Boolean'];
  clearingInfo: Clearing;
  couponLegalCopy: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  accounts?: Maybe<AccountList>;
  archiveClient?: Maybe<Scalars['String']>;
  batchListProducts?: Maybe<ProductConnection>;
  batchListProductsHelios?: Maybe<ProductConnection>;
  coupons?: Maybe<Array<Maybe<Coupon>>>;
  exportCoupons?: Maybe<Scalars['Boolean']>;
  exportProfiles?: Maybe<Scalars['Boolean']>;
  getAffiliateLink?: Maybe<Scalars['String']>;
  getCoupon?: Maybe<Coupon>;
  getProduct?: Maybe<Product>;
  getProfile?: Maybe<Profile>;
  getStore?: Maybe<Array<Maybe<Store>>>;
  listClients?: Maybe<Array<Maybe<Client>>>;
  listCoupons?: Maybe<Array<Maybe<Coupon>>>;
  listCouponsByOrderId?: Maybe<Array<Maybe<CouponDetails>>>;
  listFeedPeriod?: Maybe<Array<Maybe<FeedPeriod>>>;
  listProducts?: Maybe<ProductConnection>;
  listProductsLinks?: Maybe<Array<Maybe<ProductLink>>>;
  listRetailers?: Maybe<Array<Maybe<Retailer>>>;
  products?: Maybe<ProductList>;
  states?: Maybe<StateList>;
  stores?: Maybe<StoreList>;
  validatePhoneNumber?: Maybe<Scalars['Boolean']>;
};

export type QueryAccountsArgs = {
  client: Scalars['String'];
  brand?: Maybe<Scalars['String']>;
  weeks?: Maybe<Scalars['Int']>;
  accounts?: Maybe<Array<Maybe<Scalars['String']>>>;
  products?: Maybe<Array<Maybe<Scalars['String']>>>;
  stores?: Maybe<Array<Maybe<Scalars['String']>>>;
  states?: Maybe<Array<Maybe<Scalars['String']>>>;
  pageable?: Maybe<PageableInput>;
};

export type QueryArchiveClientArgs = {
  client?: Maybe<Scalars['String']>;
};

export type QueryBatchListProductsArgs = {
  client: Scalars['String'];
  brand?: Maybe<Scalars['String']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  pageable?: Maybe<PageableInput>;
};

export type QueryBatchListProductsHeliosArgs = {
  client: Scalars['String'];
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type QueryCouponsArgs = {
  client: Scalars['String'];
  retailers?: Maybe<Array<Maybe<Scalars['Int']>>>;
  products?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type QueryExportCouponsArgs = {
  client?: Maybe<Scalars['String']>;
  template: ExportTemplate;
  exportType: ExportType;
  recipients: Array<Maybe<Scalars['AWSEmail']>>;
};

export type QueryExportProfilesArgs = {
  template: ExportTemplate;
  exportType: ExportType;
  recipients: Array<Maybe<Scalars['AWSEmail']>>;
};

export type QueryGetAffiliateLinkArgs = {
  input: GetAffiliateLinkInput;
};

export type QueryGetCouponArgs = {
  id: Scalars['ID'];
};

export type QueryGetProductArgs = {
  id: Scalars['ID'];
};

export type QueryGetProfileArgs = {
  client: Scalars['String'];
};

export type QueryGetStoreArgs = {
  client: Scalars['String'];
  spinsTag?: Maybe<Scalars['String']>;
  nielsenTag?: Maybe<Scalars['String']>;
  storeTag?: Maybe<Scalars['String']>;
};

export type QueryListCouponsArgs = {
  filter?: Maybe<TableCouponFilterInput>;
  status?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};

export type QueryListCouponsByOrderIdArgs = {
  filter?: Maybe<TableCouponFilterInput>;
  status?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QueryListFeedPeriodArgs = {
  client: Scalars['String'];
};

export type QueryListProductsArgs = {
  client: Scalars['String'];
  active?: Maybe<Scalars['Boolean']>;
  nextToken?: Maybe<Scalars['String']>;
};

export type QueryListProductsLinksArgs = {
  client?: Maybe<Scalars['String']>;
};

export type QueryProductsArgs = {
  client: Scalars['String'];
  brand?: Maybe<Scalars['String']>;
  weeks?: Maybe<Scalars['Int']>;
  accounts?: Maybe<Array<Maybe<Scalars['String']>>>;
  products?: Maybe<Array<Maybe<Scalars['String']>>>;
  stores?: Maybe<Array<Maybe<Scalars['String']>>>;
  states?: Maybe<Array<Maybe<Scalars['String']>>>;
  pageable?: Maybe<PageableInput>;
};

export type QueryStatesArgs = {
  client: Scalars['String'];
  brand?: Maybe<Scalars['String']>;
  weeks?: Maybe<Scalars['Int']>;
  accounts?: Maybe<Array<Maybe<Scalars['String']>>>;
  products?: Maybe<Array<Maybe<Scalars['String']>>>;
  stores?: Maybe<Array<Maybe<Scalars['String']>>>;
  states?: Maybe<Array<Maybe<Scalars['String']>>>;
  pageable?: Maybe<PageableInput>;
};

export type QueryStoresArgs = {
  client: Scalars['String'];
  brand?: Maybe<Scalars['String']>;
  weeks?: Maybe<Scalars['Int']>;
  accounts?: Maybe<Array<Maybe<Scalars['String']>>>;
  products?: Maybe<Array<Maybe<Scalars['String']>>>;
  stores?: Maybe<Array<Maybe<Scalars['String']>>>;
  states?: Maybe<Array<Maybe<Scalars['String']>>>;
  pageable?: Maybe<PageableInput>;
};

export type QueryValidatePhoneNumberArgs = {
  input: Scalars['AWSPhone'];
};

export type Retailer = {
  __typename?: 'Retailer';
  id: Scalars['ID'];
  parentRetailer?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  markets?: Maybe<Array<Maybe<Retailer>>>;
  storeCount?: Maybe<Scalars['Int']>;
  clipFee?: Maybe<Scalars['Float']>;
  setUpFee?: Maybe<Scalars['Float']>;
  minimumClip?: Maybe<Scalars['Int']>;
  leadTime: Scalars['Int'];
  logo?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type RetailerName = {
  __typename?: 'RetailerName';
  name?: Maybe<Scalars['String']>;
};

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type State = {
  __typename?: 'State';
  code: Scalars['String'];
};

export type StateList = {
  __typename?: 'StateList';
  data: Array<Maybe<State>>;
  page?: Maybe<Page>;
};

export enum Status {
  Active = 'active',
  Inactive = 'inactive',
  Prerelease = 'prerelease',
  Discontinued = 'discontinued'
}

export type Store = {
  __typename?: 'Store';
  name?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  route?: Maybe<Scalars['String']>;
  subpremise?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  locality?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['String']>;
  spinsTag?: Maybe<Scalars['String']>;
  nielsenTag?: Maybe<Scalars['String']>;
  storeTag?: Maybe<Scalars['String']>;
};

export type StoreList = {
  __typename?: 'StoreList';
  data: Array<Maybe<Store>>;
  page?: Maybe<Page>;
};

export type TableBooleanFilterInput = {
  ne?: Maybe<Scalars['Boolean']>;
  eq?: Maybe<Scalars['Boolean']>;
};

export type TableCouponFilterInput = {
  id?: Maybe<TableIdFilterInput>;
  title?: Maybe<TableStringFilterInput>;
  client?: Maybe<TableStringFilterInput>;
  offerType?: Maybe<TableStringFilterInput>;
};

export type TableFloatFilterInput = {
  ne?: Maybe<Scalars['Float']>;
  eq?: Maybe<Scalars['Float']>;
  le?: Maybe<Scalars['Float']>;
  lt?: Maybe<Scalars['Float']>;
  ge?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  contains?: Maybe<Scalars['Float']>;
  notContains?: Maybe<Scalars['Float']>;
  between?: Maybe<Array<Maybe<Scalars['Float']>>>;
};

export type TableIdFilterInput = {
  beginsWith?: Maybe<Scalars['ID']>;
  between?: Maybe<Array<Maybe<Scalars['ID']>>>;
  contains?: Maybe<Scalars['ID']>;
  eq?: Maybe<Scalars['ID']>;
  ge?: Maybe<Scalars['ID']>;
  gt?: Maybe<Scalars['ID']>;
  le?: Maybe<Scalars['ID']>;
  lt?: Maybe<Scalars['ID']>;
  ne?: Maybe<Scalars['ID']>;
  notContains?: Maybe<Scalars['ID']>;
};

export type TableIntFilterInput = {
  ne?: Maybe<Scalars['Int']>;
  eq?: Maybe<Scalars['Int']>;
  le?: Maybe<Scalars['Int']>;
  lt?: Maybe<Scalars['Int']>;
  ge?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  contains?: Maybe<Scalars['Int']>;
  notContains?: Maybe<Scalars['Int']>;
  between?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type TableProductFilterInput = {
  id?: Maybe<TableIdFilterInput>;
  name?: Maybe<TableStringFilterInput>;
  description?: Maybe<TableStringFilterInput>;
  ingredientsList?: Maybe<TableStringFilterInput>;
  msrp?: Maybe<TableFloatFilterInput>;
  quantity?: Maybe<TableFloatFilterInput>;
  size?: Maybe<TableStringFilterInput>;
  brickMortar?: Maybe<TableBooleanFilterInput>;
  onLine?: Maybe<TableBooleanFilterInput>;
};

export type TableStringFilterInput = {
  beginsWith?: Maybe<Scalars['String']>;
  between?: Maybe<Array<Maybe<Scalars['String']>>>;
  contains?: Maybe<Scalars['String']>;
  eq?: Maybe<Scalars['String']>;
  ge?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  le?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  notContains?: Maybe<Scalars['String']>;
};

export type Upc = {
  __typename?: 'UPC';
  id: Scalars['ID'];
  value: Scalars['String'];
  type?: Maybe<UpcType>;
};

export enum UpcType {
  Gtin = 'GTIN',
  Upca = 'UPCA',
  Upce = 'UPCE',
  Ean13 = 'EAN13',
  Ean8 = 'EAN8'
}

export type UpdateCouponInput = {
  id: Scalars['ID'];
  client?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  campaigns?: Maybe<Array<Maybe<CreateCampaignInput>>>;
  products?: Maybe<Array<Maybe<Scalars['ID']>>>;
  description?: Maybe<Scalars['String']>;
  purhcaseRequierementDescription?: Maybe<Scalars['String']>;
  offerType?: Maybe<OfferType>;
  faceValueOffer?: Maybe<Scalars['Float']>;
  maxValueOffer?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  manufactererOfferId?: Maybe<Scalars['String']>;
  gs1Databar?: Maybe<Scalars['String']>;
  productImage?: Maybe<ImageInput>;
  campaignImage?: Maybe<ImageInput>;
  createDate?: Maybe<Scalars['AWSDate']>;
  expireDate?: Maybe<Scalars['AWSDate']>;
  approvalStatus?: Maybe<ApprovalStatus>;
  legal?: Maybe<Scalars['String']>;
  itemsRewardQuantity?: Maybe<Scalars['Int']>;
};

export type UpdateProductLinkInput = {
  clientId: Scalars['String'];
  retailerSite: Scalars['String'];
  retailerId: Scalars['String'];
  upc: Scalars['String'];
  sku: Scalars['String'];
  availability: Scalars['String'];
  affiliateUrl: Scalars['String'];
  bareUrl: Scalars['String'];
  price: Scalars['String'];
  offers: Scalars['String'];
};

export type UpdateProfileInput = {
  client: Scalars['String'];
  billingInfo: BillingInput;
  billingAddress: AddressInput;
  companyAddress: AddressInput;
  PORequirement: Scalars['Boolean'];
  clearingInfo: ClearingInput;
};

export type CreateCouponMutationVariables = Exact<{
  coupon: CreateCouponInput;
}>;

export type CreateCouponMutation = { __typename?: 'Mutation' } & {
  createCoupon?: Maybe<
    Array<
      Maybe<
        { __typename?: 'Coupon' } & Pick<
          Coupon,
          | 'id'
          | 'io'
          | 'orderId'
          | 'title'
          | 'description'
          | 'products'
          | 'createDate'
          | 'status'
          | 'campaignImageUrl'
        > & {
            campaigns: Array<
              Maybe<
                { __typename?: 'Campaign' } & Pick<Campaign, 'retailer' | 'startDate' | 'duration'>
              >
            >;
          }
      >
    >
  >;
};

export type GetCouponQueryVariables = Exact<{
  couponId: Scalars['ID'];
}>;

export type GetCouponQuery = { __typename?: 'Query' } & {
  getCoupon?: Maybe<
    { __typename?: 'Coupon' } & Pick<
      Coupon,
      | 'id'
      | 'io'
      | 'orderId'
      | 'title'
      | 'description'
      | 'products'
      | 'status'
      | 'gs1Databar'
      | 'campaignImageUrl'
      | 'faceValueOffer'
      | 'manufactererOfferId'
      | 'maxValueOffer'
      | 'notes'
      | 'productImageUrl'
      | 'purhcaseRequierementDescription'
      | 'quantity'
      | 'itemsRewardQuantity'
      | 'offerType'
      | 'legal'
      | 'maxClips'
      | 'clips'
      | 'redemptions'
      | 'redemptionRate'
      | 'percentageClipsLeft'
      | 'clipsRemaining'
    > & {
        campaigns: Array<
          Maybe<
            { __typename?: 'Campaign' } & Pick<
              Campaign,
              | 'retailer'
              | 'startDate'
              | 'duration'
              | 'expirationDate'
              | 'extendedDays'
              | 'maxClips'
              | 'maxClipsBudget'
              | 'clips'
              | 'redemptions'
              | 'redemptionRate'
            > & {
                retailerDetail?: Maybe<
                  { __typename?: 'Retailer' } & Pick<
                    Retailer,
                    | 'id'
                    | 'parentRetailer'
                    | 'name'
                    | 'storeCount'
                    | 'clipFee'
                    | 'setUpFee'
                    | 'minimumClip'
                    | 'logo'
                  > & {
                      markets?: Maybe<
                        Array<
                          Maybe<
                            { __typename?: 'Retailer' } & Pick<
                              Retailer,
                              'id' | 'name' | 'storeCount' | 'clipFee' | 'setUpFee' | 'minimumClip'
                            >
                          >
                        >
                      >;
                    }
                >;
              }
          >
        >;
      }
  >;
};

export type GetCouponsQueryVariables = Exact<{
  filter?: Maybe<TableCouponFilterInput>;
  status?: Maybe<Scalars['String']>;
}>;

export type GetCouponsQuery = { __typename?: 'Query' } & {
  listCoupons?: Maybe<
    Array<
      Maybe<
        { __typename?: 'Coupon' } & Pick<
          Coupon,
          | 'id'
          | 'io'
          | 'orderId'
          | 'title'
          | 'description'
          | 'products'
          | 'createDate'
          | 'status'
          | 'campaignImageUrl'
        > & {
            campaigns: Array<
              Maybe<
                { __typename?: 'Campaign' } & Pick<Campaign, 'retailer' | 'startDate' | 'duration'>
              >
            >;
          }
      >
    >
  >;
};

export type GetCouponsByOrderIdQueryVariables = Exact<{
  filter?: Maybe<TableCouponFilterInput>;
  status?: Maybe<Scalars['String']>;
}>;

export type GetCouponsByOrderIdQuery = { __typename?: 'Query' } & {
  listCouponsByOrderId?: Maybe<
    Array<
      Maybe<
        { __typename?: 'CouponDetails' } & Pick<
          CouponDetails,
          | 'orderId'
          | 'title'
          | 'description'
          | 'image'
          | 'numberOfIos'
          | 'clips'
          | 'maxClips'
          | 'redemptions'
          | 'redemptionRate'
          | 'createdDate'
        > & {
            ios?: Maybe<
              Array<
                Maybe<
                  { __typename?: 'OrderIO' } & Pick<
                    OrderIo,
                    | 'id'
                    | 'io'
                    | 'startDate'
                    | 'expirationDate'
                    | 'status'
                    | 'duration'
                    | 'extendedDays'
                    | 'clips'
                    | 'maxClips'
                    | 'maxClipsBudget'
                    | 'redemptionRate'
                    | 'redemptions'
                  > & {
                      retailers?: Maybe<
                        Array<Maybe<{ __typename?: 'RetailerName' } & Pick<RetailerName, 'name'>>>
                      >;
                    }
                >
              >
            >;
          }
      >
    >
  >;
};

export type GetRetailersQueryVariables = Exact<{ [key: string]: never }>;

export type GetRetailersQuery = { __typename?: 'Query' } & {
  listRetailers?: Maybe<
    Array<
      Maybe<
        { __typename?: 'Retailer' } & Pick<
          Retailer,
          | 'id'
          | 'parentRetailer'
          | 'name'
          | 'storeCount'
          | 'clipFee'
          | 'setUpFee'
          | 'minimumClip'
          | 'leadTime'
          | 'logo'
        > & { markets?: Maybe<Array<Maybe<{ __typename?: 'Retailer' } & Pick<Retailer, 'id'>>>> }
      >
    >
  >;
};

export type UpdateCouponMutationVariables = Exact<{
  coupon: UpdateCouponInput;
}>;

export type UpdateCouponMutation = { __typename?: 'Mutation' } & {
  updateCoupon?: Maybe<
    { __typename?: 'Coupon' } & Pick<
      Coupon,
      | 'id'
      | 'io'
      | 'orderId'
      | 'title'
      | 'description'
      | 'products'
      | 'status'
      | 'gs1Databar'
      | 'campaignImageUrl'
      | 'faceValueOffer'
      | 'manufactererOfferId'
      | 'maxValueOffer'
      | 'notes'
      | 'productImageUrl'
      | 'purhcaseRequierementDescription'
      | 'quantity'
      | 'offerType'
      | 'legal'
      | 'createDate'
    > & {
        campaigns: Array<
          Maybe<
            { __typename?: 'Campaign' } & Pick<
              Campaign,
              | 'retailer'
              | 'startDate'
              | 'duration'
              | 'expirationDate'
              | 'extendedDays'
              | 'maxClips'
              | 'maxClipsBudget'
            > & {
                retailerDetail?: Maybe<
                  { __typename?: 'Retailer' } & Pick<
                    Retailer,
                    'id' | 'name' | 'storeCount' | 'clipFee' | 'setUpFee' | 'minimumClip' | 'logo'
                  >
                >;
              }
          >
        >;
      }
  >;
};

export type UpdateApprovalStatusMutationVariables = Exact<{
  id: Scalars['ID'];
  approvalStatus: ApprovalStatus;
}>;

export type UpdateApprovalStatusMutation = { __typename?: 'Mutation' } & {
  updateApprovalStatus: { __typename?: 'Coupon' } & Pick<
    Coupon,
    | 'id'
    | 'io'
    | 'orderId'
    | 'title'
    | 'description'
    | 'products'
    | 'status'
    | 'gs1Databar'
    | 'campaignImageUrl'
    | 'faceValueOffer'
    | 'manufactererOfferId'
    | 'maxValueOffer'
    | 'notes'
    | 'productImageUrl'
    | 'purhcaseRequierementDescription'
    | 'quantity'
    | 'offerType'
    | 'legal'
    | 'createDate'
  > & {
      campaigns: Array<
        Maybe<
          { __typename?: 'Campaign' } & Pick<
            Campaign,
            | 'retailer'
            | 'startDate'
            | 'duration'
            | 'expirationDate'
            | 'extendedDays'
            | 'maxClips'
            | 'maxClipsBudget'
          > & {
              retailerDetail?: Maybe<
                { __typename?: 'Retailer' } & Pick<
                  Retailer,
                  'id' | 'name' | 'storeCount' | 'clipFee' | 'setUpFee' | 'minimumClip' | 'logo'
                >
              >;
            }
        >
      >;
    };
};

export type GetProductsByIdQueryVariables = Exact<{
  clientId: Scalars['String'];
  ids?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
}>;

export type GetProductsByIdQuery = { __typename?: 'Query' } & {
  batchListProductsHelios?: Maybe<
    { __typename?: 'ProductConnection' } & {
      items?: Maybe<
        Array<
          Maybe<
            { __typename?: 'Product' } & Pick<Product, 'id' | 'name'> & {
                upc?: Maybe<Array<Maybe<{ __typename?: 'UPC' } & Pick<Upc, 'value' | 'type'>>>>;
              }
          >
        >
      >;
    }
  >;
};

export type GetProductsQueryVariables = Exact<{
  clientId: Scalars['String'];
}>;

export type GetProductsQuery = { __typename?: 'Query' } & {
  listProducts?: Maybe<
    { __typename?: 'ProductConnection' } & {
      items?: Maybe<
        Array<
          Maybe<
            { __typename?: 'Product' } & Pick<Product, 'id' | 'name'> & {
                upc?: Maybe<Array<Maybe<{ __typename?: 'UPC' } & Pick<Upc, 'value' | 'type'>>>>;
              }
          >
        >
      >;
    }
  >;
};

export type GetProfileQueryVariables = Exact<{
  clientId: Scalars['String'];
}>;

export type GetProfileQuery = { __typename?: 'Query' } & {
  getProfile?: Maybe<
    { __typename?: 'Profile' } & Pick<Profile, 'client'> & {
        companyAddress: { __typename?: 'Address' } & Pick<
          Address,
          'company' | 'street' | 'street2' | 'city' | 'state' | 'zipcode' | 'phoneNumber'
        >;
        billingAddress: { __typename?: 'Address' } & Pick<
          Address,
          'company' | 'street' | 'street2' | 'city' | 'state' | 'zipcode' | 'phoneNumber'
        >;
        billingInfo: { __typename?: 'Billing' } & Pick<Billing, 'name' | 'phoneNumber' | 'email'>;
        clearingInfo: { __typename?: 'Clearing' } & Pick<
          Clearing,
          | 'posRequired'
          | 'clearingAgentContactName'
          | 'clearingAgentCompany'
          | 'clearingAgentEmail'
          | 'clearingAgentPhoneNumber'
        >;
      }
  >;
};

export type CreateProfileMutationVariables = Exact<{
  profile: CreateProfileInput;
}>;

export type CreateProfileMutation = { __typename?: 'Mutation' } & {
  createProfile?: Maybe<{ __typename?: 'Profile' } & Pick<Profile, 'client'>>;
};

export type UpdateProfileMutationVariables = Exact<{
  profile: UpdateProfileInput;
}>;

export type UpdateProfileMutation = { __typename?: 'Mutation' } & {
  updateProfile?: Maybe<{ __typename?: 'Profile' } & Pick<Profile, 'client'>>;
};

export type ValidatePhoneNumberQueryVariables = Exact<{
  phoneNumber: Scalars['AWSPhone'];
}>;

export type ValidatePhoneNumberQuery = { __typename?: 'Query' } & Pick<
  Query,
  'validatePhoneNumber'
>;

export const CreateCouponDocument = gql`
  mutation CreateCoupon($coupon: CreateCouponInput!) {
    createCoupon(input: $coupon) {
      id
      io
      orderId
      title
      description
      products
      createDate
      status
      campaignImageUrl
      campaigns {
        retailer
        startDate
        duration
      }
    }
  }
`;
export type CreateCouponMutationFn = Apollo.MutationFunction<
  CreateCouponMutation,
  CreateCouponMutationVariables
>;

/**
 * __useCreateCouponMutation__
 *
 * To run a mutation, you first call `useCreateCouponMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCouponMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCouponMutation, { data, loading, error }] = useCreateCouponMutation({
 *   variables: {
 *      coupon: // value for 'coupon'
 *   },
 * });
 */
export function useCreateCouponMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateCouponMutation, CreateCouponMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateCouponMutation, CreateCouponMutationVariables>(
    CreateCouponDocument,
    options
  );
}
export type CreateCouponMutationHookResult = ReturnType<typeof useCreateCouponMutation>;
export type CreateCouponMutationResult = Apollo.MutationResult<CreateCouponMutation>;
export type CreateCouponMutationOptions = Apollo.BaseMutationOptions<
  CreateCouponMutation,
  CreateCouponMutationVariables
>;
export const GetCouponDocument = gql`
  query GetCoupon($couponId: ID!) {
    getCoupon(id: $couponId) {
      id
      io
      orderId
      title
      description
      products
      status
      gs1Databar
      campaignImageUrl
      faceValueOffer
      manufactererOfferId
      maxValueOffer
      notes
      productImageUrl
      purhcaseRequierementDescription
      quantity
      itemsRewardQuantity
      offerType
      legal
      maxClips
      clips
      redemptions
      redemptionRate
      percentageClipsLeft
      clipsRemaining
      campaigns {
        retailer
        retailerDetail {
          id
          parentRetailer
          name
          storeCount
          clipFee
          setUpFee
          minimumClip
          markets {
            id
            name
            storeCount
            clipFee
            setUpFee
            minimumClip
          }
          logo
        }
        startDate
        duration
        expirationDate
        extendedDays
        maxClips
        maxClipsBudget
        clips
        redemptions
        redemptionRate
      }
    }
  }
`;

/**
 * __useGetCouponQuery__
 *
 * To run a query within a React component, call `useGetCouponQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCouponQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCouponQuery({
 *   variables: {
 *      couponId: // value for 'couponId'
 *   },
 * });
 */
export function useGetCouponQuery(
  baseOptions: Apollo.QueryHookOptions<GetCouponQuery, GetCouponQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCouponQuery, GetCouponQueryVariables>(GetCouponDocument, options);
}
export function useGetCouponLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCouponQuery, GetCouponQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCouponQuery, GetCouponQueryVariables>(GetCouponDocument, options);
}
export type GetCouponQueryHookResult = ReturnType<typeof useGetCouponQuery>;
export type GetCouponLazyQueryHookResult = ReturnType<typeof useGetCouponLazyQuery>;
export type GetCouponQueryResult = Apollo.QueryResult<GetCouponQuery, GetCouponQueryVariables>;
export const GetCouponsDocument = gql`
  query GetCoupons($filter: TableCouponFilterInput = null, $status: String) {
    listCoupons(filter: $filter, status: $status) {
      id
      io
      orderId
      title
      description
      products
      createDate
      status
      campaignImageUrl
      campaigns {
        retailer
        startDate
        duration
      }
    }
  }
`;

/**
 * __useGetCouponsQuery__
 *
 * To run a query within a React component, call `useGetCouponsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCouponsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCouponsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useGetCouponsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCouponsQuery, GetCouponsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCouponsQuery, GetCouponsQueryVariables>(GetCouponsDocument, options);
}
export function useGetCouponsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCouponsQuery, GetCouponsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCouponsQuery, GetCouponsQueryVariables>(
    GetCouponsDocument,
    options
  );
}
export type GetCouponsQueryHookResult = ReturnType<typeof useGetCouponsQuery>;
export type GetCouponsLazyQueryHookResult = ReturnType<typeof useGetCouponsLazyQuery>;
export type GetCouponsQueryResult = Apollo.QueryResult<GetCouponsQuery, GetCouponsQueryVariables>;
export const GetCouponsByOrderIdDocument = gql`
  query GetCouponsByOrderId($filter: TableCouponFilterInput = null, $status: String) {
    listCouponsByOrderId(filter: $filter, status: $status) {
      orderId
      title
      description
      image
      numberOfIos
      ios {
        id
        io
        retailers {
          name
        }
        startDate
        expirationDate
        status
        duration
        extendedDays
        clips
        maxClips
        maxClipsBudget
        redemptionRate
        redemptions
      }
      clips
      maxClips
      redemptions
      redemptionRate
      createdDate
    }
  }
`;

/**
 * __useGetCouponsByOrderIdQuery__
 *
 * To run a query within a React component, call `useGetCouponsByOrderIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCouponsByOrderIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCouponsByOrderIdQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useGetCouponsByOrderIdQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCouponsByOrderIdQuery, GetCouponsByOrderIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCouponsByOrderIdQuery, GetCouponsByOrderIdQueryVariables>(
    GetCouponsByOrderIdDocument,
    options
  );
}
export function useGetCouponsByOrderIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCouponsByOrderIdQuery,
    GetCouponsByOrderIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCouponsByOrderIdQuery, GetCouponsByOrderIdQueryVariables>(
    GetCouponsByOrderIdDocument,
    options
  );
}
export type GetCouponsByOrderIdQueryHookResult = ReturnType<typeof useGetCouponsByOrderIdQuery>;
export type GetCouponsByOrderIdLazyQueryHookResult = ReturnType<
  typeof useGetCouponsByOrderIdLazyQuery
>;
export type GetCouponsByOrderIdQueryResult = Apollo.QueryResult<
  GetCouponsByOrderIdQuery,
  GetCouponsByOrderIdQueryVariables
>;
export const GetRetailersDocument = gql`
  query GetRetailers {
    listRetailers {
      id
      parentRetailer
      name
      storeCount
      clipFee
      setUpFee
      minimumClip
      leadTime
      markets {
        id
      }
      logo
    }
  }
`;

/**
 * __useGetRetailersQuery__
 *
 * To run a query within a React component, call `useGetRetailersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRetailersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRetailersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRetailersQuery(
  baseOptions?: Apollo.QueryHookOptions<GetRetailersQuery, GetRetailersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRetailersQuery, GetRetailersQueryVariables>(
    GetRetailersDocument,
    options
  );
}
export function useGetRetailersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetRetailersQuery, GetRetailersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRetailersQuery, GetRetailersQueryVariables>(
    GetRetailersDocument,
    options
  );
}
export type GetRetailersQueryHookResult = ReturnType<typeof useGetRetailersQuery>;
export type GetRetailersLazyQueryHookResult = ReturnType<typeof useGetRetailersLazyQuery>;
export type GetRetailersQueryResult = Apollo.QueryResult<
  GetRetailersQuery,
  GetRetailersQueryVariables
>;
export const UpdateCouponDocument = gql`
  mutation UpdateCoupon($coupon: UpdateCouponInput!) {
    updateCoupon(input: $coupon) {
      id
      io
      orderId
      title
      description
      products
      status
      gs1Databar
      campaignImageUrl
      faceValueOffer
      manufactererOfferId
      maxValueOffer
      notes
      productImageUrl
      purhcaseRequierementDescription
      quantity
      offerType
      legal
      createDate
      campaigns {
        retailer
        retailerDetail {
          id
          name
          storeCount
          clipFee
          setUpFee
          minimumClip
          logo
        }
        startDate
        duration
        expirationDate
        extendedDays
        maxClips
        maxClipsBudget
      }
    }
  }
`;
export type UpdateCouponMutationFn = Apollo.MutationFunction<
  UpdateCouponMutation,
  UpdateCouponMutationVariables
>;

/**
 * __useUpdateCouponMutation__
 *
 * To run a mutation, you first call `useUpdateCouponMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCouponMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCouponMutation, { data, loading, error }] = useUpdateCouponMutation({
 *   variables: {
 *      coupon: // value for 'coupon'
 *   },
 * });
 */
export function useUpdateCouponMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateCouponMutation, UpdateCouponMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateCouponMutation, UpdateCouponMutationVariables>(
    UpdateCouponDocument,
    options
  );
}
export type UpdateCouponMutationHookResult = ReturnType<typeof useUpdateCouponMutation>;
export type UpdateCouponMutationResult = Apollo.MutationResult<UpdateCouponMutation>;
export type UpdateCouponMutationOptions = Apollo.BaseMutationOptions<
  UpdateCouponMutation,
  UpdateCouponMutationVariables
>;
export const UpdateApprovalStatusDocument = gql`
  mutation UpdateApprovalStatus($id: ID!, $approvalStatus: ApprovalStatus!) {
    updateApprovalStatus(id: $id, approvalStatus: $approvalStatus) {
      id
      io
      orderId
      title
      description
      products
      status
      gs1Databar
      campaignImageUrl
      faceValueOffer
      manufactererOfferId
      maxValueOffer
      notes
      productImageUrl
      purhcaseRequierementDescription
      quantity
      offerType
      legal
      createDate
      campaigns {
        retailer
        retailerDetail {
          id
          name
          storeCount
          clipFee
          setUpFee
          minimumClip
          logo
        }
        startDate
        duration
        expirationDate
        extendedDays
        maxClips
        maxClipsBudget
      }
    }
  }
`;
export type UpdateApprovalStatusMutationFn = Apollo.MutationFunction<
  UpdateApprovalStatusMutation,
  UpdateApprovalStatusMutationVariables
>;

/**
 * __useUpdateApprovalStatusMutation__
 *
 * To run a mutation, you first call `useUpdateApprovalStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApprovalStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApprovalStatusMutation, { data, loading, error }] = useUpdateApprovalStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      approvalStatus: // value for 'approvalStatus'
 *   },
 * });
 */
export function useUpdateApprovalStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateApprovalStatusMutation,
    UpdateApprovalStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateApprovalStatusMutation, UpdateApprovalStatusMutationVariables>(
    UpdateApprovalStatusDocument,
    options
  );
}
export type UpdateApprovalStatusMutationHookResult = ReturnType<
  typeof useUpdateApprovalStatusMutation
>;
export type UpdateApprovalStatusMutationResult = Apollo.MutationResult<
  UpdateApprovalStatusMutation
>;
export type UpdateApprovalStatusMutationOptions = Apollo.BaseMutationOptions<
  UpdateApprovalStatusMutation,
  UpdateApprovalStatusMutationVariables
>;
export const GetProductsByIdDocument = gql`
  query GetProductsById($clientId: String!, $ids: [ID]) {
    batchListProductsHelios(client: $clientId, ids: $ids) {
      items {
        id
        name
        upc {
          value
          type
        }
      }
    }
  }
`;

/**
 * __useGetProductsByIdQuery__
 *
 * To run a query within a React component, call `useGetProductsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductsByIdQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetProductsByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetProductsByIdQuery, GetProductsByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProductsByIdQuery, GetProductsByIdQueryVariables>(
    GetProductsByIdDocument,
    options
  );
}
export function useGetProductsByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetProductsByIdQuery, GetProductsByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProductsByIdQuery, GetProductsByIdQueryVariables>(
    GetProductsByIdDocument,
    options
  );
}
export type GetProductsByIdQueryHookResult = ReturnType<typeof useGetProductsByIdQuery>;
export type GetProductsByIdLazyQueryHookResult = ReturnType<typeof useGetProductsByIdLazyQuery>;
export type GetProductsByIdQueryResult = Apollo.QueryResult<
  GetProductsByIdQuery,
  GetProductsByIdQueryVariables
>;
export const GetProductsDocument = gql`
  query GetProducts($clientId: String!) {
    listProducts(client: $clientId) {
      items {
        id
        name
        upc {
          value
          type
        }
      }
    }
  }
`;

/**
 * __useGetProductsQuery__
 *
 * To run a query within a React component, call `useGetProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductsQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useGetProductsQuery(
  baseOptions: Apollo.QueryHookOptions<GetProductsQuery, GetProductsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProductsQuery, GetProductsQueryVariables>(GetProductsDocument, options);
}
export function useGetProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetProductsQuery, GetProductsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProductsQuery, GetProductsQueryVariables>(
    GetProductsDocument,
    options
  );
}
export type GetProductsQueryHookResult = ReturnType<typeof useGetProductsQuery>;
export type GetProductsLazyQueryHookResult = ReturnType<typeof useGetProductsLazyQuery>;
export type GetProductsQueryResult = Apollo.QueryResult<
  GetProductsQuery,
  GetProductsQueryVariables
>;
export const GetProfileDocument = gql`
  query GetProfile($clientId: String!) {
    getProfile(client: $clientId) {
      client
      companyAddress {
        company
        street
        street2
        city
        state
        zipcode
        phoneNumber
      }
      billingAddress {
        company
        street
        street2
        city
        state
        zipcode
        phoneNumber
      }
      billingInfo {
        name
        phoneNumber
        email
      }
      clearingInfo {
        posRequired
        clearingAgentContactName
        clearingAgentCompany
        clearingAgentEmail
        clearingAgentPhoneNumber
      }
    }
  }
`;

/**
 * __useGetProfileQuery__
 *
 * To run a query within a React component, call `useGetProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useGetProfileQuery(
  baseOptions: Apollo.QueryHookOptions<GetProfileQuery, GetProfileQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProfileQuery, GetProfileQueryVariables>(GetProfileDocument, options);
}
export function useGetProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetProfileQuery, GetProfileQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProfileQuery, GetProfileQueryVariables>(
    GetProfileDocument,
    options
  );
}
export type GetProfileQueryHookResult = ReturnType<typeof useGetProfileQuery>;
export type GetProfileLazyQueryHookResult = ReturnType<typeof useGetProfileLazyQuery>;
export type GetProfileQueryResult = Apollo.QueryResult<GetProfileQuery, GetProfileQueryVariables>;
export const CreateProfileDocument = gql`
  mutation CreateProfile($profile: CreateProfileInput!) {
    createProfile(input: $profile) {
      client
    }
  }
`;
export type CreateProfileMutationFn = Apollo.MutationFunction<
  CreateProfileMutation,
  CreateProfileMutationVariables
>;

/**
 * __useCreateProfileMutation__
 *
 * To run a mutation, you first call `useCreateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProfileMutation, { data, loading, error }] = useCreateProfileMutation({
 *   variables: {
 *      profile: // value for 'profile'
 *   },
 * });
 */
export function useCreateProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateProfileMutation, CreateProfileMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateProfileMutation, CreateProfileMutationVariables>(
    CreateProfileDocument,
    options
  );
}
export type CreateProfileMutationHookResult = ReturnType<typeof useCreateProfileMutation>;
export type CreateProfileMutationResult = Apollo.MutationResult<CreateProfileMutation>;
export type CreateProfileMutationOptions = Apollo.BaseMutationOptions<
  CreateProfileMutation,
  CreateProfileMutationVariables
>;
export const UpdateProfileDocument = gql`
  mutation UpdateProfile($profile: UpdateProfileInput!) {
    updateProfile(input: $profile) {
      client
    }
  }
`;
export type UpdateProfileMutationFn = Apollo.MutationFunction<
  UpdateProfileMutation,
  UpdateProfileMutationVariables
>;

/**
 * __useUpdateProfileMutation__
 *
 * To run a mutation, you first call `useUpdateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileMutation, { data, loading, error }] = useUpdateProfileMutation({
 *   variables: {
 *      profile: // value for 'profile'
 *   },
 * });
 */
export function useUpdateProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateProfileMutation, UpdateProfileMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateProfileMutation, UpdateProfileMutationVariables>(
    UpdateProfileDocument,
    options
  );
}
export type UpdateProfileMutationHookResult = ReturnType<typeof useUpdateProfileMutation>;
export type UpdateProfileMutationResult = Apollo.MutationResult<UpdateProfileMutation>;
export type UpdateProfileMutationOptions = Apollo.BaseMutationOptions<
  UpdateProfileMutation,
  UpdateProfileMutationVariables
>;
export const ValidatePhoneNumberDocument = gql`
  query ValidatePhoneNumber($phoneNumber: AWSPhone!) {
    validatePhoneNumber(input: $phoneNumber)
  }
`;

/**
 * __useValidatePhoneNumberQuery__
 *
 * To run a query within a React component, call `useValidatePhoneNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidatePhoneNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidatePhoneNumberQuery({
 *   variables: {
 *      phoneNumber: // value for 'phoneNumber'
 *   },
 * });
 */
export function useValidatePhoneNumberQuery(
  baseOptions: Apollo.QueryHookOptions<ValidatePhoneNumberQuery, ValidatePhoneNumberQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ValidatePhoneNumberQuery, ValidatePhoneNumberQueryVariables>(
    ValidatePhoneNumberDocument,
    options
  );
}
export function useValidatePhoneNumberLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ValidatePhoneNumberQuery,
    ValidatePhoneNumberQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ValidatePhoneNumberQuery, ValidatePhoneNumberQueryVariables>(
    ValidatePhoneNumberDocument,
    options
  );
}
export type ValidatePhoneNumberQueryHookResult = ReturnType<typeof useValidatePhoneNumberQuery>;
export type ValidatePhoneNumberLazyQueryHookResult = ReturnType<
  typeof useValidatePhoneNumberLazyQuery
>;
export type ValidatePhoneNumberQueryResult = Apollo.QueryResult<
  ValidatePhoneNumberQuery,
  ValidatePhoneNumberQueryVariables
>;
