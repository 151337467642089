import React from 'react';
import './styles.scss';

interface ItemProps {
  value: string;
}

const Item: React.FC<ItemProps> = ({ value }) => (
  <div className="item">
    <div className="value">{value || '-'}</div>
  </div>
);

interface Props {
  title?: string;
  description?: string;
  requirements?: string;
  image?: any;
}

const CouponPreview: React.FC<Props> = ({ title, description, requirements, image = null }) => {
  let img: any;

  if (image !== null) {
    img = <img src={URL.createObjectURL(image)} alt="preview" />;
  } else {
    img = <Item value="-" />;
  }

  return (
    <div className="coupon-preview">
      <Item value={title} />
      <Item value={description} />
      <Item value={requirements} />
      {img}
    </div>
  );
};

export default CouponPreview;
