import React from 'react';
import 'components/CouponCreation/OfferInput/styles.scss';

interface Props {
  disabled?: boolean;
  placeholder?: string;
  value: string;
  onChange: any;
}

const OfferTextInput: React.FC<Props> = ({
  disabled = false,
  placeholder,
  value,
  onChange,
  ...props
}) => (
  <div className="offer-input">
    <input
      {...props}
      disabled={disabled}
      placeholder={placeholder}
      value={value}
      type="text"
      onChange={event => onChange(event.target.value)}
    />
  </div>
);

export default OfferTextInput;
