import React, { useState } from 'react';
import { SearchInput } from '@spins/react-ui-library';
import { Col } from 'react-bootstrap';
import _ from 'lodash';
import Checkbox from 'components/Checkbox';
import PaginatorWrapper from 'components/PaginatorWrapper';
import Table from 'react-bootstrap/Table';
import TableHeader from 'components/Table/Header';
import Widget from 'components/Widget';

import './styles.scss';

type Product = {
  id: string;
  name: string;
  upc: string;
};

interface Props {
  products: Array<Product>;
  selectedProductsIds?: Array<string>;
  onAllClick(productsIds: Array<string>): void;
  onProductClick: (string) => void;
}

const PRODUCTS_PER_PAGE = 15;

const ProductsSelectionWidget: React.FC<Props> = ({
  products,
  selectedProductsIds = [],
  onAllClick,
  onProductClick
}) => {
  const [currentSortHeader, setCurrentSortHeader] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [sortingAsc, setSortingAsc] = useState(null);

  const onSort = (key, ascending) => {
    setCurrentSortHeader(key);
    setSortingAsc(ascending);
  };

  let filteredProducts: Array<Product> = products.filter(
    product =>
      searchText === '' ||
      product.upc.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ||
      product.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1
  );

  const intersection = _.intersection(
    filteredProducts.map(product => product.id),
    selectedProductsIds
  );
  const allSelected =
    selectedProductsIds.length > 0 && filteredProducts.length === intersection.length;

  // Sort products
  filteredProducts = _.orderBy(filteredProducts, currentSortHeader, sortingAsc ? 'asc' : 'desc');

  const isChecked = product => {
    return (
      selectedProductsIds.filter(id => {
        return id === product.id;
      }).length > 0
    );
  };

  return (
    <div className="products-selection-widget">
      <Widget>
        <Widget.Header>
          <Col>
            <div className="title">{`Products (${filteredProducts.length})`}</div>
          </Col>
          <Col>
            <div className="search">
              <SearchInput onChange={value => setSearchText(value)} />
            </div>
          </Col>
          <Col />
        </Widget.Header>
        <Widget.Body>
          <PaginatorWrapper perPage={PRODUCTS_PER_PAGE} total={filteredProducts.length}>
            {(indexStart, indexEnd) => (
              <Table className="products-selection-table">
                <colgroup>
                  <col />
                  <col />
                  <col />
                </colgroup>
                <thead>
                  <tr>
                    <th>
                      <Checkbox
                        id="product-selection-all"
                        data-testid="product-selection-all"
                        disabled={filteredProducts.length === 0}
                        checked={allSelected}
                        onChange={() => onAllClick(filteredProducts.map(product => product.id))}
                      />
                    </th>
                    <TableHeader
                      ascending={currentSortHeader === 'upc' ? sortingAsc : null}
                      id="upc"
                      title="UPC"
                      onSort={onSort}
                    />
                    <TableHeader
                      ascending={currentSortHeader === 'name' ? sortingAsc : null}
                      id="name"
                      title="Name"
                      onSort={onSort}
                    />
                  </tr>
                </thead>
                <tbody>
                  {filteredProducts.slice(indexStart, indexEnd).map(product => (
                    <tr key={product.id}>
                      <td>
                        <Checkbox
                          id={product.id}
                          data-testid={product.id}
                          checked={allSelected || isChecked(product)}
                          onChange={onProductClick}
                        />
                      </td>
                      <td>{product.upc}</td>
                      <td>{product.name}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          </PaginatorWrapper>
        </Widget.Body>
      </Widget>
    </div>
  );
};

export default ProductsSelectionWidget;
