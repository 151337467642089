import React, { useState } from 'react';
import { slide as Menu } from 'react-burger-menu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/Button';
import classNames from 'classnames';
import parse from 'html-react-parser';
import { HamburgerMenuType } from 'utils/types';
import './styles.scss';

interface Props {
  items: Array<HamburgerMenuType>;
}

const HamburgerMenu: React.FC<Props> = ({ items }) => {
  const [show, setShow] = useState(false);

  const handleStateChange = state => {
    if (!state.isOpen) {
      setShow(false);
    }
  };

  return (
    <div className="hamburger-menu">
      <Menu
        customBurgerIcon={false}
        customCrossIcon={false}
        disableAutoFocus
        isOpen={show}
        width={250}
        onStateChange={handleStateChange}
      >
        <div className="menu-item destini-logo">
          <span className="icon-destiniLogo" />
        </div>
        {items.map((item: HamburgerMenuType, index) => {
          const menuItemClass = classNames({
            'menu-item': true,
            selected: item.selected
          });
          return (
            <a className={menuItemClass} key={index} href={item.url}>
              <div className="menu-item-selector" />
              <div className="menu-item-icon">{item.icon && parse(item.icon)}</div>
              <div className="menu-item-name">{item.name}</div>
            </a>
          );
        })}
      </Menu>
      <Button data-testid="hamburger-menu-button" onClick={() => setShow(!show)}>
        <FontAwesomeIcon className="icon" icon={faBars} color="#FFFFFF" />
      </Button>
    </div>
  );
};

export default HamburgerMenu;
