import React, { useState } from 'react';
import moment from 'moment';
import Widget from 'components/Widget';
import { RetailerCampaigns, RetailerType } from 'utils/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import ConfirmationModal from 'components/ConfirmationModal';
import RetailerDetailsRow from 'components/RetailerDetailsWidget/RetailerDetailsRow';
import './styles.scss';

const InfoTooltip = ({ id, text }) => (
  <OverlayTrigger
    placement="top"
    overlay={
      <Tooltip id={id} style={{ fontSize: '12px' }}>
        {text}
      </Tooltip>
    }
  >
    <FontAwesomeIcon icon={faInfoCircle} />
  </OverlayTrigger>
);

const getStartDate = (retailerCampaigns, userRetailerLeadTime) => {
  let startDate = null;
  const { campaigns } = retailerCampaigns;
  if (campaigns.length > 1) {
    const expDate = campaigns[campaigns.length - 2].expiration;
    if (expDate !== null) {
      startDate = moment(expDate).add(1, 'day');
    } else {
      startDate = moment();
    }
  } else if (userRetailerLeadTime) {
    startDate = moment().add(retailerCampaigns.retailer.leadTimeInDays, 'day');
  }
  return startDate;
};

const canAdd = retailerCampaigns => {
  const { campaigns } = retailerCampaigns;
  const expDate = campaigns[campaigns.length - 1].expiration;
  return expDate !== null;
};

interface Props {
  currentUserIsAdmin?: boolean;
  retailerCampaigns: RetailerCampaigns;
  parentRetailerName?: string;
  readOnly?: boolean;
  onCampaignAdd?(retailer: RetailerType): void;
  onCampaignRemove?(retailerId: string, index: number): void;
  onRetailerRemove?(retailer: RetailerType): void;
  onValueChange?(index: number, attribute: string, value: any): any;
}

const RetailerDetailsWidget: React.FC<Props> = ({
  currentUserIsAdmin = false,
  readOnly = false,
  retailerCampaigns,
  parentRetailerName = null,
  onCampaignAdd,
  onCampaignRemove,
  onRetailerRemove,
  onValueChange
}) => {
  let {
    retailer: { name, logo }
  } = retailerCampaigns;

  if (parentRetailerName !== null) {
    name = `${parentRetailerName} - ${name}`;
  }

  const [showConfirmation, setShowConfirmation] = useState(false);
  const startDate = getStartDate(retailerCampaigns, !currentUserIsAdmin);

  const handleRemoveConfirmation = () => {
    setShowConfirmation(false);
    onRetailerRemove(retailerCampaigns.retailer);
  };

  return (
    <div className="retailer-campaign-details-widget">
      <Widget>
        <Widget.Header>
          {logo ? (
            <div className="logo-container">
              <Image src={logo} alt="logo" fluid />
            </div>
          ) : (
            ''
          )}
          <div className="title">{name}</div>
          {!readOnly &&
            (canAdd(retailerCampaigns) ? (
              <div className="push-right">
                {onCampaignAdd && (
                  <Button
                    className="btn-add push-right"
                    onClick={() => onCampaignAdd(retailerCampaigns.retailer)}
                  >
                    +
                  </Button>
                )}
                {onRetailerRemove && (
                  <Button className="btn-add push-right" onClick={() => setShowConfirmation(true)}>
                    <FontAwesomeIcon icon={faTrashAlt} size="lg" />
                  </Button>
                )}
              </div>
            ) : (
              <Button className="btn-add-disabled push-right">+</Button>
            ))}
        </Widget.Header>
        <Widget.Body>
          <div className="details-row details-header">
            <div>
              <span>Campaign Start Date</span>
              <InfoTooltip
                id="tooltip-start-date"
                text="This is when you want your deal to be exposed to consumers in the retailer's app to be clipped"
              />
            </div>
            <div>
              <span>Campaign Duration</span>
              <InfoTooltip
                id="tooltip-duration"
                text="Duration is the length of time you want this deal to be exposed to consumers at this retailer so they can clip and add this deal to their accounts"
              />
            </div>
            <div>
              <span>Expiration</span>
              <InfoTooltip
                id="tooltip-expiration"
                text="This is the date when you no longer will allow consumers to be able to clip and add this deal to their shopping cart in the retailer's app"
              />
            </div>
            <div>
              <span>Extend Redemption</span>
              <InfoTooltip
                id="tooltip-redemption"
                text="While consumers will no longer be able to add this deal to their shopping cart, this extension will maximize consumer conversion rate on this campaign"
              />
            </div>
            <div>
              <span>Max Clips</span>
              <InfoTooltip
                id="tooltip-max-clips"
                text="Maximum clips is the total number of maximum number times you will allow this deal to be clipped and added to consumer's digital shopping baskets at this retailer in total"
              />
            </div>
            <div>
              <span>Clip Budget</span>
              <InfoTooltip
                id="tooltip-max-clip-budget"
                text="The maximum spend for this retailer based on the number of clips multiplied by the per clip cost"
              />
            </div>
            <div />
          </div>
          {retailerCampaigns.campaigns.map((campaign, index) => (
            <RetailerDetailsRow
              key={index}
              campaign={campaign}
              currentUserIsAdmin={currentUserIsAdmin}
              index={index}
              readOnly={readOnly}
              retailerCampaigns={retailerCampaigns}
              startDate={startDate}
              onCampaignRemove={onCampaignRemove}
              onValueChange={onValueChange}
            />
          ))}
        </Widget.Body>
      </Widget>
      <ConfirmationModal
        message="Are you sure you want to remove the retailer?"
        show={showConfirmation}
        onCancel={() => setShowConfirmation(false)}
        onOk={handleRemoveConfirmation}
      />
    </div>
  );
};

export default RetailerDetailsWidget;
