import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTags } from '@fortawesome/free-solid-svg-icons';
import './styles.scss';

const CouponsEmptyList: React.FC = () => (
  <div className="coupons-empty-list">
    <div className="circle">
      <FontAwesomeIcon icon={faTags} size="3x" />
    </div>
    <div className="title-1">No Digital Coupons Created Yet</div>
    <div className="title-2">
      Set up your entire year's worth of digital coupon campaigns across 60+ retailers. Let's get
      started!
    </div>
  </div>
);

export default CouponsEmptyList;
