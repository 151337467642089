import React from 'react';
import './styles.scss';

type Option = { label: string; id: number };

interface Props {
  options: Array<Option>;
  orientation?: 'horizontal' | 'vertical';
  value?: number | string;
  onChange: any;
}

const handleChange = (e, id, onChange) => {
  e.stopPropagation();
  onChange(id);
};

const RadioButton: React.FC<Props> = ({
  options,
  orientation = 'vertical',
  value = null,
  onChange
}) => {
  return (
    <div className={`radio-buttons ${orientation}`}>
      {options.map(option => {
        const checked = option.id === value;
        const labelClassName = ['radio-button'];
        if (checked) {
          labelClassName.push('checked');
        }
        return (
          <label
            key={option.id}
            htmlFor={option.id.toString()}
            className={labelClassName.join(' ')}
          >
            {option.label}
            <input
              id={option.id.toString()}
              type="checkbox"
              checked={checked}
              onChange={e => handleChange(e, option.id, onChange)}
            />
            <span className="checkmark" />
          </label>
        );
      })}
    </div>
  );
};

export default RadioButton;
