import { HamburgerMenuType } from 'utils/types';

export const getBearerGrant = () => {
  return process.env.REACT_APP_BEARER_GRANT;
};

export const getBaseCmsUrl = () => {
  return `${process.env.REACT_APP_CMS_URL}`;
};

export const getChangeBrandUrl = () => {
  return `${getBaseCmsUrl()}/client/changeBrand`;
};

export const getCmsLoginUrl = () => {
  return `${getBaseCmsUrl()}/client/login`;
};

export const getCmsLogoutUrl = () => {
  return `${getBaseCmsUrl()}/client/logout`;
};

export const mapHamburgerMenuItems = (
  activeClient: any,
  baseUrl: string = ''
): Array<HamburgerMenuType> => {
  let result = [];
  try {
    const availableProjects = activeClient.projects.filter(project => project.is_visible === '1');
    result = availableProjects.map(project => {
      const result = {
        name: project.project_name,
        icon: project.icon_html,
        selected: project.project_id === '9', // Coupons, hardcoded, talked with Alex Nikitin
        url: null
      };
      const leftMenu = project.project_navigation.find(
        projectNavigation => projectNavigation.title === 'left_menu'
      );
      if (leftMenu) {
        const child = leftMenu.childs.find(child => child.is_default);
        if (child) {
          if (child.url.startsWith('/')) {
            result.url = `${baseUrl}${child.url}`;
          } else {
            result.url = child.url;
          }
        }
      }
      return result;
    });
  } catch (e) {
    console.log(e);
  }
  return result;
};
