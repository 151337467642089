import React from 'react';
import { ProgressBar } from 'react-bootstrap';
import Widget from 'components/Widget';
import Formats from 'utils/formats';

import './styles.scss';

interface Props {
  redemptionRate: number | null;
  clipsLeft: number | null;
  maxClips: number | null;
  clipsRemaining: number | null;
}

const PerformanceSummary: React.FC<Props> = ({
  redemptionRate,
  clipsLeft,
  maxClips,
  clipsRemaining
}) => {
  return (
    <Widget className="performance-summary">
      <Widget.Header>
        <div className="title">Performance Summary</div>
      </Widget.Header>
      <Widget.Body>
        <div className="performance-summary__container">
          <div className="performance-summary__item performance-summary__item-border">
            <div className="performance-summary__item-title">
              {redemptionRate !== null ? `${redemptionRate}%` : '-'}
            </div>
            <div className="performance-summary__item-description">Redemption Rate</div>
          </div>
          <div className="performance-summary__item performance-summary__item-border">
            <div className="performance-summary__item-title">
              {clipsLeft !== null ? `${clipsLeft}%` : '-'}
            </div>
            <div className="performance-summary__item-description">% Clips Left</div>
            <div className="performance-summary__item-percentage">
              <ProgressBar
                className="performance-summary__item-percentage-progress"
                now={clipsLeft !== null ? clipsLeft : 0}
              />
            </div>
          </div>
          <div className="performance-summary__item performance-summary__item-border">
            <div className="performance-summary__item-title">
              {maxClips !== null ? Formats.formatNumber(maxClips) : '-'}
            </div>
            <div className="performance-summary__item-description">Max Clips</div>
          </div>
          <div className="performance-summary__item">
            <div className="performance-summary__item-title">
              {clipsRemaining !== null ? Formats.formatNumber(clipsRemaining) : '-'}
            </div>
            <div className="performance-summary__item-description"># Of Clips Remaining</div>
          </div>
        </div>
      </Widget.Body>
    </Widget>
  );
};

export default PerformanceSummary;
