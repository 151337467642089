import {
  BuildOfferFormType,
  RetailerCampaigns,
  CouponType,
  OfferTypeItem,
  OfferType
} from 'utils/types';
import moment from 'moment';
import RetailersUtils from 'utils/retailers';

export type ImageType = {
  base64: string;
  type: string;
};

class CouponsUtils {
  static areCampaignsValid(campaigns: Array<RetailerCampaigns>) {
    return !campaigns
      .map(campaign =>
        campaign.campaigns.some(
          details =>
            !details.startDate ||
            details.startDate === '' ||
            !details.maxClips ||
            details.maxClips === 0
        )
      )
      .reduce((accumulator, currentValue) => accumulator || currentValue, false);
  }

  static calculateCampaignsTotal(campaigns: Array<RetailerCampaigns>) {
    return campaigns
      .map(item =>
        item.campaigns.reduce(
          (accumulator, campaign) =>
            campaign.maxClipsBudget ? accumulator + campaign.maxClipsBudget : accumulator,
          0
        )
      )
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  }

  static calculateSetUpFee(campaigns: Array<RetailerCampaigns>) {
    let arr = [];
    campaigns.forEach(item => {
      if (item.retailer.setUpFee > 0) {
        arr.push({ campaigns: item.campaigns.length, setUpFee: item.retailer.setUpFee });
      }
    });

    let max = -Infinity,
      key;

    arr.forEach((v, k) => {
      if (max < +v.campaigns) {
        max = +v.campaigns;
        key = k;
      }
    });

    if (arr.length > 0) {
      return arr[key].campaigns * arr[key].setUpFee;
    } else {
      return 0;
    }
  }

  static calculateExpiration(startDate: string, duration, extension: boolean = false) {
    let result = null;
    if (startDate && startDate !== '') {
      let expirationMoment = moment(startDate).add(duration - 1, 'days');
      if (extension) {
        expirationMoment = expirationMoment.add(30, 'days');
      }
      result = expirationMoment.format('YYYY-MM-DD');
    }
    return result;
  }

  static isOfferFormValid(offerForm: BuildOfferFormType) {
    // Attributes common to all types
    let isValid =
      offerForm.type !== null &&
      offerForm.faceValue !== null &&
      offerForm.title !== '' &&
      offerForm.description !== '' &&
      offerForm.campaignImage !== null &&
      offerForm.purchaseRequirementDescription !== '' &&
      offerForm.blendedGS1 !== '' &&
      offerForm.manufacturerId !== '';

    // Validate attributes for % discount
    if (offerForm.type === 'percentage') {
      isValid = isValid && offerForm.maxValue !== null && offerForm.quantity !== null;
    }
    if (offerForm.manufacturerId !== null && offerForm.manufacturerId !== '') {
      isValid = isValid && offerForm.manufacturerId.length === 6;
    }
    if (offerForm.blendedGS1 !== null && offerForm.blendedGS1 !== '') {
      isValid = isValid && offerForm.blendedGS1.length === 37;
    }

    return isValid;
  }

  static mapCouponFromBackend(backCoupon: any): CouponType {
    const coupon: CouponType = {
      id: backCoupon.id,
      blendedGS1: backCoupon.gs1Databar,
      campaignImage: backCoupon.campaignImageUrl,
      campaigns: undefined,
      description: backCoupon.description,
      faceValue: backCoupon.faceValueOffer,
      legalCopy: backCoupon.legal,
      manufacturerId: backCoupon.manufactererOfferId,
      maxValue: backCoupon.maxValueOffer,
      notes: backCoupon.notes,
      productImage: backCoupon.productImageUrl,
      productsIds: backCoupon.products.slice(),
      purchaseRequirementDescription: backCoupon.purhcaseRequierementDescription,
      quantity: backCoupon.quantity,
      itemsRewardQuantity: backCoupon.itemsRewardQuantity,
      title: backCoupon.title,
      type: this.mapOfferTypeFromBackend(backCoupon.offerType)
    };
    // Despite will be weird that one retailer has two or more campaigns with same dates, we should take care of this situation
    coupon.campaigns = [];
    backCoupon.campaigns.forEach(backCampaign => {
      let campaignRetailer = coupon.campaigns.find(
        campaign => campaign.retailer.id === backCampaign.retailerDetail.id
      );
      if (!campaignRetailer) {
        campaignRetailer = {
          retailer: RetailersUtils.mapRetailerFromBackend(backCampaign.retailerDetail),
          campaigns: [
            {
              duration: backCampaign.duration,
              expiration: backCampaign.expirationDate,
              extension: backCampaign.extendedDays > 0,
              maxClips: backCampaign.maxClips,
              maxClipsBudget: backCampaign.maxClipsBudget,
              startDate: backCampaign.startDate
            }
          ]
        };
        coupon.campaigns.push(campaignRetailer);
      } else {
        campaignRetailer.campaigns.push({
          duration: backCampaign.duration,
          expiration: backCampaign.expirationDate,
          extension: backCampaign.extendedDays > 0,
          maxClips: backCampaign.maxClips,
          maxClipsBudget: backCampaign.maxClipsBudget,
          startDate: backCampaign.startDate
        });
      }
    });
    return coupon;
  }

  static mapImageFile(file: any): Promise<ImageType> {
    return new Promise<ImageType>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event: any) => {
        const base64 = event.target.result;
        const result = {
          base64,
          type: ''
        };
        switch (file.type) {
          case 'image/jpeg':
            result.type = 'jpeg';
            break;
          case 'image/jpg':
            result.type = 'jpg';
            break;
          case 'image/png':
            result.type = 'png';
            break;
        }
        resolve(result);
      };
      reader.onerror = error => {
        reject(error);
      };
    });
  }
  static mapOfferFormToBackend(
    offerForm: BuildOfferFormType,
    campaignImage: ImageType = null,
    productImage: ImageType = null
  ): object {
    const coupon = {
      campaignImage: undefined,
      description: offerForm.description,
      faceValueOffer: offerForm.faceValue,
      gs1Databar: undefined,
      legal: undefined,
      manufactererOfferId: undefined,
      maxValueOffer: undefined,
      notes: undefined,
      offerType: CouponsUtils.mapOfferTypeToBackend(offerForm.type),
      productImage: undefined,
      purhcaseRequierementDescription: offerForm.purchaseRequirementDescription,
      quantity: undefined,
      itemsRewardQuantity: undefined,
      title: offerForm.title
    };
    if (offerForm.type === 'percentage') {
      coupon.maxValueOffer = offerForm.maxValue;
      coupon.quantity = offerForm.quantity;
    }
    if (offerForm.type === 'bogo') {
      coupon.quantity = offerForm.quantity;
      coupon.itemsRewardQuantity = offerForm.itemsRewardQuantity;
    }
    if (offerForm.blendedGS1 !== '') {
      coupon.gs1Databar = offerForm.blendedGS1;
    }
    if (offerForm.manufacturerId !== '') {
      coupon.manufactererOfferId = offerForm.manufacturerId;
    }
    if (offerForm.notes !== '') {
      coupon.notes = offerForm.notes;
    }
    if (campaignImage !== null) {
      coupon.campaignImage = {
        data: campaignImage.base64,
        type: campaignImage.type
      };
    }
    if (productImage !== null) {
      coupon.productImage = {
        data: productImage.base64,
        type: productImage.type
      };
    }
    if (offerForm.legalCopy !== '') {
      coupon.legal = offerForm.legalCopy;
    }
    return coupon;
  }

  static getOfferType(type: OfferType): string {
    switch (type) {
      case 'dollarDiscount':
        return '$ Discount';
      case 'percentDiscount':
        return '% Discount';
      case 'buyxgety':
        return 'BOGO';
      case 'freeItem':
        return 'FREE';
    }
  }

  static mapOfferTypeFromBackend(type: OfferType): OfferTypeItem {
    switch (type) {
      case 'dollarDiscount':
        return 'dollar';
      case 'percentDiscount':
        return 'percentage';
      case 'buyxgety':
        return 'bogo';
      case 'freeItem':
        return 'free';
    }
  }

  static mapOfferTypeToBackend(type: OfferTypeItem): string {
    switch (type) {
      case 'dollar':
        return 'dollarDiscount';
      case 'percentage':
        return 'percentDiscount';
      case 'bogo':
        return 'buyxgety';
      case 'free':
        return 'freeItem';
    }
  }

  static mapRetailersCampaignToBackend(retailersCampaigns: Array<RetailerCampaigns>): Array<any> {
    const campaigns = [];
    retailersCampaigns.forEach(retailer => {
      retailer.campaigns.forEach(details => {
        const data = {
          retailer: retailer.retailer.id,
          startDate: details.startDate,
          expirationDate: details.expiration,
          extendedDays: details.extension ? 30 : 0,
          duration: details.duration,
          maxClips: details.maxClips,
          maxClipsBudget: details.maxClipsBudget
        };
        campaigns.push(data);
      });
    });
    return campaigns;
  }
}

export default CouponsUtils;
